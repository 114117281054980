
export const GES_PATOLOGIES = [
  {
    id        : 1,
    name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
    pathologic: [
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'I120',
        descriptor: 'ENFERMEDAD RENAL HIPERTENSIVA CON INSUFICIENCIA RENAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'N184',
        descriptor: 'ENFERMEDAD RENAL CRONICA, ESTADIO 4',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'N185',
        descriptor: 'ENFERMEDAD RENAL CRONICA, ESTADIO 5',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'N189',
        descriptor: 'ENFERMEDAD RENAL CRONICA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'N19',
        descriptor: 'INSUFICIENCIA RENAL NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'N26',
        descriptor: 'RIÑON CONTRAIDO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'N271',
        descriptor: 'RIÑON PEQUEÑO, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'N279',
        descriptor: 'RIÑON PEQUEÑO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'Q60',
        descriptor: 'AGENESIA RENAL Y OTRAS MALFORMACIONES HIPOPLASICAS DEL RIÑON',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'Q600',
        descriptor: 'AGENESIA RENAL, UNILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'Q601',
        descriptor: 'AGENESIA RENAL, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'Q602',
        descriptor: 'AGENESIA RENAL, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'Q603',
        descriptor: 'HIPOPLASIA RENAL, UNILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'Q604',
        descriptor: 'HIPOPLASIA RENAL, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'Q605',
        descriptor: 'HIPOPLASIA RENAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'Q606',
        descriptor: 'SINDROME DE POTTER',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '1',
        name      : 'ENFERMEDAD RENAL CRONICA ETAPA 4 Y 5',
        code      : 'N18',
        descriptor: 'ENFERMEDAD RENAL CRONICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 2,
    name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
    pathologic: [
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'I456',
        descriptor: 'SINDROME DE PREEXCITACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q20',
        descriptor: 'MALFORMACIONES CONGENITAS DE LAS CAMARAS CARDIACAS Y SUS CONEXIONES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q200',
        descriptor: 'TRONCO ARTERIOSO COMUN',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q201',
        descriptor: 'TRANSPOSICION DE LOS GRANDES VASOS EN VENTRICULO DERECHO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q202',
        descriptor: 'TRANSPOSICION DE LOS GRANDES VASOS EN VENTRICULO IZQUIERDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q203',
        descriptor: 'DISCORDANCIA DE LA CONEXION VENTRICULOARTERIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q204',
        descriptor: 'VENTRICULO CON DOBLE ENTRADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q205',
        descriptor: 'DISCORDANCIA DE LA CONEXION AURICULOVENTRICULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q206',
        descriptor: 'ISOMERISMO DE LOS APENDICES AURICULARES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q208',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LAS CAMARAS CARDIACAS Y DE SUS CONEXIONES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q209',
        descriptor: 'MALFORMACION CONGENITA DE LAS CAMARAS CARDIACAS Y DE SUS CONEXIONES, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q21',
        descriptor: 'MALFORMACIONES CONGENITAS DE LOS TABIQUES CARDIACOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q210',
        descriptor: 'DEFECTO DEL TABIQUE VENTRICULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q211',
        descriptor: 'DEFECTO DEL TABIQUE AURICULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q212',
        descriptor: 'DEFECTO DEL TABIQUE AURICULOVENTRICULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q213',
        descriptor: 'TETRALOGIA DE FALLOT',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q214',
        descriptor: 'DEFECTO DEL TABIQUE AORTOPULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q218',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LOS TABIQUES CARDIACOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q219',
        descriptor: 'MALFORMACION CONGENITA DEL TABIQUE CARDIACO, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q22',
        descriptor: 'MALFORMACIONES CONGENITAS DE LA VALVULAS PULMONAR Y TRICUSPIDE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q220',
        descriptor: 'ATRESIA DE LA VALVULA PULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q221',
        descriptor: 'ESTENOSIS CONGENITA DE LA VALVULA PULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q222',
        descriptor: 'INSUFICIENCIA CONGENITA DE LA VALVULA PULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q223',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LA VALVULA PULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q224',
        descriptor: 'ESTENOSIS CONGENITA DE LA VALVULA TRICUSPIDE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q225',
        descriptor: 'ANOMALIA DE EBSTEIN',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q226',
        descriptor: 'SINDROME DE HIPOPLASIA DEL CORAZON DERECHO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q228',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LA VALVULA TRICUSPIDE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q229',
        descriptor: 'MALFORMACION CONGENITA DE LA VALVULA TRICUSPIDE, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q23',
        descriptor: 'MALFORMACIONES CONGENITAS DE LAS VALVULAS AORTICA Y MITRAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q230',
        descriptor: 'ESTENOSIS CONGENITA DE LA VALVULA AORTICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q231',
        descriptor: 'INSUFICIENCIA CONGENITA DE LA VALVULA AORTICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q232',
        descriptor: 'ESTENOSIS MITRAL CONGENITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q233',
        descriptor: 'INSUFICIENCIA MITRAL CONGENITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q234',
        descriptor: 'SINDROME DE HIPOPLASIA DEL CORAZON IZQUIERDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q238',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LAS VALVULAS AORTICAS Y MITRAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q239',
        descriptor: 'MALFORMACION CONGENITA DE LAS VALVULAS AORTICA Y MITRAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q240',
        descriptor: 'DEXTROCARDIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q241',
        descriptor: 'LEVOCARDIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q242',
        descriptor: 'CORAZON TRIAURICULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q243',
        descriptor: 'ESTENOSIS DEL INFUNDIBULO PULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q244',
        descriptor: 'ESTENOSIS SUBAORTICA CONGENITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q245',
        descriptor: 'MALFORMACION DE LOS VASOS CORONARIOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q246',
        descriptor: 'BLOQUEO CARDIACO CONGENITO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q248',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DEL CORAZON, ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q249',
        descriptor: 'MALFORMACION CONGENITA DEL CORAZON, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q25',
        descriptor: 'MALFORMACIONES CONGENITAS DE LAS GRANDES ARTERIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q250',
        descriptor: 'CONDUCTO ARTERIOSO PERMEABLE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q251',
        descriptor: 'COARTACION DE LA AORTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q252',
        descriptor: 'ATRESIA DE LA AORTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q253',
        descriptor: 'ESTENOSIS DE LA AORTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q254',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LA AORTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q255',
        descriptor: 'ATRESIA DE LA ARTERIA PULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q256',
        descriptor: 'ESTENOSIS DE LA ARTERIA PULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q257',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LA ARTERIA PULMONAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q258',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LAS GRANDES ARTERIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q259',
        descriptor: 'MALFORMACION CONGENITA DE LAS GRANDES ARTERIAS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q260',
        descriptor: 'ESTENOSIS CONGENITA DE LA VENA CAVA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q261',
        descriptor: 'PERSISTENCIA DE LA VENA CAVA SUPERIOR IZQUIERDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q262',
        descriptor: 'CONEXION ANOMALA TOTAL DE LAS VENAS PULMONARES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q263',
        descriptor: 'CONEXION ANOMALA PARCIAL DE LAS VENAS PULMONARES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q264',
        descriptor: 'CONEXION ANOMALA DE LAS VENAS PULMONARES, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q268',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LAS GRANDES VENAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q269',
        descriptor: 'MALFORMACION CONGENITA DE LAS GRANDES VENAS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '2',
        name      : 'CARDIOPATIAS CONGENITAS OPERABLES EN MENORES DE 15 AÑOS',
        code      : 'Q289',
        descriptor: 'MALFORMACION CONGENITA DEL SISTEMA CIRCULATORIO, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 3,
    name      : 'CANCER CERVICOUTERINO',
    pathologic: [
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'C53',
        descriptor: 'TUMOR MALIGNO DEL CUELLO DEL UTERO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'C530',
        descriptor: 'TUMOR MALIGNO DEL ENDOCERVIX',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'C531',
        descriptor: 'TUMOR MALIGNO DEL EXOCERVIX',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'C538',
        descriptor: 'LESION (NEOPLASICA) DE SITIOS CONTIGUOS DEL CUELLO DEL UTERO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'C539',
        descriptor: 'TUMOR MALIGNO DEL CUELLO DEL UTERO, SIN OTRA ESPECIFICACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'D06',
        descriptor: 'CARCINOMA IN SITU DEL CUELLO DEL UTERO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'D060',
        descriptor: 'CARCINOMA IN SITU DEL ENDOCERVIX',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'D061',
        descriptor: 'CARCINOMA IN SITU DEL EXOCERVIX',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'D067',
        descriptor: 'CARCINOMA IN SITU DE OTRAS PARTES ESPECIFICADAS DEL CUELLO DEL UTERO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'D069',
        descriptor: 'CARCINOMA IN SITU DEL CUELLO DEL UTERO, PARTE NO ESPECIFICADA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'N87',
        descriptor: 'DISPLASIA DEL CUELLO UTERINO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'N870',
        descriptor: 'DISPLASIA CERVICAL LEVE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'N872',
        descriptor: 'DISPLASIA CERVICAL SEVERA, NO CLASIFICADA EN OTRA PARTE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'N871',
        descriptor: 'DISPLASIA CERVICAL MODERADA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '3',
        name      : 'CANCER CERVICOUTERINO',
        code      : 'N879',
        descriptor: 'DISPLASIA DEL CUELLO DEL UTERO, NO ESPECIFICADA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 4,
    name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
    pathologic: [
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C00',
        descriptor: 'TUMOR MALIGNO DEL LABIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C000',
        descriptor: 'TUMOR MALIGNO DEL LABIO SUPERIOR, CARA EXTERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C001',
        descriptor: 'TUMOR MALIGNO DEL LABIO INFERIOR, CARA EXTERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C002',
        descriptor: 'TUMOR MALIGNO DEL LABIO, CARA EXTERNA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C003',
        descriptor: 'TUMOR MALIGNO DEL LABIO SUPERIOR, CARA INTERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C004',
        descriptor: 'TUMOR MALIGNO DEL LABIO INFERIOR, CARA INTERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C005',
        descriptor: 'TUMOR MALIGNO DEL LABIO, CARA INTERNA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C006',
        descriptor: 'TUMOR MALIGNO DE LA COMISURA LABIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C008',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL LABIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C009',
        descriptor: 'TUMOR MALIGNO DEL LABIO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C01',
        descriptor: 'TUMOR MALIGNO DE LA BASE DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C02',
        descriptor: 'TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C020',
        descriptor: 'TUMOR MALIGNO DE LA CARA DORSAL DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C021',
        descriptor: 'TUMOR MALIGNO DEL BORDE DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C022',
        descriptor: 'TUMOR MALIGNO DE LA CARA VENTRAL DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C023',
        descriptor: 'TUMOR MALIGNO DE LOS DOS TERCIOS ANTERIORES DE LA LENGUA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C024',
        descriptor: 'TUMOR MALIGNO DE LA AMIGDALA LINGUAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C028',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C029',
        descriptor: 'TUMOR MALIGNO DE LA LENGUA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C03',
        descriptor: 'TUMOR MALIGNO DE LA ENCIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C030',
        descriptor: 'TUMOR MALIGNO DE LA ENCIA SUPERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C031',
        descriptor: 'TUMOR MALIGNO DE LA ENCIA INFERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C039',
        descriptor: 'TUMOR MALIGNO DE LA ENCIA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C04',
        descriptor: 'TUMOR MALIGNO DEL PISO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C040',
        descriptor: 'TUMOR MALIGNO DE LA PARTE ANTERIOR DEL PISO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C041',
        descriptor: 'TUMOR MALIGNO DE LA PARTE LATERAL DEL PISO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C048',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL PISO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C049',
        descriptor: 'TUMOR MALIGNO DEL PISO DE LA BOCA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C05',
        descriptor: 'TUMOR MALIGNO DEL PALADAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C050',
        descriptor: 'TUMOR MALIGNO DEL PALADAR DURO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C051',
        descriptor: 'TUMOR MALIGNO DEL PALADAR BLANDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C052',
        descriptor: 'TUMOR MALIGNO DE LA UVULA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C058',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL PALADAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C059',
        descriptor: 'TUMOR MALIGNO DEL PALADAR, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C06',
        descriptor: 'TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C060',
        descriptor: 'TUMOR MALIGNO DE LA MUCOSA DE LA MEJILLA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C061',
        descriptor: 'TUMOR MALIGNO DEL VESTIBULO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C062',
        descriptor: 'TUMOR MALIGNO DEL AREA RETROMOLAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C068',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS EN LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C069',
        descriptor: 'TUMOR MALIGNO DE LA BOCA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C07',
        descriptor: 'TUMOR MALIGNO DE LA GLANDULA PAROTIDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C08',
        descriptor: 'TUMOR MALIGNO DE OTRAS GLANDULAS SALIVALES MAYORES Y DE LAS NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C080',
        descriptor: 'TUMOR MALIGNO DE LA GLANDULA SUBMAXILAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C081',
        descriptor: 'TUMOR MALIGNO DE LA GLANDULA SUBLINGUAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C088',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LAS GLANDULAS SALIVALES MAYORES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C089',
        descriptor: 'TUMOR MALIGNO DE GLANDULA SALIVAL MAYOR, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C09',
        descriptor: 'TUMOR MALIGNO DE LA AMIGDALA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C090',
        descriptor: 'TUMOR MALIGNO DE LA FOSA AMIGDALINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C091',
        descriptor: 'TUMOR MALIGNO DEL PILAR AMIGDALINO (ANTERIOR) (POSTERIOR)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C098',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA AMIGDALA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C099',
        descriptor: 'TUMOR MALIGNO DE LA AMIGDALA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C10',
        descriptor: 'TUMOR MALIGNO DE LA OROFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C100',
        descriptor: 'TUMOR MALIGNO DE LA VALECULA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C101',
        descriptor: 'TUMOR MALIGNO DE LA CARA ANTERIOR DE LA EPIGLOTIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C102',
        descriptor: 'TUMOR MALIGNO DE LA PARED LATERAL DE LA OROFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C103',
        descriptor: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA OROFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C104',
        descriptor: 'TUMOR MALIGNO DE LA HENDIDURA BRANQUIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C108',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA OROFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C109',
        descriptor: 'TUMOR MALIGNO DE LA OROFARINGE, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C11',
        descriptor: 'TUMOR MALIGNO DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C110',
        descriptor: 'TUMOR MALIGNO DE LA PARED SUPERIOR DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C111',
        descriptor: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C112',
        descriptor: 'TUMOR MALIGNO DE LA PARED LATERAL DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C113',
        descriptor: 'TUMOR MALIGNO DE LA PARED ANTERIOR DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C118',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C119',
        descriptor: 'TUMOR MALIGNO DE LA NASOFARINGE, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C12',
        descriptor: 'TUMOR MALIGNO DEL SENO PIRIFORME',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C13',
        descriptor: 'TUMOR MALIGNO DE LA HIPOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C130',
        descriptor: 'TUMOR MALIGNO DE LA REGION POSTCRICOIDEA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C131',
        descriptor: 'TUMOR MALIGNO DEL PLIEGUE ARITENOEPIGLOTICO, CARA HIPOFARINGEA (HIPOFARINGE)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C132',
        descriptor: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA HIPOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C138',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA HIPOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C139',
        descriptor: 'TUMOR MALIGNO DE LA HIPOFARINGE, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C14',
        descriptor:
            'TUMOR MALIGNO DE OTROS SITIOS Y DE LOS MAL DEFINIDOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C140',
        descriptor: 'TUMOR MALIGNO DE LA FARINGE, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C142',
        descriptor: 'TUMOR MALIGNO DEL ANILLO DE WALDEYER',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C148',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C15',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C150',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO, PORCION CERVICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C151',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO, PORCION TORACICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C152',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO, PORCION ABDOMINAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C153',
        descriptor: 'TUMOR MALIGNO DEL TERCIO SUPERIOR DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C154',
        descriptor: 'TUMOR MALIGNO DEL TERCIO MEDIO DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C155',
        descriptor: 'TUMOR MALIGNO DEL TERCIO INFERIOR DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C158',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C159',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C16',
        descriptor: 'TUMOR MALIGNO DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C160',
        descriptor: 'TUMOR MALIGNO DEL CARDIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C161',
        descriptor: 'TUMOR MALIGNO DEL FUNDUS GASTRICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C162',
        descriptor: 'TUMOR MALIGNO DEL CUERPO DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C163',
        descriptor: 'TUMOR MALIGNO DEL ANTRO PILORICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C164',
        descriptor: 'TUMOR MALIGNO DEL PILORO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C165',
        descriptor: 'TUMOR MALIGNO DE LA CURVATURA MENOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C166',
        descriptor: 'TUMOR MALIGNO DE LA CURVATURA MAYOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C168',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C169',
        descriptor: 'TUMOR MALIGNO DEL ESTOMAGO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C17',
        descriptor: 'TUMOR MALIGNO DEL INTESTINO DELGADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C170',
        descriptor: 'TUMOR MALIGNO DEL DUODENO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C171',
        descriptor: 'TUMOR MALIGNO DEL YEYUNO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '4',
        name      : 'ALIVIO DEL DOLOR Y CUIDADOS PALIATIVOS DE CANCER AVANZADO',
        code      : 'C172',
        descriptor: 'TUMOR MALIGNO DEL ILEON',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 5,
    name      : 'INFARTO AGUDO DEL MIOCARDIO',
    pathologic: [
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I21',
        descriptor: 'INFARTO AGUDO DEL MIOCARDIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I210',
        descriptor: 'INFARTO TRANSMURAL AGUDO DEL MIOCARDIO DE LA PARED ANTERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I211',
        descriptor: 'INFARTO TRANSMURAL AGUDO DEL MIOCARDIO DE LA PARED INFERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I212',
        descriptor: 'INFARTO AGUDO TRANSMURAL DEL MIOCARDIO DE OTROS SITIOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I213',
        descriptor: 'INFARTO TRANSMURAL AGUDO DEL MIOCARDIO, DE SITIO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I214',
        descriptor: 'INFARTO SUBENDOCARDICO AGUDO DEL MIOCARDIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I219',
        descriptor: 'INFARTO AGUDO DEL MIOCARDIO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I22',
        descriptor: 'INFARTO SUBSECUENTE DEL MIOCARDIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I220',
        descriptor: 'INFARTO SUBSECUENTE DEL MIOCARDIO DE LA PARED ANTERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I221',
        descriptor: 'INFARTO SUBSECUENTE DEL MIOCARDIO DE LA PARED INFERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I228',
        descriptor: 'INFARTO SUBSECUENTE DEL MIOCARDIO DE OTROS SITIOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I229',
        descriptor: 'INFARTO SUBSECUENTE DEL MIOCARDIO, DE PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I230',
        descriptor: 'HEMOPERICARDIO COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I249',
        descriptor: 'ENFERMEDAD ISQUEMICA AGUDA DEL CORAZON, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '5',
        name      : 'INFARTO AGUDO DEL MIOCARDIO',
        code      : 'I252',
        descriptor: 'INFARTO ANTIGUO DEL MIOCARDIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 6,
    name      : 'DIABETES MELLITUS TIPO 1',
    pathologic: [
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E10',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E100',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON COMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E101',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON CETOACIDOSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E102',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E103',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E104',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES NEUROLOGICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E105',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES CIRCULATORIAS PERIFERICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E106',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON OTRAS COMPLICACIONES ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E107',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES MULTIPLES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E108',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '6',
        name      : 'DIABETES MELLITUS TIPO 1',
        code      : 'E109',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE SIN MENCION DE COMPLICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 7,
    name      : 'DIABETES MELLITUS TIPO 2',
    pathologic: [
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E119',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE SIN MENCION DE COMPLICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E12',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E120',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E121',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON CETOACIDOSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E122',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E123',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E124',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES NEUROLOGICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E125',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES CIRCULATORIAS PERIFERICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E126',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON OTRAS COMPLICACIONES ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E127',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES MULTIPLES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E128',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E129',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, SIN MENCION DE COMPLICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E13',
        descriptor: 'OTRAS DIABETES MELLITUS ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E130',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON COMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E131',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON CETOACIDOSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E132',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E133',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E134',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES NEUROLOGICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E135',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES CIRCULATORIAS PERIFERICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E136',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON OTRAS COMPLICACIONES ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E137',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES MULTIPLES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E138',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E139',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, SIN MENCION DE COMPLICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E14',
        descriptor: 'DIABETES MELLITUS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E140',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E141',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON CETOACIDOSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E142',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E143',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E144',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES NEUROLOGICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E145',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES CIRCULATORIAS PERIFERICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E146',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON OTRAS COMPLICACIONES ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E147',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES MULTIPLES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E148',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E149',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, SIN MENCION DE COMPLICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E891',
        descriptor: 'HIPOINSULINEMIA CONSECUTIVA A PROCEDIMIENTOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'O244',
        descriptor: 'DIABETES MELLITUS QUE SE ORIGINA CON EL EMBARAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E11',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E110',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E111',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON CETOACIDOSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E112',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E113',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E114',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES NEUROLOGICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E115',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES CIRCULATORIAS PERIFERICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E116',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON OTRAS COMPLICACIONES ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E117',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES MULTIPLES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '7',
        name      : 'DIABETES MELLITUS TIPO 2',
        code      : 'E118',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 8,
    name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C50',
        descriptor: 'TUMOR MALIGNO DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C500',
        descriptor: 'TUMOR MALIGNO DEL PEZON Y DE LA AREOLA MAMARIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C501',
        descriptor: 'TUMOR MALIGNO DE LA PORCION CENTRAL DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C502',
        descriptor: 'TUMOR MALIGNO DEL CUADRANTE SUPERIOR INTERNO DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C503',
        descriptor: 'TUMOR MALIGNO DEL CUADRANTE INFERIOR INTERNO DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C504',
        descriptor: 'TUMOR MALIGNO DEL CUADRANTE SUPERIOR EXTERNO DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C505',
        descriptor: 'TUMOR MALIGNO DEL CUADRANTE INFERIOR EXTERNO DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C506',
        descriptor: 'TUMOR MALIGNO DE LA PROLONGACION AXILAR DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C508',
        descriptor: 'LESION (NEOPLASICA) DE SITIOS CONTIGUOS DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C509',
        descriptor: 'TUMOR MALIGNO DE LA MAMA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D05',
        descriptor: 'CARCINOMA IN SITU DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D050',
        descriptor: 'CARCINOMA IN SITU LOBULAR DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D051',
        descriptor: 'CARCINOMA IN SITU INTRACANALICULAR DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D057',
        descriptor: 'OTROS CARCINOMAS IN SITU DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D059',
        descriptor: 'CARCINOMA IN SITU DE LA MAMA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D486',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA MAMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '8',
        name      : 'CANCER DE MAMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D079',
        descriptor: 'CARCINOMA IN SITU DE LA MAMA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 9,
    name      : 'DISRAFIAS  ESPINALES',
    pathologic: [
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'D334',
        descriptor: 'TUMOR BENIGNO DE LA MEDULA ESPINAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'G950',
        descriptor: 'SIRINGOMIELIA Y SIRINGOBULBIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'L050',
        descriptor: 'QUISTE PILONIDAL CON ABSCESO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'L059',
        descriptor: 'QUISTE PILONIDAL SIN ABSCESO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q01',
        descriptor: 'ENCEFALOCELE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q010',
        descriptor: 'ENCEFALOCELE FRONTAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q011',
        descriptor: 'ENCEFALOCELE NASOFRONTAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q012',
        descriptor: 'ENCEFALOCELE OCCIPITAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q018',
        descriptor: 'ENCEFALOCELE DE OTROS SITIOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q019',
        descriptor: 'ENCEFALOCELE, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q05',
        descriptor: 'ESPINA BIFIDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q050',
        descriptor: 'ESPINA BIFIDA CERVICAL CON HIDROCEFALO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q051',
        descriptor: 'ESPINA BIFIDA TORACICA CON HIDROCEFALO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q052',
        descriptor: 'ESPINA BIFIDA LUMBAR CON HIDROCEFALO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q053',
        descriptor: 'ESPINA BIFIDA SACRA CON HIDROCEFALO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q054',
        descriptor: 'ESPINA BIFIDA CON HIDROCEFALO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q055',
        descriptor: 'ESPINA BIFIDA CERVICAL SIN HIDROCEFALO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q056',
        descriptor: 'ESPINA BIFIDA TORACICA SIN HIDROCEFALO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q057',
        descriptor: 'ESPINA BIFIDA LUMBAR SIN HIDROCEFALO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q058',
        descriptor: 'ESPINA BIFIDA SACRA SIN HIDROCEFALO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q059',
        descriptor: 'ESPINA BIFIDA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q06',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS DE LA MEDULA ESPINAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q060',
        descriptor: 'AMIELIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q061',
        descriptor: 'HIPOPLASIA Y DISPLASIA DE LA MEDULA ESPINAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q062',
        descriptor: 'DIASTEMATOMIELIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q063',
        descriptor: 'OTRAS ANOMALIAS CONGENITAS DE LA COLA DE CABALLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q064',
        descriptor: 'HIDROMIELIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q068',
        descriptor: 'OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DE LA MEDULA ESPINAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q069',
        descriptor: 'MALFORMACION CONGENITA DE LA MEDULA ESPINAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q070',
        descriptor: 'SINDROME DE ARNOLD-CHIARI',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q760',
        descriptor: 'ESPINA BIFIDA OCULTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '9',
        name      : 'DISRAFIAS  ESPINALES',
        code      : 'Q001',
        descriptor: 'CRANEORRAQUISQUISIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 10,
    name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
    pathologic: [
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M41',
        descriptor: 'ESCOLIOSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M410',
        descriptor: 'ESCOLIOSIS IDIOPATICA INFANTIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M411',
        descriptor: 'ESCOLIOSIS IDIOPATICA JUVENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M412',
        descriptor: 'OTRAS ESCOLIOSIS IDIOPATICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M413',
        descriptor: 'ESCOLIOSIS TORACOGENICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M414',
        descriptor: 'ESCOLIOSIS NEUROMUSCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M415',
        descriptor: 'OTRAS ESCOLIOSIS SECUNDARIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M418',
        descriptor: 'OTRAS FORMAS DE ESCOLIOSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'M419',
        descriptor: 'ESCOLIOSIS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'Q675',
        descriptor: 'DEFORMIDAD CONGENITA DE LA COLUMNA VERTEBRAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
      {
        id        : '10',
        name      : 'TRATAMIENTO QUIRURGICO DE ESCOLIOSIS EN PERSONAS MENORES DE 25 AÑOS',
        code      : 'Q763',
        descriptor: 'ESCOLIOSIS CONGENITA DEBIDA A MALFORMACION CONGENITA OSEA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '24 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 11,
    name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
    pathologic: [
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H25',
        descriptor: 'CATARATA SENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H250',
        descriptor: 'CATARATA SENIL INCIPIENTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H251',
        descriptor: 'CATARATA SENIL NUCLEAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H252',
        descriptor: 'CATARATA SENIL, TIPO MORGAGNIAN',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H258',
        descriptor: 'OTRAS CATARATAS SENILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H259',
        descriptor: 'CATARATA SENIL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H26',
        descriptor: 'OTRAS CATARATAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H260',
        descriptor: 'CATARATA  INFANTIL, JUVENIL Y PRESENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H261',
        descriptor: 'CATARATA TRAUMATICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H262',
        descriptor: 'CATARATA COMPLICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H263',
        descriptor: 'CATARATA INDUCIDA POR DROGAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H264',
        descriptor: 'CATARATA RESIDUAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H268',
        descriptor: 'OTRAS FORMAS ESPECIFICADAS DE CATARATA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H269',
        descriptor: 'CATARATA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H28',
        descriptor: 'CATARATA Y OTROS TRASTORNOS DEL CRISTALINO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H280',
        descriptor: 'CATARATA DIABETICA (E10-E14+ CON CUARTO CARACTER COMUN .3)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H281',
        descriptor: 'CATARATA EN OTRAS ENF ENDOCRINAS, NUTRICIONALES Y METABOLICAS CLASIFICADAS EN OTROS CAPITULOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'H282',
        descriptor: 'CATARATA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'Q120',
        descriptor: 'CATARATA CONGENITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'Q12',
        descriptor: 'MALFORMACIONES CONGENITAS DEL CRISTALINO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'Q121',
        descriptor: 'DESPLAZAMIENTO CONGENITO DEL CRISTALINO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'Q122',
        descriptor: 'COLOBOMA DEL CRISTALINO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'Q123',
        descriptor: 'AFAQUIA CONGENITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '11',
        name      : 'TRATAMIENTO QUIRURGICO DE CATARATAS',
        code      : 'Q124',
        descriptor: 'ESFEROFAQUIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 12,
    name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
    pathologic: [
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M16',
        descriptor: 'COXARTROSIS [ARTROSIS DE LA CADERA]',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M160',
        descriptor: 'COXARTROSIS PRIMARIA, BILATERAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M161',
        descriptor: 'OTRAS COXARTROSIS PRIMARIAS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M162',
        descriptor: 'COXARTROSIS A CONSECUENCIA DE DISPLASIA, BILATERAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M163',
        descriptor: 'OTRAS COXARTROSIS DISPLASICAS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M164',
        descriptor: 'COXARTROSIS POSTRAUMATICA, BILATERAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M165',
        descriptor: 'OTRA COXARTROSIS POSTRAUMATICA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M166',
        descriptor: 'OTRA COXARTROSIS SECUNDARIA, BILATERAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M167',
        descriptor: 'OTRAS COXARTROSIS SECUNDARIAS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '12',
        name      : 'ENDOPROTESIS TOTAL DE CADERA EN PERSONAS DE 65 AÑOS Y MAS CON ARTROSIS DE CADERA LIMITACION FUNCIONAL SEVERA',
        code      : 'M169',
        descriptor: 'COXARTROSIS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 13,
    name      : 'FISURA LABIOPALATINA',
    pathologic: [
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'D821',
        descriptor: 'SINDROME DE DI GEORGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q35',
        descriptor: 'FISURA DEL PALADAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q351',
        descriptor: 'FISURA DEL PALADAR DURO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q353',
        descriptor: 'FISURA DEL PALADAR BLANDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q355',
        descriptor: 'FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q357',
        descriptor: 'FISURA DE LA UVULA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q359',
        descriptor: 'FISURA DEL PALADAR , SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q36',
        descriptor: 'LABIO LEPORINO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q360',
        descriptor: 'LABIO LEPORINO, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q361',
        descriptor: 'LABIO LEPORINO, LINEA MEDIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q369',
        descriptor: 'LABIO LEPORINO, UNILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q37',
        descriptor: 'FISURA DEL PALADAR CON LABIO LEPORINO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q370',
        descriptor: 'FISURA DEL PALADAR DURO CON LABIO LEPORINO BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q371',
        descriptor: 'FISURA DEL PALADAR DURO CON LABIO LEPORINO UNILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q372',
        descriptor: 'FISURA DEL PALADAR BLANDO CON LABIO LEPORINO BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q373',
        descriptor: 'FISURA DEL PALADAR BLANDO CON LABIO LEPORINO UNILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q374',
        descriptor: 'FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO CON LABIO LEPORINO BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q375',
        descriptor: 'FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO CON LABIO LEPORINO UNILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q378',
        descriptor: 'FISURA DEL PALADAR CON LABIO LEPORINO BILATERAL, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q379',
        descriptor: 'FISURA DEL PALADAR CON LABIO LEPORINO UNILATERAL, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q380',
        descriptor: 'MALFORMACIONES CONGENITAS DE LOS LABIOS, NO CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q870',
        descriptor: 'SINDROMES DE MALFORMACIONES CONGENITAS QUE AFECTAN PRINCIPALMENTE LA APARIENCIA FACIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q754',
        descriptor: 'DISOSTOSIS MAXILOFACIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q740',
        descriptor: 'OTRAS MALFORM.CONGENITAS DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES), INCLUIDA LA CINTURA ESCAPULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '13',
        name      : 'FISURA LABIOPALATINA',
        code      : 'Q751',
        descriptor: 'DISOSTOSIS CRANEOFACIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 14,
    name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
    pathologic: [
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C00',
        descriptor: 'TUMOR MALIGNO DEL LABIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C000',
        descriptor: 'TUMOR MALIGNO DEL LABIO SUPERIOR, CARA EXTERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C001',
        descriptor: 'TUMOR MALIGNO DEL LABIO INFERIOR, CARA EXTERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C002',
        descriptor: 'TUMOR MALIGNO DEL LABIO, CARA EXTERNA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C003',
        descriptor: 'TUMOR MALIGNO DEL LABIO SUPERIOR, CARA INTERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C004',
        descriptor: 'TUMOR MALIGNO DEL LABIO INFERIOR, CARA INTERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C005',
        descriptor: 'TUMOR MALIGNO DEL LABIO, CARA INTERNA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C006',
        descriptor: 'TUMOR MALIGNO DE LA COMISURA LABIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C008',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL LABIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C009',
        descriptor: 'TUMOR MALIGNO DEL LABIO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C01',
        descriptor: 'TUMOR MALIGNO DE LA BASE DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C02',
        descriptor: 'TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C020',
        descriptor: 'TUMOR MALIGNO DE LA CARA DORSAL DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C021',
        descriptor: 'TUMOR MALIGNO DEL BORDE DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C022',
        descriptor: 'TUMOR MALIGNO DE LA CARA VENTRAL DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C023',
        descriptor: 'TUMOR MALIGNO DE LOS DOS TERCIOS ANTERIORES DE LA LENGUA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C024',
        descriptor: 'TUMOR MALIGNO DE LA AMIGDALA LINGUAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C028',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA LENGUA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C029',
        descriptor: 'TUMOR MALIGNO DE LA LENGUA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C03',
        descriptor: 'TUMOR MALIGNO DE LA ENCIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C030',
        descriptor: 'TUMOR MALIGNO DE LA ENCIA SUPERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C031',
        descriptor: 'TUMOR MALIGNO DE LA ENCIA INFERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C039',
        descriptor: 'TUMOR MALIGNO DE LA ENCIA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C04',
        descriptor: 'TUMOR MALIGNO DEL PISO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C040',
        descriptor: 'TUMOR MALIGNO DE LA PARTE ANTERIOR DEL PISO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C041',
        descriptor: 'TUMOR MALIGNO DE LA PARTE LATERAL DEL PISO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C048',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL PISO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C049',
        descriptor: 'TUMOR MALIGNO DEL PISO DE LA BOCA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C05',
        descriptor: 'TUMOR MALIGNO DEL PALADAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C050',
        descriptor: 'TUMOR MALIGNO DEL PALADAR DURO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C051',
        descriptor: 'TUMOR MALIGNO DEL PALADAR BLANDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C052',
        descriptor: 'TUMOR MALIGNO DE LA UVULA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C058',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL PALADAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C059',
        descriptor: 'TUMOR MALIGNO DEL PALADAR, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C06',
        descriptor: 'TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C060',
        descriptor: 'TUMOR MALIGNO DE LA MUCOSA DE LA MEJILLA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C061',
        descriptor: 'TUMOR MALIGNO DEL VESTIBULO DE LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C062',
        descriptor: 'TUMOR MALIGNO DEL AREA RETROMOLAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C068',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS EN LA BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C069',
        descriptor: 'TUMOR MALIGNO DE LA BOCA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C07',
        descriptor: 'TUMOR MALIGNO DE LA GLANDULA PAROTIDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C08',
        descriptor: 'TUMOR MALIGNO DE OTRAS GLANDULAS SALIVALES MAYORES Y DE LAS NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C080',
        descriptor: 'TUMOR MALIGNO DE LA GLANDULA SUBMAXILAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C081',
        descriptor: 'TUMOR MALIGNO DE LA GLANDULA SUBLINGUAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C088',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LAS GLANDULAS SALIVALES MAYORES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C089',
        descriptor: 'TUMOR MALIGNO DE GLANDULA SALIVAL MAYOR, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C09',
        descriptor: 'TUMOR MALIGNO DE LA AMIGDALA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C090',
        descriptor: 'TUMOR MALIGNO DE LA FOSA AMIGDALINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C091',
        descriptor: 'TUMOR MALIGNO DEL PILAR AMIGDALINO (ANTERIOR) (POSTERIOR)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C098',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA AMIGDALA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C099',
        descriptor: 'TUMOR MALIGNO DE LA AMIGDALA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C10',
        descriptor: 'TUMOR MALIGNO DE LA OROFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C100',
        descriptor: 'TUMOR MALIGNO DE LA VALECULA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C101',
        descriptor: 'TUMOR MALIGNO DE LA CARA ANTERIOR DE LA EPIGLOTIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C102',
        descriptor: 'TUMOR MALIGNO DE LA PARED LATERAL DE LA OROFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C103',
        descriptor: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA OROFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C104',
        descriptor: 'TUMOR MALIGNO DE LA HENDIDURA BRANQUIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C108',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA OROFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C109',
        descriptor: 'TUMOR MALIGNO DE LA OROFARINGE, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C11',
        descriptor: 'TUMOR MALIGNO DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C110',
        descriptor: 'TUMOR MALIGNO DE LA PARED SUPERIOR DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C111',
        descriptor: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C112',
        descriptor: 'TUMOR MALIGNO DE LA PARED LATERAL DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C113',
        descriptor: 'TUMOR MALIGNO DE LA PARED ANTERIOR DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C118',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA NASOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C119',
        descriptor: 'TUMOR MALIGNO DE LA NASOFARINGE, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C12',
        descriptor: 'TUMOR MALIGNO DEL SENO PIRIFORME',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C13',
        descriptor: 'TUMOR MALIGNO DE LA HIPOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C130',
        descriptor: 'TUMOR MALIGNO DE LA REGION POSTCRICOIDEA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C131',
        descriptor: 'TUMOR MALIGNO DEL PLIEGUE ARITENOEPIGLOTICO, CARA HIPOFARINGEA (HIPOFARINGE)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C132',
        descriptor: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA HIPOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C138',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LA HIPOFARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C139',
        descriptor: 'TUMOR MALIGNO DE LA HIPOFARINGE, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C14',
        descriptor:
            'TUMOR MALIGNO DE OTROS SITIOS Y DE LOS MAL DEFINIDOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C140',
        descriptor: 'TUMOR MALIGNO DE LA FARINGE, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C142',
        descriptor: 'TUMOR MALIGNO DEL ANILLO DE WALDEYER',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C148',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C15',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C150',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO, PORCION CERVICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C151',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO, PORCION TORACICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C152',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO, PORCION ABDOMINAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C153',
        descriptor: 'TUMOR MALIGNO DEL TERCIO SUPERIOR DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C154',
        descriptor: 'TUMOR MALIGNO DEL TERCIO MEDIO DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C155',
        descriptor: 'TUMOR MALIGNO DEL TERCIO INFERIOR DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C158',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C159',
        descriptor: 'TUMOR MALIGNO DEL ESOFAGO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C16',
        descriptor: 'TUMOR MALIGNO DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C160',
        descriptor: 'TUMOR MALIGNO DEL CARDIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C161',
        descriptor: 'TUMOR MALIGNO DEL FUNDUS GASTRICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C162',
        descriptor: 'TUMOR MALIGNO DEL CUERPO DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C163',
        descriptor: 'TUMOR MALIGNO DEL ANTRO PILORICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C164',
        descriptor: 'TUMOR MALIGNO DEL PILORO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C165',
        descriptor: 'TUMOR MALIGNO DE LA CURVATURA MENOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C166',
        descriptor: 'TUMOR MALIGNO DE LA CURVATURA MAYOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C168',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C169',
        descriptor: 'TUMOR MALIGNO DEL ESTOMAGO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C17',
        descriptor: 'TUMOR MALIGNO DEL INTESTINO DELGADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C170',
        descriptor: 'TUMOR MALIGNO DEL DUODENO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C171',
        descriptor: 'TUMOR MALIGNO DEL YEYUNO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '14',
        name      : 'CANCER EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'C172',
        descriptor: 'TUMOR MALIGNO DEL ILEON',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 15,
    name      : 'ESQUIZOFRENIA',
    pathologic: [
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F20',
        descriptor: 'ESQUIZOFRENIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F200',
        descriptor: 'ESQUIZOFRENIA PARANOIDE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F201',
        descriptor: 'ESQUIZOFRENIA HEBEFRENICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F202',
        descriptor: 'ESQUIZOFRENIA CATATONICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F203',
        descriptor: 'ESQUIZOFRENIA INDIFERENCIADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F204',
        descriptor: 'DEPRESION POSTESQUIZOFRENICA (ESQUIZOFRENIA)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F205',
        descriptor: 'ESQUIZOFRENIA RESIDUAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F206',
        descriptor: 'ESQUIZOFRENIA SIMPLE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F208',
        descriptor: 'OTRAS ESQUIZOFRENIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F209',
        descriptor: 'ESQUIZOFRENIA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F231',
        descriptor: 'TRASTORNO PSICOTICO AGUDO POLIMORFO, CON SINTOMAS DE ESQUIZOFRENIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F232',
        descriptor: 'TRASTORNO PSICOTICO AGUDO DE TIPO ESQUIZOFRENICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F25',
        descriptor: 'TRASTORNOS ESQUIZOAFECTIVOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F250',
        descriptor: 'TRASTORNO ESQUIZOAFECTIVO DE TIPO MANIACO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F251',
        descriptor: 'TRASTORNO ESQUIZOAFECTIVO DE TIPO DEPRESIVO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F252',
        descriptor: 'TRASTORNO ESQUIZOAFECTIVO DE TIPO MIXTO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F258',
        descriptor: 'OTROS TRASTORNOS ESQUIZOAFECTIVOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F259',
        descriptor: 'TRASTORNO ESQUIZOAFECTIVO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '15',
        name      : 'ESQUIZOFRENIA',
        code      : 'F21',
        descriptor: 'TRASTORNO ESQUIZOTIPICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 16,
    name      : 'CANCER DE TESTICULO EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '16',
        name      : 'CANCER DE TESTICULO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C62',
        descriptor: 'TUMOR MALIGNO DEL TESTICULO',
        sex       : 'Masculino',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '16',
        name      : 'CANCER DE TESTICULO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C620',
        descriptor: 'TUMOR MALIGNO DEL TESTICULO NO DESCENDIDO',
        sex       : 'Masculino',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '16',
        name      : 'CANCER DE TESTICULO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C621',
        descriptor: 'TUMOR MALIGNO DEL TESTICULO DESCENDIDO',
        sex       : 'Masculino',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '16',
        name      : 'CANCER DE TESTICULO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C629',
        descriptor: 'TUMOR MALIGNO DEL TESTICULO, NO ESPECIFICADO',
        sex       : 'Masculino',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 17,
    name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C81 ',
        descriptor: 'LINFOMA DE HODGKIN',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C810',
        descriptor: 'LINFOMA NODULAR DE HODGKIN CON PREDOMINIO DE LINFOCITOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C811',
        descriptor: 'LINFOMA DE HODGKIN CLASICO CON ESCLEROSIS NODULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C812',
        descriptor: 'LINFOMA DE HODGKIN CLASICO CON CELULARIDAD MIXTA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C813',
        descriptor: 'LINFOMA DE HODGKIN CLASICO CON DEPLECION LINFOCITICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C814',
        descriptor: 'LINFOMA DE HODGKIN CLASICO RICO EN LINFOCITOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C817',
        descriptor: 'OTROS TIPOS DE LINFOMA CLASICO DE HODGKIN',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C819',
        descriptor: 'LINFOMA DE HODGKIN, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C82',
        descriptor: 'LINFOMA FOLICULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C820',
        descriptor: 'LINFOMA FOLICULAR GRADO I',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C821',
        descriptor: 'LINFOMA FOLICULAR GRADO II',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C822',
        descriptor: 'LINFOMA FOLICULAR GRADO III, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C823',
        descriptor: 'LINFOMA FOLICULAR GRADO IIIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C824',
        descriptor: 'LINFOMA FOLICULAR GRADO IIIB',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C825',
        descriptor: 'LINFOMA CENTRO FOLICULAR DIFUSO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C826',
        descriptor: 'LINFOMA CENTRO FOLICULAR CUTANEO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C827',
        descriptor: 'OTROS TIPOS ESPECIFICADOS DE LINFOMA NO-HODGKIN FOLICULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C829',
        descriptor: 'LINFOMA FOLICULAR, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C83',
        descriptor: 'LINFOMA NO FOLICULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C830',
        descriptor: 'LINFOMA DE CELULAS B PEQUEÑAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C831',
        descriptor: 'LINFOMA DE LA ZONA DEL MANTO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C833',
        descriptor: 'LINFOMA DE CELULAS B GRANDES DIFUSO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C835',
        descriptor: 'LINFOMA LINFOBLASTICO (DIFUSO)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C837',
        descriptor: 'LINFOMA DE BURKITT',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C838',
        descriptor: 'OTROS LINFOMAS NO FOLICULARES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C839',
        descriptor: 'LINFOMA NO FOLICULAR (DIFUSO), SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C84',
        descriptor: 'LINFOMAS DE CELULAS T/NK MADURAS ',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C840',
        descriptor: 'MICOSIS FUNGOIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C841',
        descriptor: 'ENFERMEDAD DE SEZARY',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C844',
        descriptor: 'LINFOMA PERIFERICO DE CELULAS T , NO CLASIFICADO EN OTRA PARTE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C845',
        descriptor: 'OTROS LINFOMAS DE CELULAS T/NK MADURAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C846',
        descriptor: 'LINFOMA ANAPLASICO DE CELULAS GRANDES, ALK POSITIVO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C847',
        descriptor: 'LINFOMA ANAPLASICO DE CELULAS GRANDES, ALK NEGATIVO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C848',
        descriptor: 'LINFOMA CUTANEO DE CELULAS T, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C849',
        descriptor: 'LINFOMA DE CELULAS T/NK MADURAS, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C85',
        descriptor: 'LINFOMA NO HODGKIN DE OTRO TIPO Y EL NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C851',
        descriptor: 'LINFOMA DE CELULAS B, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C852',
        descriptor: 'LINFOMA MEDIASTINAL DE CELULAS B GRANDES  (DEL TIMO)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C857',
        descriptor: 'OTROS TIPOS ESPECIFICADOS DE LINFOMA NO HODGKIN',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C859',
        descriptor: 'LINFOMA NO HODGKIN, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C86',
        descriptor: 'OTROS TIPOS ESPECIFICADOS DE LINFOMA DE CELULAS T/NK  ',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C860',
        descriptor: 'LINFOMA DE CELULAS T/NK EXTRANODALES, TIPO NASAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C861',
        descriptor: 'LINFOMA HEPATOESPLENICO DE CELULAS T ',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C862',
        descriptor: 'LINFOMA DE CELULAS T TIPO ENTEROPATIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C863',
        descriptor: 'LINFOMA DE CELULAS T, SUBCUTANEO, SEMEJANTE A PANICULITIS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C864',
        descriptor: 'LINFOMA BLASTICO DE CELULAS NK ',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C865',
        descriptor: 'LINFOMA ANGIOINMUNOBLASTICO DE CELULAS T',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C866',
        descriptor: 'TRASTORNOS LINFOPROLIFERATIVOS PRIMARIO CUTANEOS DE CELULAS T CD30-POSITIVO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C88',
        descriptor: 'ENFERMEDADES INMUNOPROLIFERATIVAS MALIGNAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C880',
        descriptor: 'MACROGLOBULINEMIA DE WALDENSTRÖM',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C882',
        descriptor: 'OTRAS ENFERMEDADES DE CADENA PESADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C883',
        descriptor: 'ENFERMEDAD INMUNOPROLIFERATIVA DEL INTESTINO DELGADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C884',
        descriptor:
            'LINFOMA DE CELULAS B DE ZONA MARGINAL EXTRANODAL DE TEJIDO LINFOIDE ASOCIADO A TEJIDO MUCOSO [LINFOMA MALT O TLAB]',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C887',
        descriptor: 'OTRAS ENFERMEDADES INMUNOPROLIFERATIVAS MALIGNAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C889',
        descriptor: 'ENFERMEDAD INMUNOPROLIFERATIVA MALIGNA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C96',
        descriptor:
            'OTROS TUMORES MALIGNOS Y LOS NO ESPECIFICADOS DEL TEJIDO LINFATICO, DE LOS ORGANOS HEMATOPOYETICOS Y DE TEJIDOS AFINES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C960',
        descriptor:
            'HISTIOCITOSIS DE CELULAS DE LANGERHANS MULTIFOCAL Y MULTISISTEMICA (DISEMINADA) [ENFERMEDAD LETTERER DE-SIWE]',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C962',
        descriptor: 'TUMOR MALIGNO DE MASTOCITOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C964',
        descriptor: 'SARCOMA DE CELULAS DENDRITICAS (CELULAS ACCESORIAS)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C965',
        descriptor: 'HISTIOCITOSIS MULTIFOCAL Y UNISISTEMICA DE CELULAS DE LANGERHANS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C966',
        descriptor: 'HISTIOCITOSIS UNIFOCAL DE CELULAS DE LANGERHANS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C967',
        descriptor: 'OTROS TUMORES MALIGNOS ESPECIFICADOS DEL TEJIDO LINFATICO, HEMATOPOYETICO Y TEJIDOS AFINES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C968',
        descriptor: 'SARCOMA HISTIOCITICO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C969',
        descriptor: 'TUMOR MALIGNO DEL TEJIDO LINFATICO, HEMATOPOYETICO Y TEJIDOS AFINES, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D181',
        descriptor: 'LINFANGIOMA, DE CUALQUIER SITIO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '17',
        name      : 'LINFOMAS EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D761',
        descriptor: 'LINFOHISTIOCITOSIS HEMOFAGOCITICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 18,
    name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
    pathologic: [
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B20',
        descriptor:
            'ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]}, RESULTANTE EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B200',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN INFECCION POR MICOBACTERIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B201',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS INFECCIONES BACTERIANAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B202',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDAD POR CITOMEGALOVIRUS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B203',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS INFECCIONES VIRALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B204',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN CANDIDIASIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B205',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS MICOSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B206',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN NEUMONIA POR PNEUMOCYSTIS CARINII',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B207',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN INFECCIONES MULTIPLES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B208',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS ENFERMEDADES INFECCIOSAS O PARASITARIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B209',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDAD INFECCIOSA O PARASITARIA NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B21',
        descriptor: 'ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]}, RESULTANTE  EN TUMORES MALIGNOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B210',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN SARCOMA DE KAPOSI',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B211',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN LINFOMA DE BURKITT',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B212',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN OTROS TIPOS DE LINFOMA NO HODGKIN',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B213',
        descriptor: 'ENF POR VIH, RESULT.OTROS TUMORES MALIGNOS. TEJ. LINFOIDE, HEMATOPOYETICO Y TEJ. RELACIONADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B218',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN OTROS TUMORES MALIGNOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B219',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN TUMORES MALIGNOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B22',
        descriptor:
            'ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]}, RESULTANTE EN OTRAS ENFERMEDADES ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B220',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN ENCEFALOPATIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B221',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN NEUMONITIS LINFOIDE INTERSTICIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B222',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN SINDROME CAQUECTICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B227',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDADES MULTIPLES CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B23',
        descriptor: 'ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]}, RESULTANTE EN OTRAS AFECCIONES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B230',
        descriptor: 'SINDROME DE INFECCION AGUDA DEBIDA A VIH',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B231',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN LINFADENOPATIA GENERALIZADA (PERSISTENTE)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B232',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN ANORMALIDADES INMUNOLOGICAS Y HEMATOLOGICAS, NCOP',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B238',
        descriptor: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS AFECCIONES ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'B24',
        descriptor: 'ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]}, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'F024',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'Z21',
        descriptor: 'ESTADO DE INFECCION ASINTOMATICA POR EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '18',
        name      : 'SINDROME DE LA INMUNODEFICIENCIA ADQUIRIDA VIH/SIDA ',
        code      : 'R75',
        descriptor: 'EVIDENCIAS DE LABORATORIO DEL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 19,
    name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
    pathologic: [
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'A378',
        descriptor: 'TOS FERINA DEBIDA A OTRAS ESPECIES DE BORDETELLA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'A379',
        descriptor: 'TOS FERINA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J04',
        descriptor: 'LARINGITIS Y TRAQUEITIS AGUDAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J040',
        descriptor: 'LARINGITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J041',
        descriptor: 'TRAQUEITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J042',
        descriptor: 'LARINGOTRAQUEITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J05',
        descriptor: 'LARINGITIS OBSTRUCTIVA AGUDA [CRUP] Y EPIGLOTITIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J050',
        descriptor: 'LARINGITIS OBSTRUCTIVA, AGUDA (CRUP)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J051',
        descriptor: 'EPIGLOTITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J060',
        descriptor: 'LARINGOFARINGITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J09',
        descriptor: 'INFLUENZA DEBIDA A CIERTOS VIRUS DE LA INFLUENZA IDENTIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J10',
        descriptor: 'INFLUENZA DEBIDA A OTRO VIRUS DE LA INFLUENZA IDENTIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J100',
        descriptor: 'INFLUENZA CON NEUMONIA, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J101',
        descriptor: 'INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J108',
        descriptor: 'INFLUENZA, CON OTRAS MANIFESTACIONES, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J11',
        descriptor: 'INFLUENZA DEBIDA A VIRUS NO IDENTIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J110',
        descriptor: 'INFLUENZA CON NEUMONIA, VIRUS NO IDENTIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J111',
        descriptor: 'INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, VIRUS NO IDENTIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J118',
        descriptor: 'INFLUENZA CON OTRAS MANIFESTACIONES, VIRUS NO IDENTIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J12',
        descriptor: 'NEUMONIA VIRAL, NO CLASIFICADA EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J120',
        descriptor: 'NEUMONIA DEBIDA A ADENOVIRUS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J121',
        descriptor: 'NEUMONIA DEBIDA A VIRUS SINCICIAL RESPIRATORIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J122',
        descriptor: 'NEUMONIA DEBIDA A VIRUS PARAINFLUENZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J123',
        descriptor: 'NEUMONIA DEBIDA A METANEUMOVIRUS HUMANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J128',
        descriptor: 'NEUMONIA DEBIDA A OTROS VIRUS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J129',
        descriptor: 'NEUMONIA VIRAL, NO ESPECIFICADA (VIRUS)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J13',
        descriptor: 'NEUMONIA DEBIDA A STREPTOCOCCUS PNEUMONIAE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J14',
        descriptor: 'NEUMONIA DEBIDA A HAEMOPHILUS INFLUENZAE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J150',
        descriptor: 'NEUMONIA DEBIDA A KLEBSIELLA PNEUMONIAE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J15',
        descriptor: 'NEUMONIA BACTERIANA, NO CLASIFICADA EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J151',
        descriptor: 'NEUMONIA DEBIDA A PSEUDOMONAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J152',
        descriptor: 'NEUMONIA DEBIDA A ESTAFILOCOCOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J153',
        descriptor: 'NEUMONIA DEBIDA A ESTREPTOCOCOS DEL GRUPO B',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J154',
        descriptor: 'NEUMONIA DEBIDA A OTROS ESTREPTOCOCOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J155',
        descriptor: 'NEUMONIA DEBIDA A ESCHERICHIA COLI',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J156',
        descriptor: 'NEUMONIA DEBIDA A OTRAS BACTERIAS AEROBICAS GRAMNEGATIVAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J157',
        descriptor: 'NEUMONIA DEBIDA A MYCOPLASMA PNEUMONIAE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J158',
        descriptor: 'OTRAS NEUMONIAS BACTERIANAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J159',
        descriptor: 'NEUMONIA BACTERIANA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J16',
        descriptor: 'NEUMONIA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS, NO CLASIFICADOS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J160',
        descriptor: 'NEUMONIA DEBIDA A CLAMIDIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J168',
        descriptor: 'NEUMONIA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J180',
        descriptor: 'BRONCONEUMONIA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J181',
        descriptor: 'NEUMONIA LOBAR, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J188',
        descriptor: 'OTRAS NEUMONIAS, DE MICROORGANISMO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J189',
        descriptor: 'NEUMONIA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J20',
        descriptor: 'BRONQUITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J200',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A MYCOPLASMA PNEUMONIAE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J201',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A HAEMOPHILUS INFLUENZAE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J202',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A ESTREPTOCOCOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J203',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A VIRUS COXSACKIE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J204',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A VIRUS PARAINFLUENZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J205',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A VIRUS SINCITIAL RESPIRATORIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J206',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A RINOVIRUS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J207',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A VIRUS ECHO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J208',
        descriptor: 'BRONQUITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J209',
        descriptor: 'BRONQUITIS AGUDA, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J21',
        descriptor: 'BRONQUIOLITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J210',
        descriptor: 'BRONQUIOLITIS AGUDA DEBIDA A VIRUS SINCITIAL RESPIRATORIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J211',
        descriptor: 'BRONQUIOLITIS AGUDA DEBIDA A METANEUMOVIRUS HUMANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J218',
        descriptor: 'BRONQUIOLITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J219',
        descriptor: 'BRONQUIOLITIS AGUDA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'J22',
        descriptor: 'INFECCION AGUDA NO ESPECIFICADA DE LAS VIAS RESPIRATORIAS INFERIORES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'A370',
        descriptor: 'TOS FERINA DEBIDA A BORDETELLA PERTUSSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
      {
        id        : '19',
        name      : 'INFECCION RESPIRATORIA AGUDA (IRA) DE MANEJO AMBULATORIO EN PERSONAS MENORES DE 5 AÑOS',
        code      : 'A371',
        descriptor: 'TOS FERINA DEBIDA A BORDETELLA PARAPERTUSSIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '4 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 20,
    name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
    pathologic: [
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J86',
        descriptor: 'PIOTORAX',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J860',
        descriptor: 'PIOTORAX CON FISTULA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J869',
        descriptor: 'PIOTORAX SIN FISTULA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J10',
        descriptor: 'INFLUENZA DEBIDA A OTRO VIRUS DE LA INFLUENZA IDENTIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J100',
        descriptor: 'INFLUENZA CON NEUMONIA, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J101',
        descriptor: 'INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J108',
        descriptor: 'INFLUENZA, CON OTRAS MANIFESTACIONES, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J11',
        descriptor: 'INFLUENZA DEBIDA A VIRUS NO IDENTIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J110',
        descriptor: 'INFLUENZA CON NEUMONIA, VIRUS NO IDENTIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J111',
        descriptor: 'INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, VIRUS NO IDENTIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J118',
        descriptor: 'INFLUENZA CON OTRAS MANIFESTACIONES, VIRUS NO IDENTIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J12',
        descriptor: 'NEUMONIA VIRAL, NO CLASIFICADA EN OTRA PARTE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J120',
        descriptor: 'NEUMONIA DEBIDA A ADENOVIRUS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J121',
        descriptor: 'NEUMONIA DEBIDA A VIRUS SINCICIAL RESPIRATORIO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J122',
        descriptor: 'NEUMONIA DEBIDA A VIRUS PARAINFLUENZA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J123',
        descriptor: 'NEUMONIA DEBIDA A METANEUMOVIRUS HUMANO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J128',
        descriptor: 'NEUMONIA DEBIDA A OTROS VIRUS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J129',
        descriptor: 'NEUMONIA VIRAL, NO ESPECIFICADA (VIRUS)',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J13',
        descriptor: 'NEUMONIA DEBIDA A STREPTOCOCCUS PNEUMONIAE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J14',
        descriptor: 'NEUMONIA DEBIDA A HAEMOPHILUS INFLUENZAE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J15',
        descriptor: 'NEUMONIA BACTERIANA, NO CLASIFICADA EN OTRA PARTE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J150',
        descriptor: 'NEUMONIA DEBIDA A KLEBSIELLA PNEUMONIAE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J151',
        descriptor: 'NEUMONIA DEBIDA A PSEUDOMONAS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J152',
        descriptor: 'NEUMONIA DEBIDA A ESTAFILOCOCOS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J153',
        descriptor: 'NEUMONIA DEBIDA A ESTREPTOCOCOS DEL GRUPO B',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J154',
        descriptor: 'NEUMONIA DEBIDA A OTROS ESTREPTOCOCOS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J155',
        descriptor: 'NEUMONIA DEBIDA A ESCHERICHIA COLI',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J156',
        descriptor: 'NEUMONIA DEBIDA A OTRAS BACTERIAS AEROBICAS GRAMNEGATIVAS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J157',
        descriptor: 'NEUMONIA DEBIDA A MYCOPLASMA PNEUMONIAE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J158',
        descriptor: 'OTRAS NEUMONIAS BACTERIANAS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J159',
        descriptor: 'NEUMONIA BACTERIANA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J16',
        descriptor: 'NEUMONIA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS, NO CLASIFICADOS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J160',
        descriptor: 'NEUMONIA DEBIDA A CLAMIDIAS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J168',
        descriptor: 'NEUMONIA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS ESPECIFICADOS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J180',
        descriptor: 'BRONCONEUMONIA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J181',
        descriptor: 'NEUMONIA LOBAR, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J188',
        descriptor: 'OTRAS NEUMONIAS, DE MICROORGANISMO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J189',
        descriptor: 'NEUMONIA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J170',
        descriptor: 'NEUMONIA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J171',
        descriptor: 'NEUMONIA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '20',
        name      : 'NEUMONIA ADQUIRIDA EN LA COMUNIDAD DE MANEJO AMBULATORIO EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'J178',
        descriptor: 'NEUMONIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 21,
    name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I10',
        descriptor: 'HIPERTENSION ESENCIAL (PRIMARIA)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I110',
        descriptor: 'ENFERMEDAD CARDIACA HIPERTENSIVA CON INSUFICIENCIA CARDIACA (CONGESTIVA)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I119',
        descriptor: 'ENFERMEDAD CARDIACA HIPERTENSIVA SIN INSUFICIENCIA CARDIACA (CONGESTIVA)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I120',
        descriptor: 'ENFERMEDAD RENAL HIPERTENSIVA CON INSUFICIENCIA RENAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I129',
        descriptor: 'ENFERMEDAD RENAL HIPERTENSIVA SIN INSUFICIENCIA RENAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I130',
        descriptor: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA CARDIACA (CONGESTIVA)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I131',
        descriptor: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA RENAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I139',
        descriptor: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I11',
        descriptor: 'ENFERMEDAD CARDIACA HIPERTENSIVA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I12',
        descriptor: 'ENFERMEDAD RENAL HIPERTENSIVA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '21',
        name      : 'HIPERTENSION ARTERIAL PRIMARIA O ESENCIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I132',
        descriptor: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIAS CARDIACA Y RENAL (CONGESTIVA)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 22,
    name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
    pathologic: [
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'F803',
        descriptor: 'AFASIA ADQUIRIDA CON EPILEPSIA (LANDAU-KLEFFNER)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G400',
        descriptor:
            'EPILEPSIA Y SINDR.EPIL.IDIOPAT.RELAC.C/ LOCALIZAC.(FOCALES) (PARC.) Y C/ ATAQUES DE INICIO LOCALIZ.',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G401',
        descriptor: 'EPILEPSIA Y SINDR. EPIL.SINTOMAT.RELAC.C/LOCALIZAC.(FOCALES) (PARC.) Y C/ATAQUES PARC.SIMPLES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G402',
        descriptor: 'EPILEPSIA Y SINDR. EPIL.SINTOMAT.RELAC.C/LOCALIZAC.(FOCALES) (PARC.) Y C/ATAQUES PARC. COMPLEJOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G403',
        descriptor: 'EPILEPSIA Y SINDROMES EPILEPTICOS IDIOPATICOS GENERALIZADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G404',
        descriptor: 'OTRAS EPILEPSIAS Y SINDROMES EPILEPTICOS GENERALIZADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G405',
        descriptor: 'SINDROMES EPILEPTICOS ESPECIALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G406',
        descriptor: 'ATAQUES DE GRAN MAL, NO ESPECIFICADOS (CON O SIN PEQUEÑO MAL)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G407',
        descriptor: 'PEQUEÑO MAL, NO ESPECIFICADO (SIN ATAQUE DE GRAN MAL)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G408',
        descriptor: 'OTRAS EPILEPSIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G409',
        descriptor: 'EPILEPSIA, TIPO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G410',
        descriptor: 'ESTADO DE GRAN MAL EPILEPTICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G411',
        descriptor: 'ESTADO DE PEQUEÑO MAL EPILEPTICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G412',
        descriptor: 'ESTADO DE MAL EPILEPTICO PARCIAL COMPLEJO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G418',
        descriptor: 'OTROS ESTADOS EPILEPTICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G419',
        descriptor: 'ESTADO DE MAL EPILEPTICO DE TIPO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G40',
        descriptor: 'EPILEPSIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '22',
        name      : 'EPILEPSIA NO REFRACTARIA EN PERSONAS DESDE UN AÑO Y MENORES DE 15 AÑOS',
        code      : 'G41',
        descriptor: 'ESTADO DE MAL EPILEPTICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 23,
    name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
    pathologic: [
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K002',
        descriptor: 'ANOMALIAS DEL TAMAÑO Y DE LA FORMA DEL DIENTE',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K004',
        descriptor: 'ALTERACIONES EN LA FORMACION DENTARIA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K008',
        descriptor: 'OTROS TRASTORNOS DEL DESARROLLO DE LOS DIENTES',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K009',
        descriptor: 'TRASTORNO DEL DESARROLLO DE LOS DIENTES, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K02',
        descriptor: 'CARIES DENTAL',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K020',
        descriptor: 'CARIES LIMITADA AL ESMALTE',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K021',
        descriptor: 'CARIES DE LA DENTINA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K022',
        descriptor: 'CARIES DEL CEMENTO',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K023',
        descriptor: 'CARIES DENTARIA DETENIDA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K024',
        descriptor: 'ODONTOCLASIA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K025',
        descriptor: 'CARIES CON EXPOSICION PULPAR',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K028',
        descriptor: 'OTRAS CARIES DENTALES',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K029',
        descriptor: 'CARIES DENTAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K036',
        descriptor: 'DEPOSITOS (ACRECIONES) EN LOS DIENTES',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K037',
        descriptor: 'CAMBIOS POSTERUPTIVOS DEL COLOR DE LOS TEJIDOS DENTALES DUROS',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K038',
        descriptor: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LOS TEJIDOS DUROS DE LOS DIENTES',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K04',
        descriptor: 'ENFERMEDADES DE LA PULPA Y DE LOS TEJIDOS PERIAPICALES',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K040',
        descriptor: 'PULPITIS',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K041',
        descriptor: 'NECROSIS DE LA PULPA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K042',
        descriptor: 'DEGENERACION DE LA PULPA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K043',
        descriptor: 'FORMACION ANORMAL DE TEJIDO DURO EN LA PULPA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K044',
        descriptor: 'PERIODONTITIS APICAL AGUDA ORIGINADA EN LA PULPA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K045',
        descriptor: 'PERIODONTITIS APICAL CRONICA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K046',
        descriptor: 'ABSCESO PERIAPICAL CON FISTULA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K047',
        descriptor: 'ABSCESO PERIAPICAL SIN FISTULA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K048',
        descriptor: 'QUISTE RADICULAR',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K049',
        descriptor: 'OTRAS ENFERMEDADES Y LAS NO ESPECIFICADAS DE LA PULPA Y DEL TEJIDO PERIAPICAL',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K050',
        descriptor: 'GINGIVITIS AGUDA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K051',
        descriptor: 'GINGIVITIS CRONICA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K052',
        descriptor: 'PERIODONTITIS AGUDA',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'Z012',
        descriptor: 'EXAMEN ODONTOLOGICO',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'A691',
        descriptor: 'OTRAS INFECCIONES DE VINCENT',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'T859',
        descriptor: 'COMPLICACION NO ESPECIFICADA DE DISPOSITIVO PROTESICO, IMPLANTE E INJERTO INTERNO',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'T810',
        descriptor: 'HEMORRAGIA Y HEMATOMA QUE COMPLICAN UN PROCEDIMIENTO, NO CLASIFICADOS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K088',
        descriptor: 'OTRAS AFECCIONES ESPECIFICADAS DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTEN',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
      {
        id        : '23',
        name      : 'SALUD ORAL INTEGRAL PARA NIÑOS Y NIÑAS DE 6 AÑOS',
        code      : 'K103',
        descriptor: 'ALVEOLITIS DEL MAXILAR',
        sex       : 'No aplica',
        min       : '6 años, 0 meses, 0 dias',
        max       : '6 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 24,
    name      : 'PREVENCION DEL PARTO PREMATURO',
    pathologic: [
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O23',
        descriptor: 'INFECCION DE LAS VIAS URINARIAS EN EL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O230',
        descriptor: 'INFECCION DEL RIÑON EN EL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O231',
        descriptor: 'INFECCION DE LA VEJIGA URINARIA EN EL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O232',
        descriptor: 'INFECCION DE LA URETRA EN EL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O233',
        descriptor: 'INFECCION DE OTRAS PARTES DE LAS VIAS URINARIAS EN EL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O234',
        descriptor: 'INFECCION NO ESPECIFICADA DE LAS VIAS URINARIAS EN EL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O235',
        descriptor: 'INFECCION GENITAL EN EL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O239',
        descriptor: 'OTRAS INFECCIONES Y LAS NO ESPECIFICADAS DE LAS VIAS GENITOURINARIAS EN EL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O262',
        descriptor: 'ATENCION DEL EMBARAZO EN UNA ABORTADORA HABITUAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O30',
        descriptor: 'EMBARAZO MULTIPLE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O300',
        descriptor: 'EMBARAZO DOBLE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O301',
        descriptor: 'EMBARAZO TRIPLE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O302',
        descriptor: 'EMBARAZO CUADRUPLE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O308',
        descriptor: 'OTROS EMBARAZOS MULTIPLES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O309',
        descriptor: 'EMBARAZO MULTIPLE, NO ESPECIFICADO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O340',
        descriptor: 'ATENCION MATERNA POR ANOMALIA CONGENITA DEL UTERO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O341',
        descriptor: 'ATENCION MATERNA POR TUMOR DEL CUERPO DEL UTERO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O344',
        descriptor: 'ATENCION MATERNA POR OTRA ANORMALIDAD DEL CUELLO UTERINO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O363',
        descriptor: 'ATENCION MATERNA POR SIGNOS DE HIPOXIA FETAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O365',
        descriptor: 'ATENCION MATERNA POR DEFICIT DEL CRECIMIENTO FETAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O40',
        descriptor: 'POLIHIDRAMNIOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O429',
        descriptor: 'RUPTURA PREMATURA DE LAS MEMBRANAS, SIN OTRA ESPECIFICACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O44',
        descriptor: 'PLACENTA PREVIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O440',
        descriptor: 'PLACENTA PREVIA CON ESPECIFICACION DE QUE NO HUBO HEMORRAGIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O441',
        descriptor: 'PLACENTA PREVIA CON HEMORRAGIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O459',
        descriptor: 'DESPRENDIMIENTO PREMATURO DE LA PLACENTA, SIN OTRA ESPECIFICACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O469',
        descriptor: 'HEMORRAGIA ANTEPARTO, NO ESPECIFICADA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O470',
        descriptor: 'FALSO TRABAJO DE PARTO ANTES DE LAS 37 SEMANAS COMPLETAS DE GESTACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O60',
        descriptor: 'TRABAJO DE PARTO PREMATURO Y PARTO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O600',
        descriptor: 'TRABAJO DE PARTO PREMATURO SIN PARTO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O601',
        descriptor: 'TRABAJO DE PARTO PREMATURO ESPONTANEO CON PARTO PREMATURO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O602',
        descriptor: 'TRABAJO DE PARTO PREMATURO ESPONTANEO CON PARTO A TERMINO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'O603',
        descriptor: 'PARTO PREMATURO SIN TRABAJO DE PARTO ESPONTANEO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P000',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR TRASTORNOS HIPERTENSIVOS DE LA MADRE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P001',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR ENFERMEDADES RENALES Y DE LAS VIAS URINARIAS DE LA MADRE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P002',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR ENFERMEDADES INFECCIOSAS Y PARASITARIAS DE LA MADRE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P003',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS ENFERMEDADES CIRCULATORIAS Y RESPIRATORIAS DE LA MADRE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P004',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR TRASTORNOS NUTRICIONALES DE LA MADRE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P005',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR TRAUMATISMO DE LA MADRE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P010',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR INCOMPETENCIA DEL CUELLO DEL UTERINO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P011',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR RUPTURA PREMATURA DE LAS MEMBRANAS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P012',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR OLIGOHIDRAMNIOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P013',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR POLIHIDRAMNIOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P015',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR EMBARAZO MULTIPLE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P018',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS COMPLICACIONES MATERNAS DEL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P019',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR COMPLICACIONES MATERNAS NO ESPECIFICADAS DEL EMBARAZO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P02',
        descriptor:
            'FETO Y RECIEN NACIDO AFECTADO POR COMPLICACIONES DE LA PLACENTA, DEL CORDON UMBILICAL Y DE LAS MEMBRANAS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P020',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR PLACENTA PREVIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P021',
        descriptor: 'FETO Y REC.NAC.AFECT.POR OTRAS FORMAS DE DESPRENDIMIENTO Y DE HEMORRAGIA PLACENTARIOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P022',
        descriptor: 'FETO Y REC.NAC.AFECT.POR OTRAS ANORMALIDADES MORFOLOG.Y FUNC.DE PLACENTA Y LAS NO ESPECIF.',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P023',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR SINDROMES DE TRANSFUSION PLACENTARIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P024',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR PROLAPSO DEL CORDON UMBILICAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P025',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRA COMPRESION DEL CORDON UMBILICAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P026',
        descriptor: 'FETO Y REC.NAC.AFECT.POR OTRAS COMPLICACIONES DEL CORDON UMBILICAL Y LAS NO ESPECIFICADAS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P027',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR CORIOAMNIONITIS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P028',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS ANORMALIDADES DE LAS MEMBRANAS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P029',
        descriptor: 'FETO Y RECIEN NACIDO AFECTADOS POR ANORMALIDAD NO ESPECIFICADA DE LAS MEMBRANAS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P05',
        descriptor: 'RETARDO DEL CRECIMIENTO FETAL Y DESNUTRICION FETAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P050',
        descriptor: 'BAJO PESO PARA LA EDAD GESTACIONAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P051',
        descriptor: 'PEQUEÑO PARA LA EDAD GESTACIONAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P052',
        descriptor: 'DESNUTRICION FETAL, SIN MENCION DE PESO O TALLA BAJOS PARA LA EDAD GESTACIONAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '24',
        name      : 'PREVENCION DEL PARTO PREMATURO',
        code      : 'P059',
        descriptor: 'RETARDO DEL CRECIMIENTO FETAL, NO ESPECIFICADO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 25,
    name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
    pathologic: [
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I44',
        descriptor: 'BLOQUEO AURICULOVENTRICULAR Y DE RAMA IZQUIERDA DEL HAZ',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I440',
        descriptor: 'BLOQUEO AURICULOVENTRICULAR DE PRIMER GRADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I441',
        descriptor: 'BLOQUEO AURICULOVENTRICULAR DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I442',
        descriptor: 'BLOQUEO AURICULOVENTRICULAR COMPLETO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I443',
        descriptor: 'OTROS TIPOS DE BLOQUEO AURICULOVENTRICULAR Y LOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I446',
        descriptor: 'OTROS TIPOS DE BLOQUEO FASCICULAR Y LOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I447',
        descriptor: 'BLOQUEO DE RAMA IZQUIERDA DEL HAZ, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I45',
        descriptor: 'OTROS TRASTORNOS DE LA CONDUCCION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I451',
        descriptor: 'OTROS TIPOS DE BLOQUEO DE RAMA DERECHA DEL HAZ Y LOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I452',
        descriptor: 'BLOQUEO BIFASCICULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I453',
        descriptor: 'BLOQUEO TRIFASCICULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I454',
        descriptor: 'BLOQUEO INTRAVENTRICULAR NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I455',
        descriptor: 'OTROS TIPOS ESPECIFICADOS DE BLOQUEO DEL CORAZON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I458',
        descriptor: 'OTROS TRASTORNOS ESPECIFICADOS DE LA CONDUCCION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I459',
        descriptor: 'TRASTORNO DE LA CONDUCCION, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I49',
        descriptor: 'OTRAS ARRITMIAS CARDIACAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'I495',
        descriptor: 'SINDROME DEL SENO ENFERMO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'R001',
        descriptor: 'BRADICARDIA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '25',
        name      : 'TRASTORNOS DE GENERACION DEL IMPULSO CARDIACO Y CONDUCCION EN PERSONAS DE 15 AÑOS Y MAS, QUE REQUIEREN MARCAPASO125',
        code      : 'R55',
        descriptor: 'SINCOPE Y COLAPSO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 26,
    name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
    pathologic: [
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K80',
        descriptor: 'COLELITIASIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K800',
        descriptor: 'CALCULO DE LA VESICULA BILIAR CON COLECISTITIS AGUDA',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K801',
        descriptor: 'CALCULO DE LA VESICULA BILIAR CON OTRA COLECISTITIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K802',
        descriptor: 'CALCULO DE LA VESICULA BILIAR SIN COLECISTITIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K803',
        descriptor: 'CALCULO DE CONDUCTO BILIAR CON COLANGITIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K804',
        descriptor: 'CALCULO DE CONDUCTO BILIAR CON COLECISTITIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K805',
        descriptor: 'CALCULO DE CONDUCTO BILIAR SIN COLANGITIS NI COLECISTITIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K808',
        descriptor: 'OTRAS COLELITIASIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K81',
        descriptor: 'COLECISTITIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K810',
        descriptor: 'COLECISTITIS AGUDA',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K811',
        descriptor: 'COLECISTITIS CRONICA',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K818',
        descriptor: 'OTRAS COLECISTITIS',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K819',
        descriptor: 'COLECISTITIS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
      {
        id        : '26',
        name      : 'COLECISTECTOMIA PREVENTIVA DEL CANCER DE VESICULA  EN PERSONAS DE 35 A 49 AÑOS',
        code      : 'K820',
        descriptor: 'OBSTRUCCION DE LA VESICULA BILIAR',
        sex       : 'No aplica',
        min       : '35 años, 0 meses, 0 dias',
        max       : '48 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 27,
    name      : 'CANCER GASTRICO',
    pathologic: [
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C16',
        descriptor: 'TUMOR MALIGNO DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C160',
        descriptor: 'TUMOR MALIGNO DEL CARDIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C161',
        descriptor: 'TUMOR MALIGNO DEL FUNDUS GASTRICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C162',
        descriptor: 'TUMOR MALIGNO DEL CUERPO DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C163',
        descriptor: 'TUMOR MALIGNO DEL ANTRO PILORICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C164',
        descriptor: 'TUMOR MALIGNO DEL PILORO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C165',
        descriptor: 'TUMOR MALIGNO DE LA CURVATURA MENOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C166',
        descriptor: 'TUMOR MALIGNO DE LA CURVATURA MAYOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C168',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'C169',
        descriptor: 'TUMOR MALIGNO DEL ESTOMAGO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '27',
        name      : 'CANCER GASTRICO',
        code      : 'D002',
        descriptor: 'CARCINOMA IN SITU DEL ESTOMAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 28,
    name      : 'CANCER DE PROSTATA EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '28',
        name      : 'CANCER DE PROSTATA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C61',
        descriptor: 'TUMOR MALIGNO DE LA PROSTATA',
        sex       : 'Masculino',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '28',
        name      : 'CANCER DE PROSTATA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D075',
        descriptor: 'CARCINOMA IN SITU DE LA PROSTATA',
        sex       : 'Masculino',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 29,
    name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
    pathologic: [
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H442',
        descriptor: 'MIOPIA DEGENERATIVA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H52',
        descriptor: 'TRASTORNOS DE LA ACOMODACION Y DE LA REFRACCION',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H520',
        descriptor: 'HIPERMETROPIA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H521',
        descriptor: 'MIOPIA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H522',
        descriptor: 'ASTIGMATISMO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H523',
        descriptor: 'ANISOMETROPIA Y ANISEICONIA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H524',
        descriptor: 'PRESBICIA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H525',
        descriptor: 'TRASTORNOS DE LA ACOMODACION',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H526',
        descriptor: 'OTROS TRASTORNOS DE LA REFRACCION',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '29',
        name      : 'VICIOS DE REFRACCION EN PERSONAS DE 65 AÑOS Y MAS',
        code      : 'H527',
        descriptor: 'TRASTORNO DE LA REFRACCION, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 30,
    name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
    pathologic: [
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H49',
        descriptor: 'ESTRABISMO PARALITICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H490',
        descriptor: 'PARALISIS DEL NERVIO MOTOR OCULAR COMUN (III PAR)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H491',
        descriptor: 'PARALISIS DEL NERVIO PATETICO (IV PAR)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H492',
        descriptor: 'PARALISIS DEL NERVIO MOTOR OCULAR EXTERNO (VI PAR)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H493',
        descriptor: 'OFTALMOPLEJIA TOTAL (EXTERNA)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H494',
        descriptor: 'OFTALMOPLEJIA EXTERNA PROGRESIVA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H498',
        descriptor: 'OTROS ESTRABISMOS PARALITICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H499',
        descriptor: 'ESTRABISMO PARALITICO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H50',
        descriptor: 'OTROS ESTRABISMOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H500',
        descriptor: 'ESTRABISMO CONCOMITANTE CONVERGENTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H501',
        descriptor: 'ESTRABISMO CONCOMITANTE DIVERGENTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H502',
        descriptor: 'ESTRABISMO VERTICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H503',
        descriptor: 'HETEROTROPIA INTERMITENTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H504',
        descriptor: 'OTRAS HETEROTROPIAS O LAS NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H505',
        descriptor: 'HETEROFORIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H506',
        descriptor: 'ESTRABISMO MECANICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H508',
        descriptor: 'OTROS ESTRABISMOS ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H509',
        descriptor: 'ESTRABISMO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H510',
        descriptor: 'PARALISIS DE LA CONJUGACION DE LA MIRADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H511',
        descriptor: 'EXCESO E INSUFICIENCIA DE LA CONVERGENCIA OCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H512',
        descriptor: 'OFTALMOPLEJIA INTERNUCLEAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H518',
        descriptor: 'OTROS TRASTORNOS ESPECIFICADOS DE LOS MOVIMIENTOS BINOCULARES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
      {
        id        : '30',
        name      : 'ESTRABISMO EN PERSONAS MENORES DE 9 AÑOS',
        code      : 'H519',
        descriptor: 'TRASTORNO DEL MOVIMIENTO BINOCULAR, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '8 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 31,
    name      : 'RETINOPATIA DIABETICA',
    pathologic: [
      {
        id        : '31',
        name      : 'RETINOPATIA DIABETICA',
        code      : 'E103',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '31',
        name      : 'RETINOPATIA DIABETICA',
        code      : 'E113',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '31',
        name      : 'RETINOPATIA DIABETICA',
        code      : 'E123',
        descriptor: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '31',
        name      : 'RETINOPATIA DIABETICA',
        code      : 'E133',
        descriptor: 'OTRA DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '31',
        name      : 'RETINOPATIA DIABETICA',
        code      : 'E143',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES OFTALMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '31',
        name      : 'RETINOPATIA DIABETICA',
        code      : 'H360',
        descriptor: 'RETINOPATIA DIABETICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 32,
    name      : 'DESPRENDIMIENTO DE RETINA REGMATOGENO NO TRAUMATICO',
    pathologic: [
      {
        id        : '32',
        name      : 'DESPRENDIMIENTO DE RETINA REGMATOGENO NO TRAUMATICO',
        code      : 'H33',
        descriptor: 'DESPRENDIMIENTO Y DESGARRO DE LA RETINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '32',
        name      : 'DESPRENDIMIENTO DE RETINA REGMATOGENO NO TRAUMATICO',
        code      : 'H330',
        descriptor: 'DESPRENDIMIENTO DE LA RETINA CON RUPTURA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '32',
        name      : 'DESPRENDIMIENTO DE RETINA REGMATOGENO NO TRAUMATICO',
        code      : 'H335',
        descriptor: 'OTROS DESPRENDIMIENTOS DE LA RETINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 33,
    name      : 'HEMOFILIA',
    pathologic: [
      {
        id        : '33',
        name      : 'HEMOFILIA',
        code      : 'D66',
        descriptor: 'DEFICIENCIA HEREDITARIA DEL FACTOR VIII',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '33',
        name      : 'HEMOFILIA',
        code      : 'D67',
        descriptor: 'DEFICIENCIA HEREDITARIA DEL FACTOR IX',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '33',
        name      : 'HEMOFILIA',
        code      : 'D681',
        descriptor: 'DEFICIENCIA HEREDITARIA DEL FACTOR XI',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 34,
    name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F32',
        descriptor: 'EPISODIO DEPRESIVO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F320',
        descriptor: 'EPISODIO DEPRESIVO LEVE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F321',
        descriptor: 'EPISODIO DEPRESIVO MODERADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F322',
        descriptor: 'EPISODIO DEPRESIVO GRAVE SIN SINTOMAS PSICOTICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F323',
        descriptor: 'EPISODIO DEPRESIVO GRAVE CON SINTOMAS PSICOTICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F328',
        descriptor: 'OTROS EPISODIOS DEPRESIVOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F329',
        descriptor: 'EPISODIO DEPRESIVO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F33',
        descriptor: 'TRASTORNO DEPRESIVO RECURRENTE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F330',
        descriptor: 'TRASTORNO DEPRESIVO RECURRENTE, EPISODIO LEVE PRESENTE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F331',
        descriptor: 'TRASTORNO DEPRESIVO RECURRENTE, EPISODIO MODERADO PRESENTE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F332',
        descriptor: 'TRASTORNO DEPRESIVO RECURRENTE, EPISODIO DEPRESIVO GRAVE PRESENTE SIN SINTOMAS PSICOTICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F333',
        descriptor: 'TRASTORNO DEPRESIVO RECURRENTE, EPISODIO DEPRESIVO GRAVE PRESENTE, CON SINTOMAS PSICOTICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F334',
        descriptor: 'TRASTORNO DEPRESIVO RECURRENTE ACTUALMENTE EN REMISION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F338',
        descriptor: 'OTROS TRASTORNOS DEPRESIVOS RECURRENTES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '34',
        name      : 'DEPRESION EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F339',
        descriptor: 'TRASTORNO DEPRESIVO RECURRENTE, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 35,
    name      : 'TRATAMIENTO DE LA HIPERPLASIA BENIGNA DE LA PROSTATA EN PERSONAS SINTOMATICAS ',
    pathologic: [
      {
        id        : '35',
        name      : 'TRATAMIENTO DE LA HIPERPLASIA BENIGNA DE LA PROSTATA EN PERSONAS SINTOMATICAS ',
        code      : 'N40',
        descriptor: 'HIPERPLASIA DE LA PROSTATA',
        sex       : 'Masculino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '35',
        name      : 'TRATAMIENTO DE LA HIPERPLASIA BENIGNA DE LA PROSTATA EN PERSONAS SINTOMATICAS ',
        code      : 'D291',
        descriptor: 'TUMOR BENIGNO DE LA PROSTATA',
        sex       : 'Masculino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 36,
    name      : 'AYUDAS TECNICAS PARA PERSONAS DE 65 AÑOS Y MAS',
    pathologic: [
      {
        id        : '36',
        name      : 'AYUDAS TECNICAS PARA PERSONAS DE 65 AÑOS Y MAS',
        code      : 'Z736',
        descriptor: 'PROBLEMAS RELACIONADOS CON LA LIMITACION DE LAS ACTIVIDADES DEBIDO A DISCAPACIDAD',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '36',
        name      : 'AYUDAS TECNICAS PARA PERSONAS DE 65 AÑOS Y MAS',
        code      : 'Z740',
        descriptor: 'NECESIDAD DE ASISTENCIA DEBIDA A MOVILIDAD REDUCIDA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '36',
        name      : 'AYUDAS TECNICAS PARA PERSONAS DE 65 AÑOS Y MAS',
        code      : 'Z741',
        descriptor: 'PROBLEMAS RELACIONADOS CON LA NECESIDAD DE AYUDA PARA EL CUIDADO PERSONAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '36',
        name      : 'AYUDAS TECNICAS PARA PERSONAS DE 65 AÑOS Y MAS',
        code      : 'M15',
        descriptor: 'POLIARTROSIS',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '36',
        name      : 'AYUDAS TECNICAS PARA PERSONAS DE 65 AÑOS Y MAS',
        code      : 'M17',
        descriptor: 'GONARTROSIS [ARTROSIS DE LA RODILLA]',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '36',
        name      : 'AYUDAS TECNICAS PARA PERSONAS DE 65 AÑOS Y MAS',
        code      : 'M16',
        descriptor: 'COXARTROSIS [ARTROSIS DE LA CADERA]',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 37,
    name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'G45',
        descriptor: 'ATAQUES DE ISQUEMIA CEREBRAL TRANSITORIA Y SINDROMES AFINES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'G450',
        descriptor: 'SINDROME ARTERIAL VERTEBRO-BASILAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'G451',
        descriptor: 'SINDROME DE LA ARTERIA CAROTIDA (HEMISFERICO)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'G452',
        descriptor: 'SINDROMES ARTERIALES PRECEREBRALES BILATERALES Y MULTIPLES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'G453',
        descriptor: 'AMAUROSIS FUGAZ',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'G454',
        descriptor: 'AMNESIA GLOBAL TRANSITORIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'G458',
        descriptor: 'OTRAS ISQUEMIAS CEREBRALES TRANSITORIAS Y SINDROMES AFINES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'G459',
        descriptor: 'ISQUEMIA CEREBRAL TRANSITORIA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I63',
        descriptor: 'INFARTO CEREBRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I630',
        descriptor: 'INFARTO CEREBRAL DEBIDO A TROMBOSIS DE ARTERIAS PRECEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I631',
        descriptor: 'INFARTO CEREBRAL DEBIDO A EMBOLIA DE ARTERIAS PRECEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I632',
        descriptor: 'INFARTO CEREBRAL DEBIDO A OCLUSION O ESTENOSIS NO ESPECIFICADA DE ARTERIAS PRECEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I633',
        descriptor: 'INFARTO CEREBRAL DEBIDO A TROMBOSIS DE ARTERIAS CEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I634',
        descriptor: 'INFARTO CEREBRAL DEBIDO A EMBOLIA DE ARTERIAS CEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I635',
        descriptor: 'INFARTO CEREBRAL DEBIDO A OCLUSION O ESTENOSIS NO ESPECIFICADA DE ARTERIAS CEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I636',
        descriptor: 'INFARTO CEREBRAL DEBIDO A TROMBOSIS DE VENAS CEREBRALES, NO PIOGENO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I638',
        descriptor: 'OTROS INFARTOS CEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I639',
        descriptor: 'INFARTO CEREBRAL, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I64',
        descriptor: 'ACCIDENTE VASCULAR ENCEFALICO AGUDO, NO ESPECIFICADO COMO HEMORRAGICO O ISQUEMICO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '37',
        name      : 'ATAQUE CEREBROVASCULAR ISQUEMICO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I670',
        descriptor: 'DISECCION DE ARTERIAS CEREBRALES, SIN RUPTURA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 38,
    name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
    pathologic: [
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J40',
        descriptor: 'BRONQUITIS, NO ESPECIFICADA COMO AGUDA O CRONICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J410',
        descriptor: 'BRONQUITIS CRONICA SIMPLE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J411',
        descriptor: 'BRONQUITIS CRONICA MUCOPURULENTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J418',
        descriptor: 'BRONQUITIS CRONICA MIXTA SIMPLE Y MUCOPURULENTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J42',
        descriptor: 'BRONQUITIS CRONICA NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J43',
        descriptor: 'ENFISEMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J430',
        descriptor: 'SINDROME DE MACLEOD',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J431',
        descriptor: 'ENFISEMA PANLOBULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J432',
        descriptor: 'ENFISEMA CENTROLOBULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J438',
        descriptor: 'OTROS TIPOS DE ENFISEMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J439',
        descriptor: 'ENFISEMA, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J44',
        descriptor: 'OTRAS ENFERMEDADES PULMONARES OBSTRUCTIVAS CRONICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J440',
        descriptor: 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA C/INFECC. AG.DE VIAS RESPIRATORIAS INFERIORES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J441',
        descriptor: 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA CON EXACERBACION AGUDA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J448',
        descriptor: 'OTRAS ENFERMEDADES PULMONARES OBSTRUCTIVAS CRONICAS ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J449',
        descriptor: 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '38',
        name      : 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA DE TRATAMIENTO AMBULATORIO',
        code      : 'J47',
        descriptor: 'BRONQUIECTASIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 39,
    name      : 'ASMA BRONQUIAL MODERADA Y GRAVE EN PERSONAS MENORES DE 15 AÑOS',
    pathologic: [
      {
        id        : '39',
        name      : 'ASMA BRONQUIAL MODERADA Y GRAVE EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'J45',
        descriptor: 'ASMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '39',
        name      : 'ASMA BRONQUIAL MODERADA Y GRAVE EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'J450',
        descriptor: 'ASMA PREDOMINANTEMENTE ALERGICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '39',
        name      : 'ASMA BRONQUIAL MODERADA Y GRAVE EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'J451',
        descriptor: 'ASMA NO ALERGICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '39',
        name      : 'ASMA BRONQUIAL MODERADA Y GRAVE EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'J458',
        descriptor: 'ASMA MIXTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '39',
        name      : 'ASMA BRONQUIAL MODERADA Y GRAVE EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'J459',
        descriptor: 'ASMA, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
      {
        id        : '39',
        name      : 'ASMA BRONQUIAL MODERADA Y GRAVE EN PERSONAS MENORES DE 15 AÑOS',
        code      : 'J46',
        descriptor: 'ESTADO ASMATICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '14 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 40,
    name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
    pathologic: [
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'J12',
        descriptor: 'NEUMONIA VIRAL, NO CLASIFICADA EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'J123',
        descriptor: 'NEUMONIA DEBIDA A METANEUMOVIRUS HUMANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'J15',
        descriptor: 'NEUMONIA BACTERIANA, NO CLASIFICADA EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'J16',
        descriptor: 'NEUMONIA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS, NO CLASIFICADOS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P22',
        descriptor: 'DIFICULTAD RESPIRATORIA DEL RECIEN NACIDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P23',
        descriptor: 'NEUMONIA CONGENITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'I270',
        descriptor: 'HIPERTENSION PULMONAR PRIMARIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P220',
        descriptor: 'SINDROME DE DIFICULTAD RESPIRATORIA DEL RECIEN NACIDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P229',
        descriptor: 'DIFICULTAD RESPIRATORIA DEL RECIEN NACIDO, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P230',
        descriptor: 'NEUMONIA CONGENITA DEBIDA A AGENTE VIRAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P231',
        descriptor: 'NEUMONIA CONGENITA DEBIDA A CHLAMYDIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P232',
        descriptor: 'NEUMONIA CONGENITA DEBIDA A ESTAFILOCOCOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P233',
        descriptor: 'NEUMONIA CONGENITA DEBIDA A ESTREPTOCOCOS DEL GRUPO B',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P234',
        descriptor: 'NEUMONIA CONGENITA DEBIDA A ESCHERICHIA COLI',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P235',
        descriptor: 'NEUMONIA CONGENITA DEBIDA A PSEUDOMONAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P236',
        descriptor: 'NEUMONIA CONGENITA DEBIDA A OTROS AGENTES BACTERIANOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P238',
        descriptor: 'NEUMONIA CONGENITA DEBIDA A OTROS ORGANISMOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P239',
        descriptor: 'NEUMONIA CONGENITA, ORGANISMO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P240',
        descriptor: 'ASPIRACION NEONATAL DE MECONIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P241',
        descriptor: 'ASPIRACION NEONATAL DE LIQUIDO AMNIOTICO Y DE MOCO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P242',
        descriptor: 'ASPIRACION NEONATAL DE SANGRE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P243',
        descriptor: 'ASPIRACION NEONATAL DE LECHE Y ALIMENTO REGURGITADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P248',
        descriptor: 'OTROS SINDROMES DE ASPIRACION NEONATAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'P249',
        descriptor: 'SINDROME DE ASPIRACION NEONATAL, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
      {
        id        : '40',
        name      : 'SINDROME DE DIFICULTAD RESPIRATORIA EN EL RECIEN NACIDO',
        code      : 'Q790',
        descriptor: 'HERNIA DIAFRAGMATICA CONGENITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '0 años, 0 meses, 28 dias',
      },
    ],
  },
  {
    id        : 41,
    name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
    pathologic: [
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M16',
        descriptor: 'COXARTROSIS [ARTROSIS DE LA CADERA]',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M170',
        descriptor: 'GONARTROSIS PRIMARIA, BILATERAL',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M160',
        descriptor: 'COXARTROSIS PRIMARIA, BILATERAL',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M161',
        descriptor: 'OTRAS COXARTROSIS PRIMARIAS',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M162',
        descriptor: 'COXARTROSIS A CONSECUENCIA DE DISPLASIA, BILATERAL',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M163',
        descriptor: 'OTRAS COXARTROSIS DISPLASICAS',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M164',
        descriptor: 'COXARTROSIS POSTRAUMATICA, BILATERAL',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M165',
        descriptor: 'OTRA COXARTROSIS POSTRAUMATICA',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M166',
        descriptor: 'OTRA COXARTROSIS SECUNDARIA, BILATERAL',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M167',
        descriptor: 'OTRAS COXARTROSIS SECUNDARIAS',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M169',
        descriptor: 'COXARTROSIS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M17',
        descriptor: 'GONARTROSIS [ARTROSIS DE LA RODILLA]',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M171',
        descriptor: 'OTRAS GONARTROSIS PRIMARIAS',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M172',
        descriptor: 'GONARTROSIS POSTRAUMATICA, BILATERAL',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M173',
        descriptor: 'OTRAS GONARTROSIS POSTRAUMATICAS',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M174',
        descriptor: 'OTRAS GONARTROSIS SECUNDARIAS, BILATERALES',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M175',
        descriptor: 'OTRAS GONARTROSIS SECUNDARIAS',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '41',
        name      : 'TRATAMIENTO MEDICO EN PERSONAS DE 55 AÑOS Y MAS CON ARTROSIS DE CADERA Y/O RODILLA, LEVE O MODERADA',
        code      : 'M179',
        descriptor: 'GONARTROSIS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '55 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 42,
    name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
    pathologic: [
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I60',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I61',
        descriptor: 'HEMORRAGIA INTRAENCEFALICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I62',
        descriptor: 'OTRAS HEMORRAGIAS INTRACRANEALES NO TRAUMATICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I600',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA DE SIFON Y BIFURCACION CAROTIDEA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I601',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA CEREBRAL MEDIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I602',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA COMUNICANTE ANTERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I603',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA COMUNICANTE POSTERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I604',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA BASILAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I605',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA VERTEBRAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I606',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA DE OTRAS ARTERIAS INTRACRANEALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I607',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA INTRACRANEAL NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I608',
        descriptor: 'OTRAS HEMORRAGIAS SUBARACNOIDEAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '42',
        name      : 'HEMORRAGIA SUBARACNOIDEA SECUNDARIA A RUPTURA DE ANEURISMAS CEREBRALES',
        code      : 'I609',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 43,
    name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C71',
        descriptor: 'TUMOR MALIGNO DEL ENCEFALO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C72',
        descriptor:
            'TUMOR MALIGNO DE LA MEDULA ESPINAL, DE LOS NERVIOS CRANEALES Y DE OTRAS PARTES DEL SISTEMA NERVIOSO CENTRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D180',
        descriptor: 'HEMANGIOMA, DE CUALQUIER SITIO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D32',
        descriptor: 'TUMORES BENIGNOS DE LAS MENINGES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D320',
        descriptor: 'TUMOR BENIGNO DE LAS MENINGES CEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D321',
        descriptor: 'TUMOR BENIGNO DE LAS MENINGES RAQUIDEAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D329',
        descriptor: 'TUMOR BENIGNO DE LAS MENINGES, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D352',
        descriptor: 'TUMOR BENIGNO DE LA HIPOFISIS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D42',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D420',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES CEREBRALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D421',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES RAQUIDEAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D429',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D43',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO Y DEL SISTEMA NERVIOSO CENTRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D430',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, SUPRATENTORIAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D431',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, INFRANTENTORIAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D432',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D433',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS NERVIOS CRANEALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D434',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA MEDULA ESPINAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D437',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTRAS PARTES ESPECIF. DE SIST. NERV.CENTRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D439',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL SISTEMA NERVIOSO CENTRAL, SITIO NO ESPECIF.',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D443',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA HIPOFISIS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D444',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE CONDUCTO CRANEOFARINGEO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D330',
        descriptor: 'TUMOR BENIGNO DEL ENCEFALO, SUPRATENTORIAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D331',
        descriptor: 'TUMOR BENIGNO DEL ENCEFALO, INFRATENTORIAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D332',
        descriptor: 'TUMOR BENIGNO DEL ENCEFALO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D339',
        descriptor: 'TUMOR BENIGNO DEL SISTEMA NERVIOSO CENTRAL, SITIO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D489',
        descriptor: 'NEOPLASIA DE COMPORTAMIENTO INCIERTO DEL SISTEMA NERVIOSO CENTRAL, LOCALIZACION NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '43',
        name      : 'TUMORES PRIMARIOS DEL SISTEMA NERVIOSO CENTRAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D438',
        descriptor: 'NEOPLASIA DE COMPORTAMIENTO INCIERTO DE OTRAS PARTES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 44,
    name      : 'TRATAMIENTO QUIRURGICO DE HERNIA DEL NUCLEO PULPOSO LUMBAR',
    pathologic: [
      {
        id        : '44',
        name      : 'TRATAMIENTO QUIRURGICO DE HERNIA DEL NUCLEO PULPOSO LUMBAR',
        code      : 'M50',
        descriptor: 'TRASTORNOS DE DISCO CERVICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '44',
        name      : 'TRATAMIENTO QUIRURGICO DE HERNIA DEL NUCLEO PULPOSO LUMBAR',
        code      : 'M51',
        descriptor: 'OTROS TRASTORNOS DE LOS DISCOS INTERVERTEBRALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '44',
        name      : 'TRATAMIENTO QUIRURGICO DE HERNIA DEL NUCLEO PULPOSO LUMBAR',
        code      : 'M510',
        descriptor: 'TRASTORNOS DE DISCOS INTERVERTEBRALES LUMBARES Y OTROS, CON MIELOPATIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '44',
        name      : 'TRATAMIENTO QUIRURGICO DE HERNIA DEL NUCLEO PULPOSO LUMBAR',
        code      : 'M511',
        descriptor: 'TRASTORNOS DE DISCO LUMBAR Y OTROS, CON RADICULOPATIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '44',
        name      : 'TRATAMIENTO QUIRURGICO DE HERNIA DEL NUCLEO PULPOSO LUMBAR',
        code      : 'S331',
        descriptor: 'LUXACION DE VERTEBRA LUMBAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '44',
        name      : 'TRATAMIENTO QUIRURGICO DE HERNIA DEL NUCLEO PULPOSO LUMBAR',
        code      : 'M512',
        descriptor: 'OTROS DESPLAZAMIENTOS ESPECIFICADOS DE DISCO INTERVERTEBRAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 45,
    name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C91',
        descriptor: 'LEUCEMIA LINFOIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C910',
        descriptor: 'LEUCEMIA LINFOBLASTICA AGUDA [ALL O LLA]',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C911',
        descriptor: 'LEUCEMIA LINFOCITICA CRONICA DE CELULAS DE CELULAS B',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C913',
        descriptor: 'LEUCEMIA PROLINFOCITICA DE CELULAS B',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C914',
        descriptor: 'LEUCEMIA DE CELULAS VELLOSAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C915',
        descriptor: 'LEUCEMIA/LINFOMA DE CELULAS T ADULTAS (HTLV-1-ASOCIADO)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C916',
        descriptor: 'LEUCEMIA PROLINFOCITICA DE CELULAS TIPO T',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C917',
        descriptor: 'OTRAS LEUCEMIAS LINFOIDES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C918',
        descriptor: 'LEUCEMIA DE CELULAS B MADURAS TIPO BURKITT',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C919',
        descriptor: 'LEUCEMIA LINFOIDE, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C92',
        descriptor: 'LEUCEMIA MIELOIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C920',
        descriptor: 'LEUCEMIA MIELOBLASTICA AGUDA [AML O LMA]',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C921',
        descriptor: 'LEUCEMIA MIELOIDE CRONICA [LMC]}, BCR/ABL-POSITIVA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C922',
        descriptor: 'LEUCEMIA MIELOIDE CRONICA ATIPICA, BCR/ABL-NEGATIVA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C923',
        descriptor: 'SARCOMA MIELOIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C924',
        descriptor: 'LEUCEMIA PROMIELOCITICA AGUDA [PML O LPM]',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C925',
        descriptor: 'LEUCEMIA MIELOMONOCITICA AGUDA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C926',
        descriptor: 'LEUCEMIA MIELOIDE AGUDA CON ANORMALIDAD 11Q23',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C927',
        descriptor: 'OTRAS LEUCEMIAS MIELOIDES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C928',
        descriptor: 'LEUCEMIA MIELOIDE AGUDA CON DISPLASIA MULTILINAJE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C929',
        descriptor: 'LEUCEMIA MIELOIDE, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C93',
        descriptor: 'LEUCEMIA MONOCITICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C930',
        descriptor: 'LEUCEMIA MONOBLASTICA/MONOCITICA AGUDA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C931',
        descriptor: 'LEUCEMIA MIELOMONOCITICA CRONICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C933',
        descriptor: 'LEUCEMIA MIELOMONOCITICA JUVENIL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C937',
        descriptor: 'OTRAS LEUCEMIAS MONOCITICAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C939',
        descriptor: 'LEUCEMIA MONOCITICA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C94',
        descriptor: 'OTRAS LEUCEMIAS DE TIPO CELULAR ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C940',
        descriptor: 'LEUCEMIA ERITROIDE AGUDA Y ERITROLEUCEMIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C942',
        descriptor: 'LEUCEMIA MEGACARIOBLASTICA AGUDA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C943',
        descriptor: 'LEUCEMIA DE MASTOCITOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C944',
        descriptor: 'PANMIELOSIS AGUDA CON MIELOFIBROSIS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C946',
        descriptor: 'ENFERMEDAD MIELODISPLASICA Y MIELOPROLIFERATIVA, NO CLASIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C947',
        descriptor: 'OTRAS LEUCEMIAS ESPECIFICADAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C95',
        descriptor: 'LEUCEMIA DE CELULAS DE TIPO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C950',
        descriptor: 'LEUCEMIA AGUDA, CELULAS DE TIPO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C951',
        descriptor: 'LEUCEMIA CRONICA, CELULAS DE TIPO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C957',
        descriptor: 'OTRAS LEUCEMIAS DE CELULAS DE TIPO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C959',
        descriptor: 'LEUCEMIA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C901',
        descriptor: 'LEUCEMIA DE CELULAS PLASMATICAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D471',
        descriptor: 'ENFERMEDAD MIELOPROLIFERATIVA CRONICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D47',
        descriptor:
            'OTROS TUMORES DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFATICO, DE LOS ORGANOS HEMATOPOYETICOS Y DE TEJIDOS AFINES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D46',
        descriptor: 'SINDROMES MIELODISPLASICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D466',
        descriptor: 'SINDROME MIELODISPLASICO CON ANORMALIDAD CROMOSOMICA AISLADA DEL (5Q)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '45',
        name      : 'LEUCEMIA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D467',
        descriptor: 'OTROS SINDROMES MIELODISPLASICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 46,
    name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
    pathologic: [
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'A691',
        descriptor: 'OTRAS INFECCIONES DE VINCENT',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K04',
        descriptor: 'ENFERMEDADES DE LA PULPA Y DE LOS TEJIDOS PERIAPICALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K040',
        descriptor: 'PULPITIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K041',
        descriptor: 'NECROSIS DE LA PULPA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K042',
        descriptor: 'DEGENERACION DE LA PULPA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K043',
        descriptor: 'FORMACION ANORMAL DE TEJIDO DURO EN LA PULPA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K044',
        descriptor: 'PERIODONTITIS APICAL AGUDA ORIGINADA EN LA PULPA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K045',
        descriptor: 'PERIODONTITIS APICAL CRONICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K046',
        descriptor: 'ABSCESO PERIAPICAL CON FISTULA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K047',
        descriptor: 'ABSCESO PERIAPICAL SIN FISTULA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K048',
        descriptor: 'QUISTE RADICULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K049',
        descriptor: 'OTRAS ENFERMEDADES Y LAS NO ESPECIFICADAS DE LA PULPA Y DEL TEJIDO PERIAPICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K050',
        descriptor: 'GINGIVITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K052',
        descriptor: 'PERIODONTITIS AGUDA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K081',
        descriptor: 'PERDIDA DE DIENTES DEBIDA A ACCIDENTE, EXTRACCION O ENFERMEDAD PERIODONTAL LOCAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K102',
        descriptor: 'AFECCIONES INFLAMATORIAS DE LOS MAXILARES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K103',
        descriptor: 'ALVEOLITIS DEL MAXILAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'S025',
        descriptor: 'FRACTURA DE LOS DIENTES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'S032',
        descriptor: 'LUXACION DE DIENTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K062',
        descriptor: 'LESIONES DE LA ENCIA Y DE LA ZONA EDENTULA ASOCIADAS CON TRAUMATISMO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K068',
        descriptor: 'OTROS TRASTORNOS ESPECIFICADOS DE LA ENCIA Y DE LA ZONA EDENTULA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'K122',
        descriptor: 'CELULITIS Y ABSCESO DE BOCA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'S024',
        descriptor: 'FRACTURA DEL MALAR Y DEL HUESO MAXILAR SUPERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '46',
        name      : 'URGENCIA ODONTOLOGICA AMBULATORIA',
        code      : 'S026',
        descriptor: 'FRACTURA DEL MAXILAR INFERIOR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 47,
    name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
    pathologic: [
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'B370',
        descriptor: 'ESTOMATITIS CANDIDIASICA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'B379',
        descriptor: 'CANDIDIASIS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K000',
        descriptor: 'ANODONCIA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K02',
        descriptor: 'CARIES DENTAL',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K020',
        descriptor: 'CARIES LIMITADA AL ESMALTE',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K021',
        descriptor: 'CARIES DE LA DENTINA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K022',
        descriptor: 'CARIES DEL CEMENTO',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K023',
        descriptor: 'CARIES DENTARIA DETENIDA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K024',
        descriptor: 'ODONTOCLASIA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K025',
        descriptor: 'CARIES CON EXPOSICION PULPAR',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K028',
        descriptor: 'OTRAS CARIES DENTALES',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K029',
        descriptor: 'CARIES DENTAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K036',
        descriptor: 'DEPOSITOS (ACRECIONES) EN LOS DIENTES',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K051',
        descriptor: 'GINGIVITIS CRONICA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K053',
        descriptor: 'PERIODONTITIS CRONICA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K060',
        descriptor: 'RETRACCION GINGIVAL',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K132',
        descriptor: 'LEUCOPLASIA Y OTRAS ALTERACIONES DEL EPITELIO BUCAL, INCLUYENDO LA LENGUA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K133',
        descriptor: 'LEUCOPLASIA PILOSA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'Z012',
        descriptor: 'EXAMEN ODONTOLOGICO',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K038',
        descriptor: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LOS TEJIDOS DUROS DE LOS DIENTES',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K039',
        descriptor: 'ENFERMEDAD NO ESPECIFICADA DE LOS TEJIDOS DENTALES DUROS',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K043',
        descriptor: 'FORMACION ANORMAL DE TEJIDO DURO EN LA PULPA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K050',
        descriptor: 'GINGIVITIS AGUDA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K052',
        descriptor: 'PERIODONTITIS AGUDA',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
      {
        id        : '47',
        name      : 'SALUD ORAL INTEGRAL PARA ADULTOS  DE 60  AÑOS',
        code      : 'K081',
        descriptor: 'PERDIDA DE DIENTES DEBIDA A ACCIDENTE, EXTRACCION O ENFERMEDAD PERIODONTAL LOCAL',
        sex       : 'No aplica',
        min       : '60 años, 0 meses, 0 dias',
        max       : '60 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 48,
    name      : 'POLITRAUMATIZADO GRAVE',
    pathologic: [
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S00',
        descriptor: 'TRAUMATISMO SUPERFICIAL DE LA CABEZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S01',
        descriptor: 'HERIDA DE LA CABEZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S04',
        descriptor: 'TRAUMATISMO DE NERVIOS CRANEALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S06',
        descriptor: 'TRAUMATISMO INTRACRANEAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S07',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DE LA CABEZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S08',
        descriptor: 'AMPUTACION TRAUMATICA DE PARTE DE LA CABEZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S09',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA CABEZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S10',
        descriptor: 'TRAUMATISMO SUPERFICIAL DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S12',
        descriptor: 'FRACTURA DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S13',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S14',
        descriptor: 'TRAUMATISMO DE LA MEDULA ESPINAL Y DE NERVIOS A NIVEL DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S15',
        descriptor: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S17',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S19',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S20',
        descriptor: 'TRAUMATISMO SUPERFICIAL DEL TORAX',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S21',
        descriptor: 'HERIDA DEL TORAX',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S22',
        descriptor: 'FRACTURA DE LAS COSTILLAS, DEL ESTERNON Y DE LA COLUMNA TORACICA [DORSAL]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S23',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL TORAX',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S24',
        descriptor: 'TRAUMATISMO DE NERVIOS Y DE LA MEDULA ESPINAL A NIVEL DEL TORAX',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S25',
        descriptor: 'TRAUMATISMO DE VASOS SANGUINEOS DEL TORAX',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S26',
        descriptor: 'TRAUMATISMO DEL CORAZON',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S27',
        descriptor: 'TRAUMATISMO DE OTROS ORGANOS INTRATORACICOS Y DE LOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S28',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DEL TORAX Y AMPUTACION TRAUMATICA DE PARTE DEL TORAX',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S29',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPCIFICADOS DEL TORAX',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S30',
        descriptor: 'TRAUMATISMO SUPERFICIAL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S31',
        descriptor: 'HERIDA DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S32',
        descriptor: 'FRACTURA DE LA COLUMNA LUMBAR Y DE LA PELVIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S33',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE LA COLUMNA LUMBAR Y DE LA PELVIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S34',
        descriptor:
            'TRAUMATISMO DE LOS NERVIOS Y DE LA MEDULA ESPINAL LUMBAR, A NIVEL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S36',
        descriptor: 'TRAUMATISMO DE ORGANOS INTRAABDOMINALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S37',
        descriptor: 'TRAUMATISMO DE ORGANOS PELVICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S38',
        descriptor:
            'TRAUMATISMO POR APLASTAMIENTO Y AMPUTACION TRAUMATICA DE PARTE DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S39',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S40',
        descriptor: 'TRAUMATISMO SUPERIFICIAL DEL HOMBRO Y DEL BRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S43',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE LA CINTURA ESCAPULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S44',
        descriptor: 'TRAUMATISMO DE NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S45',
        descriptor: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DEL HOMBRO Y DEL BRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S46',
        descriptor: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DEL HOMBRO Y DEL BRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S48',
        descriptor: 'AMPUTACION TRAUMATICA DEL HOMBRO Y DEL BRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S53',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL CODO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S54',
        descriptor: 'TRAUMATISMO DE NERVIOS A NIVEL DEL ANTEBRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S55',
        descriptor: 'TRAUMATISMO DE LOS VASOS SANGUINEOS A NIVEL DEL ANTEBRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S56',
        descriptor: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DEL ANTEBRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S57',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DEL ANTEBRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S58',
        descriptor: 'AMPUTACION TRAUMATICA DEL ANTEBRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S59',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL ANTEBRAZO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S63',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS A NIVEL DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S64',
        descriptor: 'TRAUMATISMO DE NERVIOS A NIVEL DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S65',
        descriptor: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S66',
        descriptor: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S67',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S68',
        descriptor: 'AMPUTACION TRAUMATICA DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S69',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S70',
        descriptor: 'TRAUMATISMO SUPERFICIAL DE LA CADERA Y DEL MUSLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S72',
        descriptor: 'FRACTURA DEL FEMUR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S73',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE LA ARTICULACION Y DE LOS LIGAMENTOS DE LA CADERA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S74',
        descriptor: 'TRAUMATISMO DE NERVIOS A NIVEL DE LA CADERA Y DEL MUSLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S75',
        descriptor: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DE LA CADERA Y DEL MUSLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S76',
        descriptor: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DE LA CADERA Y DEL MUSLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S77',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DE LA CADERA Y DEL MUSLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S78',
        descriptor: 'AMPUTACION TRAUMATICA DE LA CADERA Y DEL MUSLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S79',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA CADERA Y DEL MUSLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S80',
        descriptor: 'TRAUMATISMO SUPERFICIAL DE LA PIERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S82',
        descriptor: 'FRACTURA DE LA PIERNA, INCLUSIVE DEL TOBILLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S83',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE LA RODILLA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S84',
        descriptor: 'TRAUMATISMO DE NERVIOS A NIVEL DE LA PIERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S85',
        descriptor: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DE LA PIERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S86',
        descriptor: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DE LA PIERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S87',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DE LA PIERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S88',
        descriptor: 'AMPUTACION TRAUMATICA DE LA PIERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S89',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA PIERNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S90',
        descriptor: 'TRAUMATISMO SUPERFICIAL DEL TOBILLO Y DEL PIE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S92',
        descriptor: 'FRACTURA DEL PIE, EXCEPTO DEL TOBILLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S93',
        descriptor: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL TOBILLO Y DEL PIE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S94',
        descriptor: 'TRAUMATISMO DE NERVIOS A NIVEL DEL PIE Y DEL TOBILLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S95',
        descriptor: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DEL PIE Y DEL TOBILLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S96',
        descriptor: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DEL PIE Y DEL TOBILLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S97',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DEL PIE Y DEL TOBILLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S98',
        descriptor: 'AMPUTACION TRAUMATICA DEL PIE Y DEL TOBILLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S99',
        descriptor: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL PIE Y DEL TOBILLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T00',
        descriptor: 'TRAUMATISMOS SUPERFICIALES QUE AFECTAN MULTIPLES REGIONES DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T01',
        descriptor: 'HERIDAS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T02',
        descriptor: 'FRACTURAS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T03',
        descriptor: 'LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MULTIPLES REGIONES DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T04',
        descriptor: 'TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MULTIPLES REGIONES DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T05',
        descriptor: 'AMPUTACIONES TRAUMATICAS  QUE AFECTAN MULTIPLES REGIONES DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T06',
        descriptor: 'OTROS TRAUMATISMOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO, NO CLASIFICADOS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T09',
        descriptor: 'OTROS TRAUMATISMOS DE LA COLUMNA VERTEBRAL Y DEL TRONCO, NIVEL NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T11',
        descriptor: 'OTROS TRAUMATISMOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T13',
        descriptor: 'OTROS TRAUMATISMOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T146',
        descriptor: 'TRAUMATISMO DE TENDONES Y MUSCULOS DE REGION NO ESPECIFICADA DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T147',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO Y AMPUTACION TRAUMATICA DE REGIONES NO ESPECIFIC. DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T148',
        descriptor: 'OTROS TRAUMATISMOS DE REGION NO ESPECIFICADA DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'T149',
        descriptor: 'TRAUMATISMO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S110',
        descriptor: 'HERIDA QUE COMPROMETE LA LARINGE Y LA TRAQUEA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S111',
        descriptor: 'HERIDA QUE COMPROMETE LA GLANDULA TIROIDES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S112',
        descriptor: 'HERIDA QUE COMPROMETE LA FARINGE Y EL ESOFAGO CERVICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S117',
        descriptor: 'HERIDAS MULTIPLES DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S118',
        descriptor: 'HERIDAS DE OTRAS PARTES DEL CUELLO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '48',
        name      : 'POLITRAUMATIZADO GRAVE',
        code      : 'S120',
        descriptor: 'FRACTURA DE LA PRIMERA VERTEBRA CERVICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 49,
    name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
    pathologic: [
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S02',
        descriptor: 'FRACTURA DE HUESOS DEL CRANEO Y DE LA CARA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S04',
        descriptor: 'TRAUMATISMO DE NERVIOS CRANEALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S06',
        descriptor: 'TRAUMATISMO INTRACRANEAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S07',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DE LA CABEZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S020',
        descriptor: 'FRACTURA DE LA BOVEDA DEL CRANEO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S021',
        descriptor: 'FRACTURA DE LA BASE DEL CRANEO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S027',
        descriptor: 'FRACTURAS MULTIPLES QUE COMPROMETEN EL CRANEO Y LOS HUESOS DE LA CARA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S028',
        descriptor: 'FRACTURA DE OTROS HUESOS DEL CRANEO Y DE LA CARA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S029',
        descriptor: 'FRACTURA DEL CRANEO Y DE LOS HUESOS DE LA CARA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S040',
        descriptor: 'TRAUMATISMO DEL NERVIO OPTICO [II PAR] Y DE LAS VIAS OPTICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S041',
        descriptor: 'TRAUMATISMO DEL NERVIO MOTOR OCULAR COMUN [III PAR]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S042',
        descriptor: 'TRAUMATISMO DEL NERVIO PATETICO [IV PAR]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S043',
        descriptor: 'TRAUMATISMO DEL NERVIO TRIGEMINO [V PAR]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S044',
        descriptor: 'TRAUMATISMO DEL NERVIO MOTOR OCULAR EXTERNO [VI PAR]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S045',
        descriptor: 'TRAUMATISMO DEL NERVIO FACIAL [VII PAR]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S046',
        descriptor: 'TRAUMATISMO DEL NERVIO ACUSTICO [VIII PAR]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S047',
        descriptor: 'TRAUMATISMO DEL NERVIO ESPINAL [XI PAR]',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S048',
        descriptor: 'TRAUMATISMO DE OTROS NERVIOS CRANEALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S049',
        descriptor: 'TRAUMATISMO DE NERVIOS CRANEALES, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S061',
        descriptor: 'EDEMA CEREBRAL TRAUMATICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S062',
        descriptor: 'TRAUMATISMO CEREBRAL DIFUSO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S063',
        descriptor: 'TRAUMATISMO CEREBRAL FOCAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S064',
        descriptor: 'HEMORRAGIA EPIDURAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S065',
        descriptor: 'HEMORRAGIA SUBDURAL TRAUMATICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S066',
        descriptor: 'HEMORRAGIA SUBARACNOIDEA TRAUMATICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S067',
        descriptor: 'TRAUMATISMO INTRACRANEAL CON COMA PROLONGADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S068',
        descriptor: 'OTROS TRAUMATISMOS INTRACRANEALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S069',
        descriptor: 'TRAUMATISMO INTRACRANEAL, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S097',
        descriptor: 'TRAUMATISMOS MULTIPLES DE LA CABEZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S098',
        descriptor: 'OTROS TRAUMATISMOS DE LA CABEZA, ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S099',
        descriptor: 'TRAUMATISMO DE LA CABEZA, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S070',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DE LA CARA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S071',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DEL CRANEO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S078',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DE LA CABEZA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '49',
        name      : 'TRAUMATISMO CRANEO ENCEFALICO MODERADO O GRAVE',
        code      : 'S079',
        descriptor: 'TRAUMATISMO POR APLASTAMIENTO DE LA CABEZA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 50,
    name      : 'TRAUMA OCULAR GRAVE',
    pathologic: [
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'H262',
        descriptor: 'CATARATA COMPLICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'H330',
        descriptor: 'DESPRENDIMIENTO DE LA RETINA CON RUPTURA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S021',
        descriptor: 'FRACTURA DE LA BASE DEL CRANEO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S022',
        descriptor: 'FRACTURA DE LOS HUESOS DE LA NARIZ',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S028',
        descriptor: 'FRACTURA DE OTROS HUESOS DEL CRANEO Y DE LA CARA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S05',
        descriptor: 'TRAUMATISMO DEL OJO Y DE LA ORBITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S050',
        descriptor: 'TRAUMATISMO DE LA CONJUNTIVA Y ABRASION CORNEAL SIN MENCION DE CUERPO EXTRAÑO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S051',
        descriptor: 'CONTUSION DEL GLOBO OCULAR Y DEL TEJIDO ORBITARIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S052',
        descriptor: 'LACERACION Y RUPTURA OCULAR CON PROLAPSO O PERDIDA DEL TEJIDO INTRAOCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S053',
        descriptor: 'LACERACION OCULAR SIN PROLAPSO O PERDIDA DEL TEJIDO INTRAOCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S054',
        descriptor: 'HERIDA PENETRANTE DE LA ORBITA CON O SIN CUERPO EXTRAÑO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S055',
        descriptor: 'HERIDA PENETRANTE DEL GLOBO OCULAR CON CUERPO EXTRAÑO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S056',
        descriptor: 'HERIDA PENETRANTE DE GLOBO OCULAR SIN CUERPO EXTRAÑO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S057',
        descriptor: 'AVULSION DE OJO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S058',
        descriptor: 'OTROS TRAUMATISMOS DEL OJO Y DE LA ORBITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S059',
        descriptor: 'TRAUMATISMO DEL OJO Y DE LA ORBITA, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T26',
        descriptor: 'QUEMADURA Y CORROSION LIMITADA AL OJO Y SUS ANEXOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T260',
        descriptor: 'QUEMADURA DEL PARPADO Y AREA PERIOCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T261',
        descriptor: 'QUEMADURA DE LA CORNEA Y SACO CONJUNTIVAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T262',
        descriptor: 'QUEMADURA CON RUPTURA Y DESTRUCCION RESULTANTES DEL GLOBO OCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T263',
        descriptor: 'QUEMADURA DE OTRAS PARTES DEL OJO Y SUS ANEXOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T264',
        descriptor: 'QUEMADURA DEL OJO Y ANEXOS, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T265',
        descriptor: 'CORROSION DEL PARPADO Y AREA PERIOCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T266',
        descriptor: 'CORROSION DE LA CORNEA Y SACO CONJUNTIVAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T267',
        descriptor: 'CORROSION CON RUPTURA Y DESTRUCCION RESULTANTES DEL GLOBO OCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T268',
        descriptor: 'CORROSION DE OTRAS PARTES DEL OJO Y SUS ANEXOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'T269',
        descriptor: 'CORROSION DEL OJO Y SUS ANEXOS, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'H261',
        descriptor: 'CATARATA TRAUMATICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '50',
        name      : 'TRAUMA OCULAR GRAVE',
        code      : 'S011',
        descriptor: 'HERIDA DEL PARPADO Y LA REGION PERIOCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 51,
    name      : 'FIBROSIS QUISTICA',
    pathologic: [
      {
        id        : '51',
        name      : 'FIBROSIS QUISTICA',
        code      : 'E84',
        descriptor: 'FIBROSIS QUISTICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '51',
        name      : 'FIBROSIS QUISTICA',
        code      : 'E840',
        descriptor: 'FIBROSIS QUISTICA CON MANIFESTACIONES PULMONARES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '51',
        name      : 'FIBROSIS QUISTICA',
        code      : 'E841',
        descriptor: 'FIBROSIS QUISTICA CON MANIFESTACIONES INTESTINALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '51',
        name      : 'FIBROSIS QUISTICA',
        code      : 'E848',
        descriptor: 'FIBROSIS QUISTICA CON OTRAS MANIFESTACIONES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '51',
        name      : 'FIBROSIS QUISTICA',
        code      : 'E849',
        descriptor: 'FIBROSIS QUISTICA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '51',
        name      : 'FIBROSIS QUISTICA',
        code      : 'P75',
        descriptor: 'ILEO MECONIAL EN LA FIBROSIS QUISTICA ',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '51',
        name      : 'FIBROSIS QUISTICA',
        code      : 'P760',
        descriptor: 'SINDROME DE TAPON MECONIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 52,
    name      : 'ARTRITIS REUMATOIDEA',
    pathologic: [
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M05',
        descriptor: 'ARTRITIS REUMATOIDE SEROPOSITIVA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M050',
        descriptor: 'SINDROME DE FELTY',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M051',
        descriptor: 'ENFERMEDAD REUMATOIDE DEL PULMON',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M052',
        descriptor: 'VASCULITIS REUMATOIDE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M053',
        descriptor: 'ARTRITIS REUMATOIDE CON COMPROMISO DE OTROS ORGANOS O SISTEMAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M058',
        descriptor: 'OTRAS ARTRITIS REUMATOIDEAS SEROPOSITIVAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M059',
        descriptor: 'ARTRITIS REUMATOIDEA SEROPOSITIVA SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M06',
        descriptor: 'OTRAS ARTRITIS REUMATOIDES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M060',
        descriptor: 'ARTRITIS REUMATOIDE SERONEGATIVA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M061',
        descriptor: 'ENFERMEDAD DE STILL DE COMIENZO EN EL ADULTO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M062',
        descriptor: 'BURSITIS REUMATOIDE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M063',
        descriptor: 'NODULO REUMATOIDE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M064',
        descriptor: 'POLIARTROPATIA INFLAMATORIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M068',
        descriptor: 'OTRAS ARTRITIS REUMATOIDES ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M069',
        descriptor: 'ARTRITIS REUMATOIDE, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M08',
        descriptor: 'ARTRITIS JUVENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M080',
        descriptor: 'ARTRITIS REUMATOIDE JUVENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M081',
        descriptor: 'ESPONDILITIS ANQUILOSANTE JUVENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M082',
        descriptor: 'ARTRITIS JUVENIL DE COMIENZO GENERALIZADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M083',
        descriptor: 'POLIARTRITIS JUVENIL (SERONEGATIVA)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M084',
        descriptor: 'ARTRITIS JUVENIL PAUCIARTICULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M088',
        descriptor: 'OTRAS ARTRITIS JUVENILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M089',
        descriptor: 'ARTRITIS JUVENIL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M130',
        descriptor: 'POLIARTRITIS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '52',
        name      : 'ARTRITIS REUMATOIDEA',
        code      : 'M45',
        descriptor: 'ESPONDILITIS ANQUILOSANTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 53,
    name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
    pathologic: [
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F10',
        descriptor: 'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F101',
        descriptor: 'USO NOCIVO DE ALCOHOL (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F102',
        descriptor: 'SINDROME DE DEPENDENCIA DEL ALCOHOL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F105',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE ALCOHOL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F107',
        descriptor: 'TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO DEBIDO AL USO DE ALCOHOL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F108',
        descriptor: 'OTRO TRASTORNO MENTAL Y DEL COMPORTAMIENTO DEBIDO AL USO DE ALCOHOL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F109',
        descriptor: 'TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO, DEBIDO AL USO DE ALCOHOL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F11',
        descriptor: 'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F111',
        descriptor: 'USO NOCIVO DE OPIACEOS (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F112',
        descriptor: 'SINDROME DE DEPENDENCIA DE OPIACEOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F115',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE OPIACEOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F116',
        descriptor: 'SINDROME AMNESICO DEBIDO AL USO DE OPIACEOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F117',
        descriptor: 'TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO DEBIDO AL USO DE OPIACEOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F118',
        descriptor: 'OTRO TRASTORNO MENTAL Y DEL COMPORTAMIENTO DEBIDO AL USO DE OPIACEOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F119',
        descriptor: 'TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO, DEBIDO AL USO DE OPIACEOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F12',
        descriptor: 'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F121',
        descriptor: 'USO NOCIVO DE CANNABINOIDES (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F122',
        descriptor: 'SINDROME DE DEPENDENCIA DE CANNABINOIDES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F125',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE CANNABINOIDES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F127',
        descriptor: 'TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO DEBIDO AL USO DE CANNABINOIDES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F128',
        descriptor: 'OTRO TRASTORNO MENTAL Y DEL COMPORTAMIENTO DEBIDO AL USO DE CANNABINOIDES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F129',
        descriptor: 'TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO, DEBIDO AL USO DE CANNABINOIDES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F13',
        descriptor: 'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES  O HIPNOTICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F131',
        descriptor: 'USO NOCIVO DE SEDANTES O HIPNOTICOS (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F132',
        descriptor: 'SINDROME DE DEPENDENCIA DE SEDANTES O HIPNOTICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F135',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE SEDANTES O HIPNOTICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F137',
        descriptor: 'TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO DEBIDO AL USO DE SEDANTES O HIPNOTICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F138',
        descriptor: 'OTRO TRASTORNO MENTAL Y DEL COMPORTAMIENTO DEBIDO AL USO DE SEDANTES O HIPNOTICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F139',
        descriptor: 'TRAST.MENTAL Y DE COMPORTAMIENTO, NO ESPECIFICADO, DEBIDO AL USO DE SEDANTES O HIPNOTICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F14',
        descriptor: 'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F141',
        descriptor: 'USO NOCIVO DE COCAINA (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F142',
        descriptor: 'SINDROME DE DEPENDENCIA DE COCAINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F145',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE COCAINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F147',
        descriptor: 'TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO, DEBIDO AL USO DE COCAINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F148',
        descriptor: 'OTRO TRASTORNO MENTAL Y DEL COMPORTAMIENTO, DEBIDO AL USO DE COCAINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F149',
        descriptor: 'TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO, DEBIDO AL USO DE COCAINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F15',
        descriptor:
            'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMILANTES, INCLUIDA LA CAFEINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F151',
        descriptor: 'USO NOCIVO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F152',
        descriptor: 'SINDROME DE DEPENDENCIA DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F155',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F157',
        descriptor: 'TRAST. PSICOT. RESIDUAL Y DE COMIENZO TARDIO DEBIDO A USO DE OTROS ESTIMULANTES, INCL.CAFEINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F158',
        descriptor: 'OTRO TRAST.MENTAL Y DE COMPORTAM. DEBIDO A USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F159',
        descriptor: 'TRAST.MENTAL Y DE COMPORTAM., NO ESPECIF., DEBIDO A USO DE OTROS ESTIMULANTES, INCL. LA CAFEINA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F16',
        descriptor: 'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F161',
        descriptor: 'USO NOCIVO DE ALUCINOGENOS (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F162',
        descriptor: 'SINDROME DE DEPENDENCIA DE ALUCINOGENOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F165',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE ALUCINOGENOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F167',
        descriptor: 'TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO DEBIDO AL USO DE ALUCINOGENOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F168',
        descriptor: 'OTRO TRASTORNO MENTAL Y DEL COMPORTAMIENTO DEBIDO AL USO DE ALUCINOGENOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F169',
        descriptor: 'TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO, DEBIDO AL USO DE ALUCINOGENOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F18',
        descriptor: 'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F181',
        descriptor: 'USO NOCIVO DE DISOLVENTES VOLATILES (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F182',
        descriptor: 'SINDROME DE DEPENDENCIA DE DISOLVENTES VOLATILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F185',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE DISOLVENTES VOLATILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F187',
        descriptor: 'TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO DEBIDO AL USO DE DISOLVENTES VOLATILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F188',
        descriptor: 'OTRO TRASTORNO MENTAL Y DEL COMPORTAMIENTO DEBIDO AL USO DE DISOLVENTES VOLATILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F189',
        descriptor: 'TRAST.MENTAL Y DE COMPORTAM., NO ESPECIFIC., DEBIDO AL USO DE DISOLVENTES VOLATILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F19',
        descriptor:
            'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F191',
        descriptor: 'USO NOCIVO DE MULTIPLES DROGAS Y DE OTRAS SUSTANCIAS PSICOACTIVAS (ABUSO)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F192',
        descriptor: 'SINDROME DE DEPENDENCIA DE MULTIPLES DROGAS Y DE OTRAS SUSTANCIAS PSICOACTIVAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F195',
        descriptor: 'TRASTORNO PSICOTICO DEBIDO AL USO DE MULTIPLES DROGAS Y DE OTRAS SUSTANCIAS PSICOACTIVAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F197',
        descriptor: 'TR.PSICOT.RESID. Y DE COMIENZO TARD. DEB.A USO DE MULTIPLES DROGAS Y OTRAS SUST. PSICOACTIVAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F198',
        descriptor: 'OTRO TR.MENT.Y DE COMPORT. DEBIDO A USO DE MULT. DROGAS Y DE OTRAS SUSTANCIAS PSICOACTIVAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
      {
        id        : '53',
        name      : 'CONSUMO PERJUDICIAL O DEPENDENCIA DE RIESGO BAJO A MODERADO DE ALCOHOL Y DROGAS EN PERSONAS MENORES DE  20 AÑOS',
        code      : 'F199',
        descriptor: 'TR.MENTAL Y DE COMPORTAMIENTO, NO ESPECIF., DEB. A USO DE MULT. DROGAS Y OTRAS SUST.PSICOACTIV.',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '19 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 54,
    name      : 'ANALGESIA DEL PARTO',
    pathologic: [
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O60',
        descriptor: 'TRABAJO DE PARTO PREMATURO Y PARTO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O600',
        descriptor: 'TRABAJO DE PARTO PREMATURO SIN PARTO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O601',
        descriptor: 'TRABAJO DE PARTO PREMATURO ESPONTANEO CON PARTO PREMATURO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O602',
        descriptor: 'TRABAJO DE PARTO PREMATURO ESPONTANEO CON PARTO A TERMINO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O603',
        descriptor: 'PARTO PREMATURO SIN TRABAJO DE PARTO ESPONTANEO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O757',
        descriptor: 'PARTO VAGINAL POSTERIOR A UNA CESAREA PREVIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O80',
        descriptor: 'PARTO UNICO ESPONTANEO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O800',
        descriptor: 'PARTO UNICO ESPONTANEO, PRESENTACION CEFALICA DE VERTICE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O801',
        descriptor: 'PARTO UNICO ESPONTANEO, PRESENTACION DE NALGAS O PODALICA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O808',
        descriptor: 'PARTO UNICO ESPONTANEO, OTRAS PRESENTACIONES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O809',
        descriptor: 'PARTO UNICO ESPONTANEO, SIN OTRA ESPECIFICACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O81',
        descriptor: 'PARTO UNICO CON FORCEPS Y VENTOSA EXTRACTORA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O810',
        descriptor: 'PARTO CON FORCEPS BAJO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O811',
        descriptor: 'PARTO CON FORCEPS MEDIO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O812',
        descriptor: 'PARTO CON FORCEPS MEDIO CON ROTACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O813',
        descriptor: 'PARTO CON FORCEPS DE OTROS TIPOS Y LOS NO ESPECIFICADOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O814',
        descriptor: 'PARTO CON VENTOSA EXTRACTORA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O815',
        descriptor: 'PARTO CON COMBINACION DE FORCEPS Y VENTOSA EXTRACTORA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O82',
        descriptor: 'PARTO UNICO POR CESAREA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O820',
        descriptor: 'PARTO POR CESAREA ELECTIVA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O821',
        descriptor: 'PARTO POR CESAREA DE EMERGENCIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O822',
        descriptor: 'PARTO POR CESAREA CON HISTERECTOMIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O828',
        descriptor: 'OTROS PARTOS UNICOS POR CESAREA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O829',
        descriptor: 'PARTO POR CESAREA, SIN OTRA ESPECIFICACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O83',
        descriptor: 'OTROS PARTOS UNICOS ASISTIDOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O830',
        descriptor: 'EXTRACCION DE NALGAS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O831',
        descriptor: 'OTROS PARTOS UNICOS ASISTIDOS, DE NALGAS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O832',
        descriptor: 'OTROS PARTOS UNICOS CON AYUDA DE MANIPULACION OBSTETRICA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O833',
        descriptor: 'PARTO DE FETO VIABLE EN EMBARAZO ABDOMINAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O834',
        descriptor: 'OPERACION DESTRUCTIVA PARA FACILITAR EL PARTO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O838',
        descriptor: 'OTROS PARTOS UNICOS ASISTIDOS ESPECIFICADOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O839',
        descriptor: 'PARTO UNICO ASISTIDO, SIN OTRA ESPECIFICACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O84',
        descriptor: 'PARTO MULTIPLE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O840',
        descriptor: 'PARTO MULTIPLE, TODOS ESPONTANEOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O841',
        descriptor: 'PARTO MULTIPLE, TODOS POR FORCEPS Y VENTOSA EXTRACTORA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O842',
        descriptor: 'PARTO MULTIPLE, TODOS POR CESAREA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O848',
        descriptor: 'OTROS PARTOS MULTIPLES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '54',
        name      : 'ANALGESIA DEL PARTO',
        code      : 'O849',
        descriptor: 'PARTO MULTIPLE, NO ESPECIFICADO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 55,
    name      : 'GRAN QUEMADO',
    pathologic: [
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T202',
        descriptor: 'QUEMADURA DE LA CABEZA Y DEL CUELLO, DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T203',
        descriptor: 'QUEMADURA DE LA CABEZA Y DEL CUELLO, DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T206',
        descriptor: 'CORROSION DE LA CABEZA Y DEL CUELLO, DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T207',
        descriptor: 'CORROSION DE LA CABEZA Y DEL CUELLO, DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T212',
        descriptor: 'QUEMADURA DEL TRONCO, DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T213',
        descriptor: 'QUEMADURA DEL TRONCO, DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T216',
        descriptor: 'CORROSION DEL TRONCO, DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T217',
        descriptor: 'CORROSION DEL TRONCO, DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T222',
        descriptor: 'QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE SEGUNDO GRADO, EXCEPTO MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T223',
        descriptor: 'QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE TERCER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T226',
        descriptor: 'CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, DE SEGUNDO GRADO, EXCEPTO DE LA MUÑECA Y DE MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T227',
        descriptor: 'CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, DE TERCER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T232',
        descriptor: 'QUEMADURA DE LA MUÑECA Y DE LA MANO, DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T233',
        descriptor: 'QUEMADURA DE LA MUÑECA Y DE LA MANO, DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T242',
        descriptor: 'QUEMADURA DE LA CADERA Y MIEMBRO INFERIOR, DE SEGUNDO GRADO, EXCEPTO TOBILLO Y PIE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T243',
        descriptor: 'QUEMADURA DE LA CADERA Y MIEMBRO INFERIOR, DE TERCER GRADO, EXCEPTO TOBILLO Y PIE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T246',
        descriptor: 'CORROSION DE LA CADERA Y MIEMBRO INFERIOR, DE SEGUNDO GRADO, EXCEPTO TOBILLO Y PIE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T247',
        descriptor: 'CORROSION DE LA CADERA Y MIEMBRO INFERIOR, DE TERCER GRADO, EXCEPTO TOBILLO Y PIE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T252',
        descriptor: 'QUEMADURA DEL TOBILLO Y DEL PIE, DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T253',
        descriptor: 'QUEMADURA DEL TOBILLO Y DEL PIE, DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T260',
        descriptor: 'QUEMADURA DEL PARPADO Y AREA PERIOCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T261',
        descriptor: 'QUEMADURA DE LA CORNEA Y SACO CONJUNTIVAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T262',
        descriptor: 'QUEMADURA CON RUPTURA Y DESTRUCCION RESULTANTES DEL GLOBO OCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T263',
        descriptor: 'QUEMADURA DE OTRAS PARTES DEL OJO Y SUS ANEXOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T264',
        descriptor: 'QUEMADURA DEL OJO Y ANEXOS, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T27',
        descriptor: 'QUEMADURA Y CORROSION DE LAS VIAS RESPIRATORIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T270',
        descriptor: 'QUEMADURA DE LA LARINGE Y DE LA TRAQUEA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T271',
        descriptor: 'QUEMADURA QUE AFECTA LA LARINGE Y LA TRAQUEA CON EL PULMON',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T272',
        descriptor: 'QUEMADURA DE OTRAS PARTES DE LAS VIAS RESPIRATORIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T273',
        descriptor: 'QUEMADURA DE LAS VIAS RESPIRATORIAS, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T274',
        descriptor: 'CORROSION DE LA LARINGE Y DE LA TRAQUEA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T275',
        descriptor: 'CORROSION QUE AFECTA LA LARINGE Y LA TRAQUEA CON EL PULMON',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T276',
        descriptor: 'CORROSION DE OTRAS PARTES DE LAS VIAS RESPIRATORIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T277',
        descriptor: 'CORROSION DE LAS VIAS RESPIRATORIAS, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T28',
        descriptor: 'QUEMADURA Y CORROSION DE OTROS ORGANOS INTERNOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T280',
        descriptor: 'QUEMADURA DE LA BOCA Y DE LA FARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T281',
        descriptor: 'QUEMADURA DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T282',
        descriptor: 'QUEMADURA DE OTRAS PARTES DEL TUBO DIGESTIVO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T283',
        descriptor: 'QUEMADURA DE ORGANOS GENITOURINARIOS INTERNOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T284',
        descriptor: 'QUEMADURA DE OTROS ORGANOS INTERNOS Y DE LOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T285',
        descriptor: 'CORROSION DE LA BOCA Y DE LA FARINGE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T286',
        descriptor: 'CORROSION DEL ESOFAGO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T287',
        descriptor: 'CORROSION DE OTRAS PARTES DEL TUBO DIGESTIVO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T288',
        descriptor: 'CORROSION DE ORGANOS GENITOURINARIOS INTERNOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T289',
        descriptor: 'CORROSION DE OTROS ORGANOS INTERNOS Y DE LOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T290',
        descriptor: 'QUEMADURAS DE MULTIPLES REGIONES, GRADO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T291',
        descriptor: 'QUEMADURAS DE MULTIPLES REGIONES, MENCIONADAS COMO DE NO MAS DE PRIMER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T292',
        descriptor: 'QUEMADURAS DE MULTIPLES REGIONES, MENCIONADAS COMO DE NO MAS DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T293',
        descriptor: 'QUEMADURAS MULTIPLES, CON MENCION AL MENOS DE UNA QUEMADURA DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T294',
        descriptor: 'CORROSIONES DE MULTIPLES REGIONES, GRADO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T296',
        descriptor: 'CORROSIONES MULTIPLES, MENCIONADAS COMO DE NO MAS DE SEGUNDO GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T297',
        descriptor: 'CORROSIONES MULTIPLES, CON MENCION AL MENOS DE UNA CORROSION DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T300',
        descriptor: 'QUEMADURA DE REGION DEL CUERPO Y GRADO NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T301',
        descriptor: 'QUEMADURA DE PRIMER GRADO, REGION DEL CUERPO NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T302',
        descriptor: 'QUEMADURA DE SEGUNDO GRADO, REGION DEL CUERPO NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T303',
        descriptor: 'QUEMADURA DE TERCER GRADO, REGION DEL CUERPO NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T306',
        descriptor: 'CORROSION DE SEGUNDO GRADO, REGION DEL CUERPO NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T307',
        descriptor: 'CORROSION DE TERCER GRADO, REGION DEL CUERPO NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T311',
        descriptor: 'QUEMADURAS QUE AFECTAN DEL 10 AL 19% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T312',
        descriptor: 'QUEMADURAS QUE AFECTAN DEL 20 AL 29% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T313',
        descriptor: 'QUEMADURAS QUE AFECTAN DEL 30 AL 39% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T314',
        descriptor: 'QUEMADURAS QUE AFECTAN DEL 40 AL 49% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T315',
        descriptor: 'QUEMADURAS QUE AFECTAN DEL 50 AL 59% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T316',
        descriptor: 'QUEMADURAS QUE AFECTAN DEL 60 AL 69% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T317',
        descriptor: 'QUEMADURAS QUE AFECTAN DEL 70 AL 79% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T318',
        descriptor: 'QUEMADURAS QUE AFECTAN DEL 80 AL 89% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T319',
        descriptor: 'QUEMADURAS QUE AFECTAN EL 90% O MAS DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T321',
        descriptor: 'CORROSIONES QUE AFECTAN DEL 10 AL 19% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T322',
        descriptor: 'CORROSIONES QUE AFECTAN DEL 20 AL 29% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T323',
        descriptor: 'CORROSIONES QUE AFECTAN DEL 30 AL 39% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T324',
        descriptor: 'CORROSIONES QUE AFECTAN DEL 40 AL 49% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T325',
        descriptor: 'CORROSIONES QUE AFECTAN DEL 50 AL 59% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T326',
        descriptor: 'CORROSIONES QUE AFECTAN DEL 60 AL 69% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T327',
        descriptor: 'CORROSIONES QUE AFECTAN DEL 70 AL 79% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T328',
        descriptor: 'CORROSIONES QUE AFECTAN DEL 80 AL 89% DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T329',
        descriptor: 'CORROSIONES QUE AFECTAN EL 90% O MAS DE LA SUPERFICIE DEL CUERPO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T754',
        descriptor: 'EFECTOS DE LA CORRIENTE ELECTRICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T237',
        descriptor: 'CORROSION DE LA MUÑECA Y DE LA MANO, DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T257',
        descriptor: 'CORROSION DEL TOBILLO Y DEL PIE, DE TERCER GRADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T265',
        descriptor: 'CORROSION DEL PARPADO Y AREA PERIOCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T266',
        descriptor: 'CORROSION DE LA CORNEA Y SACO CONJUNTIVAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T267',
        descriptor: 'CORROSION CON RUPTURA Y DESTRUCCION RESULTANTES DEL GLOBO OCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T268',
        descriptor: 'CORROSION DE OTRAS PARTES DEL OJO Y SUS ANEXOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T269',
        descriptor: 'CORROSION DEL OJO Y SUS ANEXOS, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '55',
        name      : 'GRAN QUEMADO',
        code      : 'T304',
        descriptor: 'CORROSION DE REGION DEL CUERPO Y GRADO NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 56,
    name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
    pathologic: [
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H90',
        descriptor: 'HIPOCAUSIA CONDUCTIVA Y NEUROSENSORIAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H900',
        descriptor: 'HIPOACUSIA CONDUCTIVA BILATERAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H902',
        descriptor: 'HIPOACUSIA CONDUCTIVA, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H903',
        descriptor: 'HIPOACUSIA NEUROSENSORIAL BILATERAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H905',
        descriptor: 'HIPOACUSIA NEUROSENSORIAL, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H906',
        descriptor: 'HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, BILATERAL',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H908',
        descriptor: 'HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H910',
        descriptor: 'HIPOACUSIA OTOTOXICA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '56',
        name      : 'HIPOACUSIA BILATERAL EN PERSONAS DE 65 AÑOS Y MAS QUE REQUIEREN USO DE AUDIFONO',
        code      : 'H911',
        descriptor: 'PRESBIACUSIA',
        sex       : 'No aplica',
        min       : '65 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 57,
    name      : 'RETINOPATIA DEL PREMATURO',
    pathologic: [
      {
        id        : '57',
        name      : 'RETINOPATIA DEL PREMATURO',
        code      : 'H351',
        descriptor: 'RETINOPATIA DE LA PREMATURIDAD',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '57',
        name      : 'RETINOPATIA DEL PREMATURO',
        code      : 'Z135',
        descriptor: 'EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS DEL OJO Y DEL OIDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 58,
    name      : 'DISPLASIA BRONCOPULMONAR DEL PREMATURO',
    pathologic: [
      {
        id        : '58',
        name      : 'DISPLASIA BRONCOPULMONAR DEL PREMATURO',
        code      : 'P271',
        descriptor: 'DISPLASIA BRONCOPULMONAR ORIGINADA EN EL PERIODO PERINATAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '58',
        name      : 'DISPLASIA BRONCOPULMONAR DEL PREMATURO',
        code      : 'Z098',
        descriptor: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A OTRO TRATAMIENTO POR OTRAS AFECCIONES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 59,
    name      : 'HIPOACUSIA NEUROSENSORIAL BILATERAL DEL PREMATURO',
    pathologic: [
      {
        id        : '59',
        name      : 'HIPOACUSIA NEUROSENSORIAL BILATERAL DEL PREMATURO',
        code      : 'H903',
        descriptor: 'HIPOACUSIA NEUROSENSORIAL BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '59',
        name      : 'HIPOACUSIA NEUROSENSORIAL BILATERAL DEL PREMATURO',
        code      : 'H905',
        descriptor: 'HIPOACUSIA NEUROSENSORIAL, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '59',
        name      : 'HIPOACUSIA NEUROSENSORIAL BILATERAL DEL PREMATURO',
        code      : 'Z135',
        descriptor: 'EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS DEL OJO Y DEL OIDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '59',
        name      : 'HIPOACUSIA NEUROSENSORIAL BILATERAL DEL PREMATURO',
        code      : 'Z461',
        descriptor: 'PRUEBA Y AJUSTE DE AUDIFONOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 60,
    name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G40',
        descriptor: 'EPILEPSIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G400',
        descriptor:
            'EPILEPSIA Y SINDR.EPIL.IDIOPAT.RELAC.C/ LOCALIZAC.(FOCALES) (PARC.) Y C/ ATAQUES DE INICIO LOCALIZ.',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G401',
        descriptor: 'EPILEPSIA Y SINDR. EPIL.SINTOMAT.RELAC.C/LOCALIZAC.(FOCALES) (PARC.) Y C/ATAQUES PARC.SIMPLES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G402',
        descriptor: 'EPILEPSIA Y SINDR. EPIL.SINTOMAT.RELAC.C/LOCALIZAC.(FOCALES) (PARC.) Y C/ATAQUES PARC. COMPLEJOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G403',
        descriptor: 'EPILEPSIA Y SINDROMES EPILEPTICOS IDIOPATICOS GENERALIZADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G404',
        descriptor: 'OTRAS EPILEPSIAS Y SINDROMES EPILEPTICOS GENERALIZADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G405',
        descriptor: 'SINDROMES EPILEPTICOS ESPECIALES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G406',
        descriptor: 'ATAQUES DE GRAN MAL, NO ESPECIFICADOS (CON O SIN PEQUEÑO MAL)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G407',
        descriptor: 'PEQUEÑO MAL, NO ESPECIFICADO (SIN ATAQUE DE GRAN MAL)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G408',
        descriptor: 'OTRAS EPILEPSIAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '60',
        name      : 'EPILEPSIA NO REFRACTARIA 15 AÑOS Y MAS',
        code      : 'G409',
        descriptor: 'EPILEPSIA, TIPO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 61,
    name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '61',
        name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'J45',
        descriptor: 'ASMA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '61',
        name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'J450',
        descriptor: 'ASMA PREDOMINANTEMENTE ALERGICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '61',
        name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'J451',
        descriptor: 'ASMA NO ALERGICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '61',
        name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'J458',
        descriptor: 'ASMA MIXTA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '61',
        name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'J459',
        descriptor: 'ASMA, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '61',
        name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'J46',
        descriptor: 'ESTADO ASMATICO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '61',
        name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'J64',
        descriptor: 'NEUMOCONIOSIS, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '61',
        name      : 'ASMA BRONQUIAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'J678',
        descriptor: 'NEUMONITIS DEBIDAS A HIPERSENSIBILIDAD A OTROS POLVOS ORGANICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 62,
    name      : 'ENFERMEDAD DE PARKINSON',
    pathologic: [
      {
        id        : '62',
        name      : 'ENFERMEDAD DE PARKINSON',
        code      : 'G20',
        descriptor: 'ENFERMEDAD DE PARKINSON',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 63,
    name      : 'ARTRITIS IDIOPATICA JUVENIL',
    pathologic: [
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M08',
        descriptor: 'ARTRITIS JUVENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M080',
        descriptor: 'ARTRITIS REUMATOIDE JUVENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M081',
        descriptor: 'ESPONDILITIS ANQUILOSANTE JUVENIL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M082',
        descriptor: 'ARTRITIS JUVENIL DE COMIENZO GENERALIZADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M083',
        descriptor: 'POLIARTRITIS JUVENIL (SERONEGATIVA)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M084',
        descriptor: 'ARTRITIS JUVENIL PAUCIARTICULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M088',
        descriptor: 'OTRAS ARTRITIS JUVENILES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M089',
        descriptor: 'ARTRITIS JUVENIL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M09',
        descriptor: 'ARTRITIS JUVENIL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M090',
        descriptor: 'ARTRITIS JUVENIL EN LA PSORIASIS (L40.5+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M091',
        descriptor: 'ARTRITIS JUVENIL EN ENFERMEDAD DE CROHN (ENTERITIS REGIONAL) (K50.-+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M092',
        descriptor: 'ARTRITIS JUVENIL EN LA COLITIS ULCERATIVA (K51.-+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
      {
        id        : '63',
        name      : 'ARTRITIS IDIOPATICA JUVENIL',
        code      : 'M098',
        descriptor: 'ARTRITIS JUVENIL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '16 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 64,
    name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
    pathologic: [
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'I120',
        descriptor: 'ENFERMEDAD RENAL HIPERTENSIVA CON INSUFICIENCIA RENAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'I131',
        descriptor: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA RENAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'M321',
        descriptor: 'LUPUS ERITEMATOSO SISTEMICO CON COMPROMISO DE ORGANOS O SISTEMAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N140',
        descriptor: 'NEFROPATIA INDUCIDA POR ANALGESICOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N141',
        descriptor: 'NEFROPATIA INDUCIDA POR OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N142',
        descriptor: 'NEFROPATIA INDUCIDA POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N143',
        descriptor: 'NEFROPATIA INDUCIDA POR METALES PESADOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N144',
        descriptor: 'NEFROPATIA TOXICA, NO CLASIFICADA EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N18',
        descriptor: 'ENFERMEDAD RENAL CRONICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N181',
        descriptor: 'ENFERMEDAD RENAL CRONICA, ESTADIO 1',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N182',
        descriptor: 'ENFERMEDAD RENAL CRONICA, ESTADIO 2',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N183',
        descriptor: 'ENFERMEDAD RENAL CRONICA, ESTADIO 3',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N184',
        descriptor: 'ENFERMEDAD RENAL CRONICA, ESTADIO 4',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N185',
        descriptor: 'ENFERMEDAD RENAL CRONICA, ESTADIO 5',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N189',
        descriptor: 'ENFERMEDAD RENAL CRONICA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N19',
        descriptor: 'INSUFICIENCIA RENAL NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N26',
        descriptor: 'RIÑON CONTRAIDO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N271',
        descriptor: 'RIÑON PEQUEÑO, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N279',
        descriptor: 'RIÑON PEQUEÑO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N289',
        descriptor: 'TRASTORNO DEL RIÑON Y DEL URETER, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'Q601',
        descriptor: 'AGENESIA RENAL, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'Q602',
        descriptor: 'AGENESIA RENAL, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'Q604',
        descriptor: 'HIPOPLASIA RENAL, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'Q605',
        descriptor: 'HIPOPLASIA RENAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'Q606',
        descriptor: 'SINDROME DE POTTER',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N069',
        descriptor: 'PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N060',
        descriptor: 'PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA, ANOMALIA GLOMERULAR MINIMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N061',
        descriptor:
            'PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N068',
        descriptor: 'PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA, OTRAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N063',
        descriptor:
            'PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N391',
        descriptor: 'PROTEINURIA PERSISTENTE, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N392',
        descriptor: 'PROTEINURIA ORTOSTATICA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N040',
        descriptor: 'SINDROME NEFROTICO, ANOMALIA GLOMERULAR MINIMA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N041',
        descriptor: 'SINDROME NEFROTICO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N042',
        descriptor: 'SINDROME NEFROTICO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N043',
        descriptor: 'SINDROME NEFROTICO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N044',
        descriptor: 'SINDROME NEFROTICO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N045',
        descriptor: 'SINDROME NEFROTICO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N046',
        descriptor: 'SINDROME NEFROTICO, ENFERMEDAD POR DEPOSITOS DENSOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N047',
        descriptor: 'SINDROME NEFROTICO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N048',
        descriptor: 'SINDROME NEFROTICO, OTRAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'N049',
        descriptor: 'SINDROME NEFROTICO, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'E102',
        descriptor: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'E112',
        descriptor: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'E132',
        descriptor: 'OTRAS DIABETES MELLITUS ESPECIFICADAS, CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '64',
        name      : 'PREVENCION SECUNDARIA ENFERMEDAD RENAL CRONICA TERMINAL',
        code      : 'E142',
        descriptor: 'DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES RENALES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 65,
    name      : 'DISPLASIA LUXANTE DE CADERAS',
    pathologic: [
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q65',
        descriptor: 'DEFORMIDADES CONGENITAS DE LA CADERA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q650',
        descriptor: 'LUXACION CONGENITA DE LA CADERA, UNILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q651',
        descriptor: 'LUXACION CONGENITA DE LA CADERA, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q652',
        descriptor: 'LUXACION CONGENITA DE LA CADERA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q653',
        descriptor: 'SUBLUXACION CONGENITA DE LA CADERA, UNILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q654',
        descriptor: 'SUBLUXACION CONGENITA DE LA CADERA, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q655',
        descriptor: 'SUBLUXACION CONGENITA DE LA CADERA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q656',
        descriptor: 'CADERA INESTABLE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q658',
        descriptor: 'OTRAS DEFORMIDADES CONGENITAS DE LA CADERA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Q659',
        descriptor: 'DEFORMIDAD CONGENITA DE LA CADERA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Z137',
        descriptor: 'EXAMEN DE PESQUISA ESPECIAL PARA MALFORMACIONES CONGENITAS, DEFORMIDADES Y ANOMALIAS CROMOSOMICAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '65',
        name      : 'DISPLASIA LUXANTE DE CADERAS',
        code      : 'Z478',
        descriptor: 'OTROS CUIDADOS ESPECIFICADOS POSTERIORES A LA ORTOPEDIA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 66,
    name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
    pathologic: [
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K000',
        descriptor: 'ANODONCIA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K02',
        descriptor: 'CARIES DENTAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K030    ',
        descriptor: 'ATRICION EXCESIVA DE LOS DIENTES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K031    ',
        descriptor: 'ABRASION DE LOS DIENTES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K032    ',
        descriptor: 'EROSION DE LOS DIENTES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K020',
        descriptor: 'CARIES LIMITADA AL ESMALTE',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K021',
        descriptor: 'CARIES DE LA DENTINA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K022',
        descriptor: 'CARIES DEL CEMENTO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K023',
        descriptor: 'CARIES DENTARIA DETENIDA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K028',
        descriptor: 'OTRAS CARIES DENTALES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K029',
        descriptor: 'CARIES DENTAL, NO ESPECIFICADA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K036',
        descriptor: 'DEPOSITOS (ACRECIONES) EN LOS DIENTES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K040',
        descriptor: 'PULPITIS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K041',
        descriptor: 'NECROSIS DE LA PULPA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K042',
        descriptor: 'DEGENERACION DE LA PULPA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K043',
        descriptor: 'FORMACION ANORMAL DE TEJIDO DURO EN LA PULPA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K044',
        descriptor: 'PERIODONTITIS APICAL AGUDA ORIGINADA EN LA PULPA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K045',
        descriptor: 'PERIODONTITIS APICAL CRONICA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K046',
        descriptor: 'ABSCESO PERIAPICAL CON FISTULA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K047',
        descriptor: 'ABSCESO PERIAPICAL SIN FISTULA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K050',
        descriptor: 'GINGIVITIS AGUDA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K051',
        descriptor: 'GINGIVITIS CRONICA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K052',
        descriptor: 'PERIODONTITIS AGUDA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K053',
        descriptor: 'PERIODONTITIS CRONICA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K055',
        descriptor: 'OTRAS ENFERMEDADES PERIODONTALES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K056',
        descriptor: 'ENFERMEDAD DEL PERIODONTO, NO ESPECIFICADA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K060',
        descriptor: 'RETRACCION GINGIVAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K061',
        descriptor: 'HIPERPLASIA GINGIVAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K068',
        descriptor: 'OTROS TRASTORNOS ESPECIFICADOS DE LA ENCIA Y DE LA ZONA EDENTULA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K069',
        descriptor: 'TRASTORNO NO ESPECIFICADO DE LA ENCIA Y DE LA ZONA EDENTULA',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K081',
        descriptor: 'PERDIDA DE DIENTES DEBIDA A ACCIDENTE, EXTRACCION O ENFERMEDAD PERIODONTAL LOCAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K089',
        descriptor: 'TRASTORNO DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTEN, NO ESPECIFICADO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K039',
        descriptor: 'ENFERMEDAD NO ESPECIFICADA DE LOS TEJIDOS DENTALES DUROS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K038',
        descriptor: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LOS TEJIDOS DUROS DE LOS DIENTES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K062',
        descriptor: 'LESIONES DE LA ENCIA Y DE LA ZONA EDENTULA ASOCIADAS CON TRAUMATISMO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'K049',
        descriptor: 'OTRAS ENFERMEDADES Y LAS NO ESPECIFICADAS DE LA PULPA Y DEL TEJIDO PERIAPICAL',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'Z012',
        descriptor: 'EXAMEN ODONTOLOGICO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '66',
        name      : 'SALUD ORAL INTEGRAL DE LA EMBARAZADA',
        code      : 'Z03',
        descriptor: 'OTRAS ENFERMEDADES DE LOS TEJIDOS DUROS DE LOS DIENTES',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 67,
    name      : 'ESCLEROSIS MULTIPLE REMITENTE RECURRENTE',
    pathologic: [
      {
        id        : '67',
        name      : 'ESCLEROSIS MULTIPLE REMITENTE RECURRENTE',
        code      : 'G35',
        descriptor: 'ESCLEROSIS MULTIPLE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 68,
    name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
    pathologic: [
      {
        id        : '68',
        name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
        code      : 'B16',
        descriptor: 'HEPATITIS AGUDA TIPO B',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '68',
        name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
        code      : 'B160',
        descriptor: 'HEPATITIS AGUDA TIPO B, CON AGENTE DELTA (COINFECCION), CON COMA HEPATICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '68',
        name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
        code      : 'B161',
        descriptor: 'HEPATITIS AGUDA TIPO B, CON AGENTE DELTA (COINFECCION), SIN COMA HEPATICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '68',
        name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
        code      : 'B162',
        descriptor: 'HEPATITIS AGUDA TIPO B, SIN AGENTE DELTA, CON COMA HEPATICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '68',
        name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
        code      : 'B169',
        descriptor: 'HEPATITIS AGUDA TIPO B, SIN AGENTE DELTA Y SIN COMA HEPATICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '68',
        name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
        code      : 'B180',
        descriptor: 'HEPATITIS VIRAL TIPO B CRONICA, CON AGENTE DELTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '68',
        name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
        code      : 'B181',
        descriptor: 'HEPATITIS VIRAL TIPO B CRONICA, SIN AGENTE DELTA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '68',
        name      : 'HEPATITIS CRONICA POR VIRUS HEPATITIS B',
        code      : 'Z225',
        descriptor: 'PORTADOR DE AGENTES DE HEPATITIS VIRAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 69,
    name      : 'HEPATITIS C',
    pathologic: [
      {
        id        : '69',
        name      : 'HEPATITIS C',
        code      : 'B171',
        descriptor: 'HEPATITIS AGUDA TIPO C',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '69',
        name      : 'HEPATITIS C',
        code      : 'B182',
        descriptor: 'HEPATITIS VIRAL TIPO C CRONICA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '69',
        name      : 'HEPATITIS C',
        code      : 'Z225',
        descriptor: 'PORTADOR DE AGENTES DE HEPATITIS VIRAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 70,
    name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C18',
        descriptor: 'TUMOR MALIGNO DEL COLON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C180',
        descriptor: 'TUMOR MALIGNO DEL CIEGO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C181',
        descriptor: 'TUMOR MALIGNO DEL APENDICE CECAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C182',
        descriptor: 'TUMOR MALIGNO DEL COLON ASCENDENTE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C183',
        descriptor: 'TUMOR MALIGNO DEL ANGULO HEPATICO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C184',
        descriptor: 'TUMOR MALIGNO DEL COLON TRANSVERSO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C185',
        descriptor: 'TUMOR MALIGNO DEL ANGULO ESPLENICO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C186',
        descriptor: 'TUMOR MALIGNO DEL COLON DESCENDENTE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C187',
        descriptor: 'TUMOR MALIGNO DEL COLON SIGMOIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C188',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL COLON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C189',
        descriptor: 'TUMOR MALIGNO DEL COLON, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C19',
        descriptor: 'TUMOR MALIGNO DE LA UNION RECTOSIGMOIDEA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C20',
        descriptor: 'TUMOR MALIGNO DEL RECTO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C21',
        descriptor: 'TUMOR MALIGNO DEL ANO Y DEL CONDUCTO ANAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C210',
        descriptor: 'TUMOR MALIGNO DEL ANO, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C211',
        descriptor: 'TUMOR MALIGNO DEL CONDUCTO ANAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C212',
        descriptor: 'TUMOR MALIGNO DE LA ZONA CLOACOGENICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C218',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DEL ANO, DEL CONDUCTO ANAL Y DEL RECTO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C785',
        descriptor: 'TUMOR MALIGNO SECUNDARIO DEL INTESTINO GRUESO Y DEL RECTO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D010',
        descriptor: 'CARCINOMA IN SITU DEL COLON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D011',
        descriptor: 'CARCINOMA IN SITU DE LA UNION RECTOSIGMOIDEA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D012',
        descriptor: 'CARCINOMA IN SITU DEL RECTO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D013',
        descriptor: 'CARCINOMA IN SITU DEL ANO Y DEL CONDUCTO ANAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D374',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL COLON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D375',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RECTO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '70',
        name      : 'CANCER  COLORECTAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D377',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS DIGESTIVOS ESPECIFICADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 71,
    name      : 'CANCER DE OVARIO EPITELIAL',
    pathologic: [
      {
        id        : '71',
        name      : 'CANCER DE OVARIO EPITELIAL',
        code      : 'C56',
        descriptor: 'TUMOR MALIGNO DEL OVARIO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '71',
        name      : 'CANCER DE OVARIO EPITELIAL',
        code      : 'C570',
        descriptor: 'TUMOR MALIGNO DE LA TROMPA DE FALOPIO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '71',
        name      : 'CANCER DE OVARIO EPITELIAL',
        code      : 'C574',
        descriptor: 'TUMOR MALIGNO DE LOS ANEXOS UTERINOS, SIN OTRA ESPECIFICACION',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '71',
        name      : 'CANCER DE OVARIO EPITELIAL',
        code      : 'C578',
        descriptor: 'LESION (NEOPLASICA) DE SITIOS CONTIGUOS DE LOS ORGANOS GENITALES FEMENINOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '71',
        name      : 'CANCER DE OVARIO EPITELIAL',
        code      : 'C796',
        descriptor: 'TUMOR MALIGNO SECUNDARIO DEL OVARIO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '71',
        name      : 'CANCER DE OVARIO EPITELIAL',
        code      : 'D073',
        descriptor: 'CARCINOMA IN SITU DE OTROS SITIOS DE ORGANOS GENITALES FEMENINOS Y DE LOS NO ESPECIFICADOS',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '71',
        name      : 'CANCER DE OVARIO EPITELIAL',
        code      : 'D391',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL OVARIO',
        sex       : 'Femenino',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 72,
    name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C67',
        descriptor: 'TUMOR MALIGNO DE LA VEJIGA URINARIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C670',
        descriptor: 'TUMOR MALIGNO DEL TRIGONO VESICAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C671',
        descriptor: 'TUMOR MALIGNO DE LA CUPULA VESICAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C672',
        descriptor: 'TUMOR MALIGNO DE LA PARED LATERAL DE LA VEJIGA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C673',
        descriptor: 'TUMOR MALIGNO DE LA PARED ANTERIOR DE LA VEJIGA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C674',
        descriptor: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA VEJIGA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C675',
        descriptor: 'TUMOR MALIGNO DEL CUELLO DE LA VEJIGA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C676',
        descriptor: 'TUMOR MALIGNO DEL ORIFICIO URETERAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C677',
        descriptor: 'TUMOR MALIGNO DEL URACO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C678',
        descriptor: 'LESION (NEOPLASICA) DE SITIOS CONTIGUOS DE LA VEJIGA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C679',
        descriptor: 'TUMOR MALIGNO DE LA VEJIGA URINARIA, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C689',
        descriptor: 'TUMOR MALIGNO DE ORGANO URINARIO NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C791',
        descriptor: 'TUMOR MALIGNO SECUNDARIO DE LA VEJIGA, Y DE OTROS ORG.Y DE LOS NO ESPECIFIC. DE VIAS URINARIAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D090',
        descriptor: 'CARCINOMA IN SITU DE LA VEJIGA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '72',
        name      : 'CANCER VESICAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D091',
        descriptor: 'CARCINOMA IN SITU DE OTROS ORGANOS URINARIOS Y DE LOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 73,
    name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C40',
        descriptor: 'TUMOR MALIGNO DE LOS HUESOS Y DE LOS CARTILAGOS ARTICULARES DE LOS MIEMBROS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C400',
        descriptor: 'TUMOR MALIGNO DEL OMOPLATO Y DE LOS HUESOS LARGOS DEL MIEMBRO SUPERIOR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C401',
        descriptor: 'TUMOR MALIGNO DE LOS HUESOS CORTOS DEL MIEMBRO SUPERIOR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C402',
        descriptor: 'TUMOR MALIGNO DE LOS HUESOS LARGOS DEL MIEMBRO INFERIOR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C403',
        descriptor: 'TUMOR MALIGNO DE LOS HUESOS CORTOS DEL MIEMBRO INFERIOR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C408',
        descriptor: 'LESION (NEOPLASICA) DE SITIOS CONTIGUOS DE HUESOS Y DE CARTILAGOS ARTICULARES DE LOS MIEMBROS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C409',
        descriptor: 'TUMOR MALIGNO DE HUESOS Y DE CARTILAGOS ARTICULARES DE LOS MIEMBROS, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C41',
        descriptor:
            'TUMOR MALIGNO DE LOS HUESOS Y DE LOS CARTILAGOS ARTICULARES, DE OTROS SITIOS Y DE SITIOS NO ESPECIFICADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C410',
        descriptor: 'TUMOR MALIGNO DE LOS HUESOS DEL CRANEO Y DE LA CARA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C411',
        descriptor: 'TUMOR MALIGNO DEL HUESO DEL MAXILAR INFERIOR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C412',
        descriptor: 'TUMOR MALIGNO DE LA COLUMNA VERTEBRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C413',
        descriptor: 'TUMOR MALIGNO DE LA COSTILLA, ESTERNON Y CLAVICULA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C414',
        descriptor: 'TUMOR MALIGNO DE LOS HUESOS DE LA PELVIS, SACRO Y COCCIX',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C418',
        descriptor: 'LESION (NEOPLASICA) DE SITIOS CONTIGUOS DEL HUESO Y DEL CARTILAGO ARTICULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C419',
        descriptor: 'TUMOR MALIGNO DEL HUESO Y DEL CARTILAGO ARTICULAR, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C795',
        descriptor: 'TUMOR MALIGNO SECUNDARIO DE LOS HUESOS Y DE LA MEDULA OSEA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '73',
        name      : 'OSTEOSARCOMA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D480',
        descriptor: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL HUESO Y CARTILAGO ARTICULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 74,
    name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I06',
        descriptor: 'ENFERMEDADES REUMATICAS DE LA VALVULA AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I060',
        descriptor: 'ESTENOSIS AORTICA REUMATICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I061',
        descriptor: 'INSUFICIENCIA AORTICA REUMATICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I062',
        descriptor: 'ESTENOSIS AORTICA REUMATICA CON INSUFICIENCIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I068',
        descriptor: 'OTRAS ENFERMEDADES REUMATICAS ESPECIFICADAS DE LA VALVULA AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I069',
        descriptor: 'ENFERMEDAD VALVULAR AORTICA REUMATICA, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I080',
        descriptor: 'TRASTORNOS DE LAS VALVULAS MITRAL Y AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I082',
        descriptor: 'TRASTORNOS DE LAS VALVULAS AORTICA Y TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I083',
        descriptor: 'TRASTORNOS COMBINADOS DE LAS VALVULAS MITRAL, TRICUSPIDE Y AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I088',
        descriptor: 'OTRAS ENFERMEDADES DE MULTIPLES VALVULAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I089',
        descriptor: 'ENFERMEDAD DE MULTIPLES VALVULAS NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I091',
        descriptor: 'ENFERMEDADES REUMATICAS DEL ENDOCARDIO, VALVULA NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I35',
        descriptor: 'TRASTORNOS NO REUMATICOS DE LA VALVULA AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I350',
        descriptor: 'ESTENOSIS (DE LA VALVULA) AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I351',
        descriptor: 'INSUFICIENCIA (DE LA VALVULA) AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I352',
        descriptor: 'ESTENOSIS (DE LA VALVULA) AORTICA CON INSUFICIENCIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I358',
        descriptor: 'OTROS TRASTORNOS DE LA VALVULA AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I359',
        descriptor: 'TRASTORNO DE LA VALVULA AORTICA, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '74',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LA VALVULA AORTICA EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I391',
        descriptor: 'TRASTORNOS DE LA VALVULA AORTICA EN ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 75,
    name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F31',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F310',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO HIPOMANIACO PRESENTE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F311',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO MANIACO PRESENTE SIN SINTOMAS PSICOTICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F312',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO MANIACO PRESENTE CON SINTOMAS PSICOTICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F313',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO PRESENTE LEVE O MODERADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F314',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO GRAVE PRESENTE SIN SINTOMAS PSICOTICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F315',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO GRAVE PRESENTE CON SINTOMAS PSICOTICOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F316',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO MIXTO PRESENTE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F317',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, ACTUALMENTE EN REMISION',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F318',
        descriptor: 'OTROS TRASTORNOS AFECTIVOS BIPOLARES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '75',
        name      : 'TRASTORNO  BIPOLAR  EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'F319',
        descriptor: 'TRASTORNO AFECTIVO BIPOLAR, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 76,
    name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E00',
        descriptor: 'SINDROME CONGENITO DE DEFICIENCIA DE YODO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E000',
        descriptor: 'SINDROME CONGENITO DE DEFICIENCIA DE YODO, TIPO NEUROLOGICO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E001',
        descriptor: 'SINDROME DE DEFICIENCIA CONGENITA DE YODO, TIPO MIXEDEMATOSO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E002',
        descriptor: 'SINDROME CONGENITO DE DEFICIENCIA DE YODO, TIPO MIXTO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E009',
        descriptor: 'SINDROME CONGENITO DE DEFICIENCIA DE YODO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E01',
        descriptor: 'TRASTORNOS TIROIDEOS VINCULADOS A DEFICIENCIA DE YODO Y AFECCIONES RELACIONADAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E010',
        descriptor: 'BOCIO DIFUSO (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E011',
        descriptor: 'BOCIO MULTINODULAR (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E012',
        descriptor: 'BOCIO (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E018',
        descriptor: 'OTROS TRASTORNOS DE LA TIROIDES RELACIONADOS CON DEFICIENCIA DE YODO Y AFECCIONES SIMILARES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E02',
        descriptor: 'HIPOTIROIDISMO SUBCLINICO POR DEFICIENCIA DE YODO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E03',
        descriptor: 'OTROS HIPOTIROIDISMOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E030',
        descriptor: 'HIPOTIROIDISMO CONGENITO CON BOCIO DIFUSO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E031',
        descriptor: 'HIPOTIROIDISMO CONGENITO SIN BOCIO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E032',
        descriptor: 'HIPOTIROIDISMO DEBIDO A MEDICAMENTOS Y A OTRAS SUSTANCIAS EXOGENAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E033',
        descriptor: 'HIPOTIROIDISMO POSTINFECCIOSO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E034',
        descriptor: 'ATROFIA DE TIROIDES (ADQUIRIDA)',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E035',
        descriptor: 'COMA MIXEDEMATOSO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E038',
        descriptor: 'OTROS HIPOTIROIDISMOS ESPECIFICADOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E039',
        descriptor: 'HIPOTIROIDISMO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E063',
        descriptor: 'TIROIDITIS AUTOINMUNE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '76',
        name      : 'HIPOTIROIDISMO EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'E890',
        descriptor: 'HIPOTIROIDISMO CONSECUTIVO A PROCEDIMIENTOS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 77,
    name      : 'TRATAMIENTO DE HIPOACUSIA MODERADA PERSONAS MENORES DE 4 AÑOS',
    pathologic: [
      {
        id        : '77',
        name      : 'TRATAMIENTO DE HIPOACUSIA MODERADA PERSONAS MENORES DE 4 AÑOS',
        code      : 'H903',
        descriptor: 'HIPOACUSIA NEUROSENSORIAL BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '3 años, 11 meses, 29 dias',
      },
      {
        id        : '77',
        name      : 'TRATAMIENTO DE HIPOACUSIA MODERADA PERSONAS MENORES DE 4 AÑOS',
        code      : 'H905',
        descriptor: 'HIPOACUSIA NEUROSENSORIAL, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '3 años, 11 meses, 29 dias',
      },
      {
        id        : '77',
        name      : 'TRATAMIENTO DE HIPOACUSIA MODERADA PERSONAS MENORES DE 4 AÑOS',
        code      : 'H906',
        descriptor: 'HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, BILATERAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '3 años, 11 meses, 29 dias',
      },
      {
        id        : '77',
        name      : 'TRATAMIENTO DE HIPOACUSIA MODERADA PERSONAS MENORES DE 4 AÑOS',
        code      : 'H908',
        descriptor: 'HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '3 años, 11 meses, 29 dias',
      },
      {
        id        : '77',
        name      : 'TRATAMIENTO DE HIPOACUSIA MODERADA PERSONAS MENORES DE 4 AÑOS',
        code      : 'H90',
        descriptor: 'HIPOACUSIA CONDUCTIVA Y NEUROSENSORIAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : '3 años, 11 meses, 29 dias',
      },
    ],
  },
  {
    id        : 78,
    name      : 'LUPUS ERITEMATOSO SISTEMICO',
    pathologic: [
      {
        id        : '78',
        name      : 'LUPUS ERITEMATOSO SISTEMICO',
        code      : 'M32',
        descriptor: 'LUPUS ERITEMATOSO SISTEMICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '78',
        name      : 'LUPUS ERITEMATOSO SISTEMICO',
        code      : 'M320',
        descriptor: 'LUPUS ERITEMATOSO SISTEMICO, INDUCIDO POR DROGAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '78',
        name      : 'LUPUS ERITEMATOSO SISTEMICO',
        code      : 'M321',
        descriptor: 'LUPUS ERITEMATOSO SISTEMICO CON COMPROMISO DE ORGANOS O SISTEMAS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '78',
        name      : 'LUPUS ERITEMATOSO SISTEMICO',
        code      : 'M328',
        descriptor: 'OTRAS FORMAS DE LUPUS ERITEMATOSO SISTEMICO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '78',
        name      : 'LUPUS ERITEMATOSO SISTEMICO',
        code      : 'M329',
        descriptor: 'LUPUS ERITEMATOSO SISTEMICO, SIN OTRA ESPECIFICACION',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 79,
    name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I05',
        descriptor: 'ENFERMEDADES REUMATICAS DE LA VALVULA MITRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I050',
        descriptor: 'ESTENOSIS MITRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I051',
        descriptor: 'INSUFICIENCIA MITRAL REUMATICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I052',
        descriptor: 'ESTENOSIS MITRAL CON INSUFICIENCIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I058',
        descriptor: 'OTRAS ENFERMEDADES DE LA VALVULA MITRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I059',
        descriptor: 'ENFERMEDAD VALVULAR MITRAL, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I07',
        descriptor: 'ENFERMEDADES REUMATICAS DE LA VALVULA TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I070',
        descriptor: 'ESTENOSIS TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I071',
        descriptor: 'INSUFICIENCIA TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I072',
        descriptor: 'ESTENOSIS E INSUFICIENCIA TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I078',
        descriptor: 'OTRAS ENFERMEDADES DE LA VALVULA TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I079',
        descriptor: 'ENFERMEDAD DE LA VALVULA TRICUSPIDE, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I08',
        descriptor: 'ENFERMEDADES VALVULARES MULTIPLES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I080',
        descriptor: 'TRASTORNOS DE LAS VALVULAS MITRAL Y AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I081',
        descriptor: 'TRASTORNOS DE LAS VALVULAS MITRAL Y TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I082',
        descriptor: 'TRASTORNOS DE LAS VALVULAS AORTICA Y TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I083',
        descriptor: 'TRASTORNOS COMBINADOS DE LAS VALVULAS MITRAL, TRICUSPIDE Y AORTICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I088',
        descriptor: 'OTRAS ENFERMEDADES DE MULTIPLES VALVULAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I089',
        descriptor: 'ENFERMEDAD DE MULTIPLES VALVULAS NO ESPECIFICADAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I34',
        descriptor: 'TRASTORNOS NO REUMATICOS DE LA VALVULA MITRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I340',
        descriptor: 'INSUFICIENCIA (DE LA VALVULA) MITRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I341',
        descriptor: 'PROLAPSO (DE LA VALVULA) MITRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I342',
        descriptor: 'ESTENOSIS (DE LA VALVULA) MITRAL, NO REUMATICA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I348',
        descriptor: 'OTROS TRASTORNOS NO REUMATICOS DE LA VALVULA MITRAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I349',
        descriptor: 'TRASTORNO MITRAL NO REUMATICO, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I36',
        descriptor: 'TRASTORNOS NO REUMATICOS DE LA VALVULA TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I360',
        descriptor: 'ESTENOSIS NO REUMATICA (DE LA VALVULA) TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I361',
        descriptor: 'INSUFICIENCIA NO REUMATICA (DE LA VALVULA) TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I362',
        descriptor: 'ESTENOSIS CON INSUFICIENCIA NO REUMATICA (DE LA VALVULA) TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I368',
        descriptor: 'OTROS TRASTORNOS NO REUMATICOS DE LA VALVULA TRICUSPIDE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I369',
        descriptor: 'TRASTORNO NO REUMATICO DE LA VALVULA TRICUSPIDE, NO ESPECIFICADO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '79',
        name      : 'TRATAMIENTO QUIRURGICO DE LESIONES CRONICAS DE LAS VALVULAS MITRAL Y TRICUSPIDE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'I062',
        descriptor: 'ESTENOSIS AORTICA REUMATICA CON INSUFICIENCIA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 80,
    name      : 'TRATAMIENTO DE ERRADICACION DEL HELICOBACTER PYLORI',
    pathologic: [
      {
        id        : '80',
        name      : 'TRATAMIENTO DE ERRADICACION DEL HELICOBACTER PYLORI',
        code      : 'B980',
        descriptor: 'HELICOBACTER PYLORI [H. PYLORI] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 81,
    name      : 'CANCER DE PULMON EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '81',
        name      : 'CANCER DE PULMON EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C34',
        descriptor: 'TUMOR MALIGNO DE LOS BRONQUIOS Y DEL PULMON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '81',
        name      : 'CANCER DE PULMON EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C340',
        descriptor: 'TUMOR MALIGNO DEL BRONQUIO PRINCIPAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '81',
        name      : 'CANCER DE PULMON EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C341',
        descriptor: 'TUMOR MALIGNO DEL LOBULO SUPERIOR, BRONQUIO O PULMON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '81',
        name      : 'CANCER DE PULMON EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C342',
        descriptor: 'TUMOR MALIGNO DEL LOBULO MEDIO, BRONQUIO O PULMON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '81',
        name      : 'CANCER DE PULMON EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C343',
        descriptor: 'TUMOR MALIGNO DEL LOBULO INFERIOR, BRONQUIO O PULMON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '81',
        name      : 'CANCER DE PULMON EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C348',
        descriptor: 'LESION (NEOPLASIA) DE SITIOS CONTIGUOS DE LOS BRONQUIOS Y DEL PULMON',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '81',
        name      : 'CANCER DE PULMON EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C349',
        descriptor: 'TUMOR MALIGNO DE LOS BRONQUIOS O DEL PULMON, PARTE NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 82,
    name      : 'CANCER DE TIROIDES DIFERENCIADO Y MEDULAR EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '82',
        name      : 'CANCER DE TIROIDES DIFERENCIADO Y MEDULAR EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C73',
        descriptor: 'TUMOR MALIGNO DE LA GLANDULA TIROIDES',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '82',
        name      : 'CANCER DE TIROIDES DIFERENCIADO Y MEDULAR EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'D093',
        descriptor: 'CARCINOMA IN SITU DE LA GLANDULA TIROIDES Y DE OTRAS GLANDULAS ENDOCRINAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 83,
    name      : 'CANCER RENAL EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '83',
        name      : 'CANCER RENAL EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C64',
        descriptor: 'TUMOR MALIGNO DEL RIÑON, EXCEPTO DE LA PELVIS RENAL',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 84,
    name      : 'MIELOMA MULTIPLE EN PERSONAS DE 15 AÑOS Y MAS',
    pathologic: [
      {
        id        : '84',
        name      : 'MIELOMA MULTIPLE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C90',
        descriptor: 'MIELOMA MULTIPLE Y TUMORES MALIGNOS DE CELULAS PLASMATICAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '84',
        name      : 'MIELOMA MULTIPLE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C900',
        descriptor: 'MIELOMA MULTIPLE',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '84',
        name      : 'MIELOMA MULTIPLE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C901',
        descriptor: 'LEUCEMIA DE CELULAS PLASMATICAS',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '84',
        name      : 'MIELOMA MULTIPLE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C902',
        descriptor: 'PLASMOCITOMA, EXTRAMEDULAR',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
      {
        id        : '84',
        name      : 'MIELOMA MULTIPLE EN PERSONAS DE 15 AÑOS Y MAS',
        code      : 'C903',
        descriptor: 'PLASMACITOMA SOLITARIO',
        sex       : 'No aplica',
        min       : '15 años, 0 meses, 0 dias',
        max       : 'No aplica',
      },
    ],
  },
  {
    id        : 85,
    name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
    pathologic: [
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F00',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F000',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER,DE COMIENZO TEMPRANO(G30.0+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F001',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, DE COMIENZO TARDIO (G30.1+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F002',
        descriptor: 'DEMENCIA EN LA ENFERM DE ALZHEIMER, ATIPICA O DE TIPO MIXTO (G30.8+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F009',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, NO ESPECIFICADA (G30.9+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F01',
        descriptor: 'DEMENCIA VASCULAR',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F010',
        descriptor: 'DEMENCIA VASCULAR DE COMIENZO AGUDO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F011',
        descriptor: 'DEMENCIA VASCULAR POR INFARTOS MULTIPLES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F012',
        descriptor: 'DEMENCIA VASCULAR SUBCORTICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F013 ',
        descriptor: 'DEMENCIA VASCULAR MIXTA, CORTICAL Y SUBCORTICAL',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F018',
        descriptor: 'OTRAS DEMENCIAS VASCULARES',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F019',
        descriptor: 'DEMENCIA VASCULAR, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F020',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD DE PICK (G3L.0+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F021',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD DE CREUTZFELDT-JAKOB (A81.0+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F022',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD DE HUNTINGTON (GL0+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F023',
        descriptor: 'DEMENCIA EN LA ENFERMEDAD DE PARKINSON (G20+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F024',
        descriptor: 'DEMENCIA EN LA ENF POR VIRUS DE LA INMUNODEF HUMANA (VIH) (B22.0+)',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F028',
        descriptor: 'DEMENCIA EN OTRAS ENFERMEDADES ESPECIFICADAS CLASIF EN OTRA PARTE',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'F03',
        descriptor: 'DEMENCIA , NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'G30',
        descriptor: 'ENFERMEDAD DE ALZHEIMER',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'G300',
        descriptor: 'ENFERMEDAD DE ALZHEIMER DE COMIENZO TEMPRANO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'G301',
        descriptor: 'ENFERMEDAD DE ALZHEIMER DE COMIENZO TARDIO',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'G308',
        descriptor: 'OTROS TIPOS DE ENFERMEDAD DE ALZHEIMER',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'G309',
        descriptor: 'ENFERMEDAD DE ALZHEIMER, NO ESPECIFICADA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
      {
        id        : '85',
        name      : 'ENFERMEDAD DE ALZHEIMER Y OTRAS DEMENCIAS',
        code      : 'G310',
        descriptor: 'ATROFIA CEREBRAL CIRCUNSCRITA',
        sex       : 'No aplica',
        min       : 'No aplica',
        max       : 'No aplica',
      },
    ],
  },
];
