import {GesForm} from '@shared/interfaces/ges.interface';
import {Paperwork} from '@shared/interfaces/paperwork.interface';
import {format} from 'date-fns';
import intervalToDuration from 'date-fns/intervalToDuration';

export const buildContentPdfGes461 = (paperwork: Paperwork, group) => {
  const {patient, professional} = paperwork;
  const {rows} = group;
  const profesionalName = (`${professional?.names || ''} ${professional?.surnames || ''}`).trim();
  const buildPdfGes = rows.map((item: GesForm) => {
      const {
              center,
              lender,
              beneficiary,
              namePathology,
              gesCode,
              sex,
              minimumAge,
              maximumAge,
              type,
              notification,
              attention,
            } = item;

      return [
        {
          absolutePosition: {
            x: 40,
            y: 30
          },
          style           : 'lineInnerTable',
          table           : {
            widths: ['*'],
            body  : [
              [
                {
                  text      : [
                    {
                      text    : `FORMULARIO DE CONSTANCIA INFORMACIÓN AL PACIENTE GES\n`,
                      margin  : [0, 3, 0, 0],
                      bold    : true,
                      fontSize: 10
                    },
                    {text: `(Artículo 24°, Ley 19.966)`, fontSize: 10}
                  ],
                  lineHeight: 1,
                  alignment : 'center',
                  margin    : [0, 0, 0, 10],
                  border    : [true, true, true, false]
                }
              ],
              [
                {
                  border: [true, false, true, false],
                  style : 'lineInnerTable',
                  table : {
                    widths: ['*'],
                    body  : [
                      [{
                        text: `DATOS DEL PRESTADOR`, bold: true, fontSize: 10, lineHeight: 0.8,
                        border                                                           : [false, false, false, false]
                      }],
                      [
                        {
                          table : {
                            widths: [200, '*'],
                            body  : [
                              [
                                {
                                  text  : `INSTITUCIÓN (Hospital, Clínica, Consultorio, etc.):`,
                                  border: [false, false, false, false]
                                },
                                {
                                  text                                         : `${center?.name.toUpperCase()} | RED DE SALUD UC CHRISTUS`,
                                  border: [false, false, false, true], fontSize: 9
                                }
                              ],
                            ]
                          },
                          border: [true, true, true, false]
                        }
                      ],
                      [
                        {
                          table : {
                            widths: ['auto', '*', 'auto', 75],

                            body: [
                              [
                                {text: `DIRECCIÓN:`, border: [false, false, false, false]},
                                {text: `${lender.address}`, border: [false, false, false, true]},
                                {text: `CIUDAD:`, border: [false, false, false, false]},
                                {text: `${lender.city}`, border: [false, false, false, true]},
                              ],
                            ]
                          },
                          border: [true, false, true, false]
                        }
                      ],
                      [
                        {
                          table : {
                            widths: ['auto', '*'],
                            body  : [
                              [
                                {text: `NOMBRE PERSONA QUE NOTIFICA:`, border: [false, false, false, false]},
                                {text: `${profesionalName}`, border: [false, false, false, true]}
                              ],
                            ]
                          },
                          border: [true, false, true, false]
                        }
                      ],
                      [
                        {
                          table : {
                            widths: ['auto', 170],
                            body  : [
                              [
                                {text: `RUN:`, border: [false, false, false, false]},
                                {text: `${lender.documentNumber}`, border: [false, false, false, true]}
                              ],
                            ]
                          },
                          border: [true, false, true, true],
                          margin: [0, 0, 0, 5]
                        }
                      ],
                    ],
                  }
                }
              ],
              [
                {
                  margin: [0, 3, 0, 0],
                  style : 'lineInnerTable',
                  border: [true, false, true, false],
                  table : {
                    widths: ['*'],
                    body  : [
                      [{
                        text      : `ANTECEDENTES DEL PACIENTE`,
                        bold      : true,
                        lineHeight: 0.8,
                        fontSize  : 10,
                        border    : [false, false, false, false]
                      }],

                      [
                        {

                          table : {
                            widths: ['auto', '*'],
                            body  : [
                              [
                                {
                                  text  : `NOMBRE LEGAL:`,
                                  border: [false, false, false, false]
                                },
                                {text: `${patient.names} ${patient.surnames}`, border: [false, false, false, true]}
                              ],
                            ]
                          },
                          border: [true, true, true, false]
                        }
                      ],
                      [
                        {

                          table : {
                            widths: ['auto', '*'],
                            body  : [
                              [
                                {text: `NOMBRE SOCIAL:`, border: [false, false, false, false]},
                                {
                                  text  : `${beneficiary?.socialName ? beneficiary?.socialName.toUpperCase() : ''}`,
                                  border: [false, false, false, true]
                                }
                              ],
                            ]
                          },
                          border: [true, false, true, false]
                        }
                      ],
                      [
                        {
                          table : {
                            widths: ['auto', '*', 30, 'auto', 40, 'auto', 20, 40, 'auto'],

                            body: [
                              [
                                {text: `RUN: `, border: [false, false, false, false]},
                                {text: `${patient.documentNumber}`, border: [false, false, false, true]},
                                {text: ``, border: [false, false, false, false]},
                                {text: `PREVISIÓN: `, border: [false, false, false, false]},
                                {
                                  text                                          : `${beneficiary.forecast === 'Fonasa' ? 'X' : ''}`,
                                  border: [false, false, false, true], alignment: 'center'
                                },
                                {text: `FONASA`, border: [false, false, false, false]},
                                {text: ``, border: [false, false, false, false]},
                                {
                                  text                                          : `${beneficiary.forecast === 'Isapre' ? 'X' : ''}`,
                                  border: [false, false, false, true], alignment: 'center'
                                },
                                {text: `ISAPRE`, border: [false, false, false, false]},
                              ],
                            ]
                          },
                          border: [true, false, true, false]
                        }
                      ],
                      [
                        {
                          table : {
                            widths: ['auto', '*', 'auto', 80, 'auto', 'auto'],

                            body: [
                              [
                                {text: `DOMICILIO`, border: [false, false, false, false]},
                                {text: `${beneficiary.address}`, border: [false, false, false, true]},

                                {text: `COMUNA`, border: [false, false, false, false]},
                                {text: `${beneficiary.commune}`, border: [false, false, false, true]},

                                {text: `REGIÓN`, border: [false, false, false, false]},
                                {text: `${beneficiary.region.replace(/\d+/g, '')}`, border: [false, false, false, true]},

                              ],
                            ]
                          },
                          border: [true, false, true, false]
                        }
                      ],
                      [
                        {
                          table : {
                            widths: ['auto', 'auto', 'auto', '*'],
                            body  : [
                              [
                                {text: `N° TELÉFONO`, border: [false, false, false, false]},
                                {text: `${patient.phone}`, border: [false, false, false, true]},
                                {text: `CORREO ELECTRÓNICO`, border: [false, false, false, false]},
                                {text: `${patient.email}`, border: [false, false, false, true]},
                              ],
                            ]
                          },
                          border: [true, false, true, true],
                          margin: [0, 0, 0, 5]
                        }
                      ],

                    ],
                  },
                },
              ],
              [
                {
                  margin: [0, 3, 0, 0],
                  border: [true, false, true, false],
                  style : 'lineInnerTable',
                  table : {
                    widths: ['*'],
                    body  : [
                      [{text: `INFORMACIÓN MÉDICA`, bold: true, fontSize: 10, border: [false, false, false, false]}],
                      [
                        {

                          table : {
                            widths: ['auto', '*', 'auto', 16],
                            body  : [
                              [
                                {text: `PROBLEMA DE SALUD GES:`, border: [false, false, false, false]},
                                {text: `${namePathology}`, border: [false, false, false, true], fontSize: 9},
                                {text: `N°`, border: [false, false, false, false]},
                                {text: `${gesCode}`, border: [false, false, false, true]},
                              ],
                            ]
                          },
                          border: [true, true, true, false],
                        }
                      ],
                      [
                        {

                          table : {
                            widths : [20, 'auto', 20, 'auto', 20, 'auto', 20, 'auto', 20, 'auto',],
                            heights: 12,
                            body   : [
                              [
                                {text: `${type === 'sospecha' ? 'X' : ''}`, alignment: 'center', margin: [0, 2, 0, 0]},
                                {text: `Sospecha`, border: [false, false, false, false], margin: [0, 5, 0, 0]},
                                {text: `${type === 'diagnostico' ? 'X' : ''}`, alignment: 'center', margin: [0, 2, 0, 0]},
                                {
                                  text: `Diagnóstico \n y/o Etapificación`, border: [false, false, false, false],
                                  margin                                          : [0, -3, 0, 0], lineHeight: 0.8
                                },
                                {text: `${type === 'tratamiento' ? 'X' : ''}`, alignment: 'center', margin: [0, 2, 0, 0]},
                                {text: `Tratamiento`, border: [false, false, false, false], margin: [0, 5, 0, 0]},
                                {text: `${type === 'seguimiento' ? 'X' : ''}`, alignment: 'center', margin: [0, 2, 0, 0]},
                                {text: `Seguimiento`, border: [false, false, false, false], margin: [0, 5, 0, 0]},
                                {
                                  text: `${type === 'rehabilitacion' ? 'X' : ''}`, alignment: 'center',
                                  margin                                                    : [0, 2, 0, 0]
                                },
                                {text: `Rehabilitación`, border: [false, false, false, false], margin: [0, 5, 0, 0]},
                              ],
                            ]
                          },
                          margin: [10, 0, 0, 5],
                          border: [true, false, true, true]
                        }
                      ],

                    ]
                  },
                },
              ],
              [
                {
                  margin: [0, 3, 0, 0],
                  border: [true, false, true, false],
                  style : 'lineInnerTable',
                  table : {
                    widths: ['*'],
                    body  : [
                      [{text: `TIPO ATENCIÓN`, bold: true, fontSize: 10, border: [false, false, false, false]}],
                      [
                        {

                          table : {
                            widths : [20, 'auto', 20, 'auto'],
                            heights: 13,

                            body: [
                              [
                                {
                                  text                       : `${center?.name.toLowerCase() === 'centro médico virtual' ? '' : 'X'}`,
                                  alignment: 'center', margin: [0, 2, 0, 0]
                                },
                                {text: `Presencial`, border: [false, false, false, false], margin: [0, 5, 150, 0]},
                                {
                                  text                       : `${center?.name.toLowerCase() === 'centro médico virtual' ? 'X' : ''}`,
                                  alignment: 'center', margin: [0, 2, 0, 0]
                                },
                                {text: `Teleconsulta`, border: [false, false, false, false], margin: [0, 5, 0, 0]},
                              ],
                            ]
                          },
                          margin: [10, 3, 0, 3],
                          border: [true, true, true, true]
                        }
                      ],

                    ]
                  },
                },
              ],
              [
                {
                  text      : [
                    {text: `CONSTANCIA: \n`, margin: [0, 3, 0, 0], bold: true, fontSize: 10,},
                    {text: `Tomo conocimiento que tengo derecho a acceder a las Garantías Explicitas en Salud, `},
                    {text: `en la medida que me atienda en la red de Prestadores que asigne el Fonasa o la Isapre, según corresponda.`}
                  ],
                  lineHeight: 1,
                  margin    : [0, 0, 0, 0],
                  border    : [true, true, true, true],
                  alignment : 'justify'
                }
              ],
              [
                {
                  margin: [0, 5, 0, 50],
                  border: [true, false, true, false],
                  style : 'lineInnerTable',
                  table : {
                    widths: ['auto', 250],
                    body  : [
                      [
                        {text: `FECHA Y HORA DE NOTIFICACIÓN:`, bold: true, border: [false, false, false, false]},
                        {text: `${format(new Date(), `dd-MM-yyyy 'a las' HH:mm`)}`, border: [false, false, false, true]},
                      ],

                    ]
                  },
                },
              ],
              [
                {
                  border: [true, false, true, false],
                  style : 'lineInnerTable',
                  table : {

                    body: [
                      [
                        {

                          text     : [
                            {text: '* En la modalidad de teleconsulta, '},
                            {text: `en reemplazo de la firma o huella`, decoration: 'underline'},
                            {text: `, se registrará el medio a través del cual el paciente o su representante tomó conocimiento.`}
                          ],
                          bold     : true,
                          fontSize : 9,
                          margin   : [0, 34, 0, 0],
                          alignment: 'justify',
                          border   : [false, false, false, false],
                        },
                      ],

                    ]
                  },
                },
              ],
              [
                {
                  border: [true, false, true, false],
                  style : 'lineInnerTable',
                  table : {
                    widths: ['*'],
                    body  : [
                      [
                        {
                          table : {
                            widths : ['auto', 20, 60, 'auto', 20, 60, 'auto', 80],
                            heights: 13,
                            body   : [
                              [
                                {
                                  text: `Correo electrónico`, bold: true, border: [false, false, false, false],
                                  margin                                        : [-10, 5, 0, 0]
                                },
                                {text: `X`, alignment: 'center', margin: [0, 2, 0, 0]},
                                {text: ``, border: [false, false, false, false]},
                                {
                                  text: `Carta certificada`, bold: true, border: [false, false, false, false],
                                  margin                                       : [0, 5, 0, 0]
                                },
                                {text: ``, alignment: 'center', margin: [0, 2, 0, 0]},
                                {text: ``, border: [false, false, false, false]},
                                {
                                  text: `Otros (indicar)`, bold: true, border: [false, false, false, false],
                                  margin                                     : [0, 5, 0, 0]
                                },
                                {text: ``, alignment: 'center', margin: [0, 2, 0, 0]},

                              ],
                            ]
                          },
                          margin: [0, 3, 0, 3],
                          border: [false, false, false, false]
                        }
                      ],

                    ]
                  },
                },
              ],
              [
                {

                  text    : `En caso que la persona que tomó conocimiento no sea el paciente, identificar:`,
                  margin  : [0, 8, 0, 0],
                  fontSize: 10,
                  bold    : true,
                  border  : [true, false, true, false],

                }
              ],
              [
                {
                  text    : 'NOMBRE:_________________________________________________________________________ ' +
                    'RUN:___________________________',
                  fontSize: 10,
                  margin  : [0, 8, 0, 0],
                  border  : [true, false, true, false],
                }
              ],
              [
                {
                  text    : 'N° TELÉFONO:_________________________________________________ ' +
                    'CORREO ELECTRÓNICO:___________________________',
                  fontSize: 10,
                  margin  : [0, 4, 0, 0],
                  border  : [true, false, true, false],
                }
              ],
              [
                {
                  text      : [
                    {text: 'IMPORTANTE: \n', bold: true, fontSize: 10, margin: [0, 3, 0, 0]},
                    {
                      text  : 'Tenga presente que si no se cumplen las garantías usted puede ' +
                        'reclamar ante Fonasa o la Isapre, según corresponda.',
                      bold  : true,
                      margin: [0, 3, 0, 0]
                    },
                    {
                      text  : 'Si la respuesta no es satisfactoria, usted puede recurrir en segunda ' +
                        'instancia a la Superintendencia de Salud.',
                      bold  : true,
                      margin: [0, 3, 0, 0]
                    }
                  ],
                  fontSize  : 9,
                  lineHeight: 1,
                  alignment : 'justify',
                  margin    : [0, 8, 0, 0],
                  border    : [true, false, true, true],
                }
              ]
            ]
          }
        },
      ];
    }
  );
  return [buildPdfGes];
};
