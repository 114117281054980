import {HttpClient, HttpEventType} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as b64toBlob from 'b64-to-blob';
import {environment} from '@environments/environment';

import {Observable} from 'rxjs';
import {map, retry, tap} from 'rxjs/operators';

import {API_URIS} from '@shared/constants/api-uris.const';
import {MIME_TYPES} from '@shared/constants/mime-types.const';

import {PCV2Request} from '@shared/interfaces/request.interface';
import {Attachment, FileToStorage, PresignedUrlRequest, PresignedUrlResponse,} from '@shared/interfaces/storage.interface';
import {DateService} from '@shared/services/date.service';

const BASE_API_PCV2: string = environment.baseApiPCv2;
const URI_STORAGE: string = API_URIS.storage;
const URI_BUCKET_S3: string = environment.defaultBucketS3;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private httpClient: HttpClient) {
  }

  createPresignedUrl(body: PresignedUrlRequest): Observable<PresignedUrlResponse> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_STORAGE}/prefirmar`, body).pipe(
      retry(3),
      map(({data}: { data: PresignedUrlResponse }) => data),
    );
  }

  // TODO: Esto se debe eliminar, solo la utiliza Andres para el asunto de formulario de peritaje pero esto debe ser
  // cambiado
  createListUrls(body: PresignedUrlRequest[]): Observable<PresignedUrlResponse[]> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_STORAGE}/prefirmar`, body).pipe(
      map(({data}: { data: PresignedUrlResponse[] }) => data),
    );
  }

  putObjectToS3(url: string, body: FormData | File): Observable<number> {
    const COMPLETE_PROGRESS = 100;
    return this.httpClient.put(`${url}`, body, {reportProgress: true, observe: 'events'}).pipe(
      map((event) => {
        if (event.type === HttpEventType.UploadProgress) return event.loaded / event.total;
        if (event.type === HttpEventType.Response) return COMPLETE_PROGRESS;
      }),
    );
  }

  uploadObjectToS3(files: FileToStorage[]) {
    return files.map((doc) => {
      const {url, file} = doc;
      return this.httpClient.put(`${url}`, file).pipe(
        map(() => 200),
      );
    });
  }

  getObjectBase64ToS3ToDownload(attachment: Attachment): Promise<void> {
    const {path, key, ext} = attachment;
    const endpoint = `${URI_BUCKET_S3}/${path}/${key}`;
    return this.httpClient
               .get<any>(`${BASE_API_PCV2}/${URI_STORAGE}/archivosbase/${endpoint}`)
               .pipe(
                 tap((base64) => this.downloadFile(base64, ext)),
               )
               .toPromise()
               .catch((error) =>
                 console.error(
                   'Ocurrió un error mientras se descargaba el archivo: Sv:StorageService Fn:getObjectBase64ToS3',
                   error,
                 ),
               );
  }

  getObjectBase64ToS3(attachment: Attachment): Observable<string> {
    const {path, key, ext} = attachment;
    const endpoint = `${URI_BUCKET_S3}/${path}/${key}`;
    return this.httpClient.get<any>(`${BASE_API_PCV2}/${URI_STORAGE}/archivosbase/${endpoint}`);
  }

  downloadFile(base64: string, extension: string): void {
    extension = extension.replace('.', '');
    const contentType = MIME_TYPES[extension];
    const blob = b64toBlob.default(base64, contentType);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }

  getParamsToRequestSignedUrl(requestFiles: FileToStorage[]): Observable<PresignedUrlResponse>[] {
    return requestFiles.map((file) => {
      const {extension, path} = file;
      const presigned: PresignedUrlRequest = {
        path,
        ext   : extension,
        bucket: URI_BUCKET_S3,
      };
      return this.createPresignedUrl(presigned);
    });
  }

  async presignedUrlToAttachments(fileToStorages: FileToStorage[]): Promise<Attachment[]> {
    const datetime = await DateService.getTime('datetime');
    return fileToStorages.map((presigned) => ({
      key       : presigned.key,
      ext       : presigned.extension,
      path      : presigned.path,
      bucket    : presigned.bucket,
      category  : presigned.title,
      isAttached: presigned.isAttached,
      uploadDate: datetime,
    }));
  }

  /////// DAU ///////

  getObjectBase64ToS3DAU(attachment: Attachment): Promise<string> {
    const { path, key, ext } = attachment;
    const endpoint = `${URI_BUCKET_S3}/${path}/${key}`;
    return this.httpClient
      .get<any>(`${BASE_API_PCV2}/${URI_STORAGE}/archivosbase/${endpoint}`)
      .pipe(
        retry(3),
      ).toPromise()
  }
}
