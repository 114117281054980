import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PipesModule } from './pipes/pipes.module';

import { StatusComponent } from './components/status/status.component';
import { FormHeaderComponent } from './components/form-header/form-header.component';
import { PdfViewerComponent } from '@shared/components/pdf-viewer/pdf-viewer.component';
import { TitleFormComponent } from '@shared/components/title-form/title-form.component';
import { HeaderModalsComponent } from './components/header-modals/header-modals.component';
import { PreviewPdfComponent } from '@shared/components/pdf-preview/preview-pdf.component';
import { BtnModalCloseComponent } from './components/btn-modal-close/btn-modal-close.component';
import { BtnPdfPreviewComponent } from '@shared/components/btn-pdf-preview/btn-pdf-preview.component';
import { FrmSectionTitleComponent } from './components/frm-section-title/frm-section-title.component';
import {BtnPaperworkFagnComponent} from '@shared/components/btn-paperwork-fagn/btn-paperwork-fagn.component';
import { BtnFavoriteSaveComponent } from '@shared/components/btn-favorite-save/btn-favorite-save.component';
import { FormSubtitleSeparatorComponent } from '@shared/components/subtitle/form-subtitle-separator.component';
import { FieldValidationMsgComponent } from './components/field-validation-msg/field-validation-msg.component';
import { BtnAnamnesisSelectorComponent } from './components/anamnesis-selector-btn/btn-anamnesis-selector.component';
import {
    PopoverAnamnesisSelectorComponent,
} from './components/anamnesis-selector/popover-anamnesis-selector.component';

import { ShowInAllowedModulesDirective } from './directives/show-in-allowed-modules.directive';

const sharedComponents = [
    StatusComponent,
    PdfViewerComponent,
    TitleFormComponent,
    FormHeaderComponent,
    PreviewPdfComponent,
    HeaderModalsComponent,
    BtnPdfPreviewComponent,
    BtnModalCloseComponent,
    BtnFavoriteSaveComponent,
    FrmSectionTitleComponent,
    BtnPaperworkFagnComponent,
    FieldValidationMsgComponent,
    BtnAnamnesisSelectorComponent,
    FormSubtitleSeparatorComponent,
    PopoverAnamnesisSelectorComponent,
];
const sharedDirectives = [ShowInAllowedModulesDirective];
const sharedModules = [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PipesModule,
    NgxExtendedPdfViewerModule,
];

@NgModule({
    imports: [sharedModules],
    declarations: [...sharedComponents, ...sharedDirectives],
    exports: [...sharedComponents, ...sharedModules, ...sharedDirectives],
})
export class SharedModule {
}
