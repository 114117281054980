import {patientTopCard} from '@shared/helpers/pdf-patient-card.helper';
import { Radiotherapy } from '../interfaces/radiotherapy.interface';

export const buildContentPdfRadiotherapy = (paperwork, group) => {
  const {rows}               = group;
  const radiotherapyBuildPdf = rows.map((itemForm) => {
    let diagnostic          = itemForm.diagnostic;
    let insuranse           = itemForm.insurance?.name;
    let site                = itemForm.site;
    let treatment           = itemForm.insurance.option.map((name) => name.description);
    const OPTION            = itemForm.insurance.option.map((item) => ({
      text    : ` ${item.sapId} - ${item.description}\n`,
      bold    : true,
      margin  : [0, 5, 0, 0],
      fontSize: 12,
    }));
    let convention          = itemForm.convention.name;
    let otherConvention     = itemForm.convention.other;
    let hospitalizedPatient = itemForm.hospitalizedPatient;
    let anesthesia          = itemForm.anesthesia.isAnesthetized;
    let totalSessions       = itemForm.anesthesia.totalSessions;
    let observation         = itemForm.observation;

    if(hospitalizedPatient === false) hospitalizedPatient = 'No'
    if(hospitalizedPatient === true) hospitalizedPatient = 'Si'

    if(anesthesia === false) anesthesia = 'No'
    if(anesthesia === true) anesthesia = 'Si'


    if (diagnostic)
      diagnostic = [
        {fontSize: 12, text: ` Diagnóstico: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${diagnostic}`,
          bold      : true,
        },
      ];
    if (insuranse)
      insuranse = [
        {fontSize: 12, text: ` Previsión: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${insuranse}`,
          bold      : true,
        },
      ];
    if (convention)
      convention = [
        {fontSize: 12, text: ` Convenio: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${convention}`,
          bold      : true,
        },
      ];
    if (otherConvention)
      otherConvention = [
        {fontSize: 12, text: `Otro Convenio: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${otherConvention}`,
          bold      : true,
        },
      ];
    treatment = [{fontSize: 12, text: ` Tratamiento: `}, {ul: OPTION}];

      anesthesia = [
        {fontSize: 12, text: ` Anestesia: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${anesthesia}`,
          bold      : true,
        },
      ];
    if (totalSessions)
      totalSessions = [
        {fontSize: 12, text: ` N° de sesiones: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${totalSessions}`,
          bold      : true,
        },
      ];

      hospitalizedPatient = [
        {fontSize: 12, text: ` Paciente Hospitalizado: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${hospitalizedPatient}`,
          bold      : true,
        },
      ];
    if (site)
      site = [
        {fontSize: 12, text: ` Lugar: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${site}`,
          bold      : true,
        },
      ];
    if (observation)
      observation = [
        {fontSize: 12, text: ` Observaciones: `},
        {
          fontSize  : 12,
          marginTop : -14,
          marginLeft: 220,
          alignment : 'justify',
          text      : `${observation}`,
          bold      : true,
        },
      ];

    const RADIOTHERAPY_CONTENT = {
      fontSize : 15,
      alignment: 'justify',
      stack    : [
        diagnostic,
        insuranse,
        treatment,
        convention,
        otherConvention,
        anesthesia,
        totalSessions,
        hospitalizedPatient,
        site,
        observation,
      ],
    };

    return [RADIOTHERAPY_CONTENT];
  });


  return [...patientTopCard(paperwork), radiotherapyBuildPdf];
};