/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {DaysRequest} from '../interfaces/schedule.interface';
import {DaysWeek, Exceptions, Status, StatusRequest, TypeRequest} from '../enums/type.enum';
import {addMinutes, isValid, parseISO} from 'date-fns';
import {TableExport} from '@components/records/enums/table.enum';
import {format, utcToZonedTime} from 'date-fns-tz';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {DateServiceSchedule} from './date.service';
import {Observable, map, retry} from 'rxjs';
import {PCV2Request} from '@shared/interfaces/request.interface';
import {BlockingChart} from '../interfaces/blocking.interface';

const BASE_API_PCV2: string = environment.baseApiPCv2;

@Injectable({
  providedIn: 'root',
})
export class RequesScheduleService {
  constructor(private httpClient: HttpClient) {
  }

  formatInTimeZone(date, fmt, tz) {
    return format(utcToZonedTime(date, tz), fmt, {timeZone: tz});
  }

  createRequestSchedule(request) {
    const daysRequest = new Object() as DaysRequest;
    let scheduleRequest = {};
    let valuesToSave = {};
    let timeSlot = {};

    if (request.type === 'Cierre' || request.type === 'Oferta') {
      const interval = DateServiceSchedule.calculateInterval(request.startTime, request.endTime);
      request.daysRequest.map((day) => {
        if (day.name === 'monday' || day === 'Lunes') daysRequest.Monday = true;
        if (day.name === 'tuesday' || day === 'Martes') daysRequest.Tuesday = true;
        if (day.name === 'wednesday' || day === 'Miércoles') daysRequest.Wednesday = true;
        if (day.name === 'thursday' || day === 'Jueves') daysRequest.Thursday = true;
        if (day.name === 'friday' || day === 'Viernes') daysRequest.Friday = true;
        if (day.name === 'saturday' || day === 'Sábado') daysRequest.Saturday = true;
        if (day.name === 'sunday' || day === 'Domingo') daysRequest.Sunday = true;
      });
      timeSlot = {
        ...daysRequest,
        interval,
        timeSlotAppointmentTypes: [],
      };
      valuesToSave = {
        tags: request.tags,
        roomReservationNeeded: request.roomReservationNeeded,
        unit: request.unit,
        validFrom: request.validFrom,
        box: request.box,
        validTo: request.validTo,
        timeSlot,
        observations: request.observations,
      };
      scheduleRequest = {
        type: request.type ? (request.type === 'Cierre' ? 'Closure' : 'Offer') : '',
        professional: request.profesional,
        institute: request.institute,
        center: request.center,
        specialty: request.specialty,
        service: request.service[0],
        request: valuesToSave,
        gsiOne: request.gsiOne,
        globalStatus: 'activo',
      };
      return scheduleRequest;
    } else {
      const interval = DateServiceSchedule.calculateInterval(request.startTime, request.endTime);
      const exclusionType = request.blockingType
        ? request.blockingType !== 'POR HORARIO'
          ? 'WholeDay'
          : 'Partial'
        : '';
      const center = request.centerExclude.map((centerWhole) => centerWhole);
      if (exclusionType !== 'WholeDay')
        request.daysRequest.map((day) => {
          if (day.name === 'monday') daysRequest.Monday = true;
          if (day.name === 'tuesday') daysRequest.Tuesday = true;
          if (day.name === 'wednesday') daysRequest.Wednesday = true;
          if (day.name === 'thursday') daysRequest.Thursday = true;
          if (day.name === 'friday') daysRequest.Friday = true;
          if (day.name === 'saturday') daysRequest.Saturday = true;
          if (day.name === 'sunday') daysRequest.Sunday = true;
        });
      timeSlot = {
        ...daysRequest,
        interval,
        timeSlotAppointmentTypes: [],
      };
      valuesToSave = {
        availabilityServices: [],
        unit: request.unit,
        exclusionReason: request.blockageReason.name,
        exclusionType,
        exclusionReasonId: request.blockageReason.id,
        validFrom: this.formatInTimeZone(parseISO(request.validFrom), `yyyy-MM-dd'T'00:00:00xxxxx`, 'America/Santiago'),
        validTo: this.formatInTimeZone(parseISO(request.validTo), `yyyy-MM-dd'T'23:59:59xxxxx`, 'America/Santiago'),
        timeSlot,
        repetition: 1,
        observations: request.observations,
        timeZoneId: 'America/Santiago',
      };
      scheduleRequest = {
        type: 'Exclude',
        professional: request.profesional,
        institute: request.institute,
        center,
        request: valuesToSave,
        gsiOne: request.gsiOne,
        globalStatus: 'activo',
      };
      return scheduleRequest;
    }
  }

  formatDataResolution(allRequest: any[], types?: string) {
    const data = allRequest.map((offer) => {
      const {professional, specialty, service, request, status} = offer;
      const {pk, sk, id, isTaken} = offer;
      const {type, createdAt, updatedAt, center, institute, reasonReject, internalExclusionId} = offer;
      const {validFrom, validTo, exclusionType, timeSlot, box} = request;
      const professionalName = [professional.names, professional.surnames];
      if (offer.request) {
        const base = {
          box,
          createdAt,
          institute,
          data: offer,
          reasonReject,
          status: Status[status],
          specialty: this.especialtyService(specialty, service),
          center: this.transformCenter(center),
          type: TypeRequest[type.toLowerCase()],
          createdOn: this.formatDate(createdAt),
          updatedAt: this.formatDate(updatedAt),
          validTo: this.transformFromTo(validTo, type),
          validFrom: this.transformFromTo(validFrom, type),
          timeSlots: this.transformTimeSlots(timeSlot, exclusionType),
          internalExclusionId
        };
        const myRequest = {
          actions: reasonReject !== undefined ? ['Ver'] : [''],
        };
        if (types === 'Mis solicitudes') return {...base, ...myRequest};
        const resolution = {
          pk,
          sk,
          id,
          isTaken,
          request,
          professional: professionalName,
          stateDate: this.transformFromTo(validFrom, type),
          exclusionReason: this.transformExclusionReason(request),
          actions: this.getActionByStatus(status, isTaken, request),
        };
        if (types === 'Resoluciones') return {...base, ...resolution};
      }
    });
    return data;
  }

  formatDataResolutionSchedulePatient(allRequest: any[]) {
    const data = allRequest.map((offer) => {
      if (offer.content) {
        const {professional, content, status, patient} = offer;
        const {pk, sk, id, isTaken} = offer;
        const {createdAt, reasonReject} = offer;
        const {date, observations, timeFrom, timeTo} = content;
        const professionalName = [professional.names, professional.surnames];
        const patientName = [patient.names, patient.surnames];
        return {
          createdAt,
          updatedAt: this.formatDate(createdAt),
          createdOn: this.formatDate(createdAt),
          reasonReject,
          status: Status[status],
          timeFrom,
          afterStatus: offer,
          timeTo,
          pk,
          sk,
          id,
          isTaken,
          date,
          professional: professionalName,
          patient: patientName,
          observations,
          actions: this.getActionResPat(Status[status], isTaken, content),
        };
      }
    });
    return data;
  }

  getActionResPat(status: string, isTaken: boolean, request: any): string[] {
    let actions: string;
    if (status === 'rechazado' || request.observations) actions = 'Ver';
    else actions = '';
    if (status === 'pendiente') return ['Pre-aprovar', 'Rechazar', actions];
    if (status === 'pre-aprobado' && isTaken === false) return ['Pre-aprovar', 'Rechazar', 'Open', actions];
    if (status === 'pre-aprobado' && isTaken === true) return ['Pre-aprovar', 'Rechazar', 'Closed', actions];
    return [actions];
  }

  getActionByStatus(status: string, isTaken: boolean, request: any): string[] {
    const haveRequestTags = Boolean(request?.tags?.length);

    let actions: string;
    if (haveRequestTags || request.observations || status === 'rejected' || status === 'rechazado' || status === 'pre-approved' || status === 'pre-aprobado') actions = 'Ver';
    else actions = '';
    if (status === 'pendiente' || status === 'pending') return ['Pre-aprovar', 'Rechazar', actions];
    if (status === 'managed') actions = 'Ver';
    if ((status === 'pre-aprobado' && isTaken === false) || (status === 'pre-approved' && isTaken === false))
      return ['Pre-aprovar', 'Rechazar', 'Open', actions];
    if ((status === 'pre-aprobado' && isTaken === true) || (status === 'pre-approved' && isTaken === true))
      return ['Pre-aprovar', 'Rechazar', 'Closed', actions];

    return [actions];
  }

  especialtyService(specialty, service) {
    const concat = new Set([...this.transformSpecialtyService(specialty), ...this.transformSpecialtyService(service)]);
    const especialtyService: string[] = [];
    concat.forEach((item) => especialtyService.push(item));
    return especialtyService;
  }

  transformSpecialtyService(temp: any) {
    if (!temp || temp.length === 0 || JSON.stringify(temp) === '{}') return [];
    if (temp.length > 0) return temp;
    if (typeof temp === 'object') return [temp.name];
    return (temp = []);
  }

  transformExclusionReason(request) {
    if (request.exclusionReason === undefined || request.exclusionReason === null) return '';
    if (typeof request.exclusionReason === 'object') return request.exclusionReason.name;
    if (request.exclusionReason) return request.exclusionReason;
  }

  transformCenter(center) {
    if (center === null) return [''];
    if (typeof center === 'string') return [center];
    if (Array.isArray(center)) return center.reduce((acc, curr) => [...acc, curr.name], []);
    return [center.name];
  }

  transformFromTo(date, type: string) {
    if (isValid(date)) return date;
    if (type === 'Exclude' || type === 'Bloqueo') return format(new Date(date), 'dd-MM-yyyy');
    const [yyyy, mm, dd] = date.split('-');
    if (isValid(date) === false) return `${dd.substr(0, 2)}-${mm}-${yyyy}`;
  }

  transformTimeSlots(timeSlot, exclusionType) {
    if (exclusionType !== 'WholeDay')
      return this.formatTimeSlot([
        {
          ...timeSlot,
          ...timeSlot?.daysRequest,
        },
      ]);
    return [''];
  }

  formatTimeSlot(timeSlotList: any) {
    const [timeSlots] = timeSlotList;
    let formatTimeSlot = [];
    if (typeof timeSlots === 'object' && !timeSlots[0]) formatTimeSlot = this.transformDaysTimeSlot(timeSlots);
    if (timeSlots[0]) formatTimeSlot = this.transformDaysTimeSlot(timeSlots[0]);
    return formatTimeSlot;
  }

  transformDaysTimeSlot(timeSlot: any) {
    const cell = [];
    let initialsWeek = '';
    const keysArray = Object.keys(timeSlot).filter((item) => {
      if (Exceptions[item]) return item;
    });
    keysArray.forEach((key) => {
      if (timeSlot[key] === true) {
        initialsWeek += [DaysWeek[key]] + ' ';
        if (initialsWeek.includes(DaysWeek[key])) cell.push(initialsWeek);
      }
    });
    const {HourFrom, HourTo} = timeSlot.Interval === undefined ? timeSlot.interval : timeSlot.Interval;
    cell.push(this.interval(HourFrom, HourTo));
    cell.splice(0, cell.length - 2);
    return cell;
  }

  interval(HourFrom, HourTo) {
    if (!HourFrom && !HourTo) return '';
    if (HourFrom >= 0 && HourTo >= 0) {
      const date = new Date(2022, 1, 1, 0, 0, 0);
      HourFrom = format(addMinutes(date, Number(HourFrom)), 'HH:mm');
      HourTo = format(addMinutes(date, Number(HourTo)), 'HH:mm');
    }
    return `${HourFrom}-${HourTo}`;
  }

  formatDate(date: Date) {
    if (isValid(date)) return date;
    if (!isValid(date) && date !== undefined) return format(new Date(date), 'dd-MM-yyyy - HH:mm:ss');
    if (!date) return '';
  }

  formatStatusExportExcel(exp: any) {
    const name = TableExport[exp[0].section.toLowerCase()];
    let status;
    switch (name) {
      case 'Activas':
        status = exp[0].excel.filter((x) => x.status !== 'gestionado' && x.status !== 'rechazado');
        break;
      case 'Inactivas':
        status = exp[0].excel.filter((x) => x.status === 'gestionado' || x.status === 'rechazado');
        break;
      case StatusRequest[name.toLowerCase()]:
        status = exp[0].excel.filter((x) => x.status === name.toLowerCase());
        break;
    }
    const newData = this.formatExcelResolution(status, name);
    return {newData, name};
  }

  formatExcelResolution(dataResolution, name) {
    const newDataResolution = dataResolution.map((requestOffer) => {
      const {institute, status, specialty, service, updatedAt, timeSlots} = requestOffer;
      const {request, box, type, reasonReject, createdOn, data} = requestOffer;
      const {exclusionReason, validFrom, validTo, observations} = request;
      const {professional, professionalManeged, professionalReject, center} = data;
      const nombre = professional.names === undefined ? '' : professional.names;
      const apellidos = professional.surnames === undefined ? '' : professional.surnames;
      const base = {
        ['Fecha']: createdOn,
        ['Profesional']: nombre.toLowerCase() + ' ' + apellidos.toLowerCase(),
        ['Tipo']: type,
        ['Centro']: center?.name,
        ['Instituto']: institute,
        ['Especialidad Servicio']: ''.concat(...this.especialtyService(specialty, service)),
        ['Bloques']: timeSlots[0] && timeSlots[1] !== undefined ? timeSlots[0] + ' - ' + timeSlots[1] : '',
        ['Ultimo Estado']: updatedAt,
        ['Motivo']: exclusionReason,
        ['Box']: box,
        ['Estado']: status,
        ['Observaciones']: observations,
        ['Desde']: this.transformFromTo(validFrom, type),
        ['Hasta']: this.transformFromTo(validTo, type),
      };

      const inactives = {
        ['Profesional que Gestiona']: professionalReject === undefined ? professionalManeged : professionalReject,
        ['Motivo Rechazo']: reasonReject,
      };
      if (name === 'Inactivas') return {...base, ...inactives};
      if (name === 'Activas') return {...base};
    });
    return newDataResolution;
  }

  getRequestBlockagesByProfessional(dateFrom: string, dateTo: string, stats: string, rutProfessional: string): Observable<BlockingChart[]> {
    const URI = 'agenda/v1/profesionales/bloqueos';
    const URL = `${BASE_API_PCV2}/${URI}?dateFrom=${dateFrom}&dateTo=${dateTo}&stats=${stats}&rutProfessional=${rutProfessional}`;
    return this.httpClient.get<PCV2Request>(`${URL}`).pipe(
      retry(3),
      map(({data}: { data: any[] }) => data),
    );
  }
}
