import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Professional} from '@auth/interfaces/professional.interface';
import {
  MAIL_RADIOTHERAPY_CSC,
  MAIL_RADIOTHERAPY_MARCOLETA,
  NURSES_MC_MAILS,
  NURSES_SC_MAILS,
} from '@clinical/oncology/constants/oncology-constant';
import {FormsOncology} from '@clinical/oncology/enums/encology.enum';
import {Patient} from '@components/patient/interfaces/patient.interface';
import {environment} from '@environments/environment';
import {Store} from '@ngrx/store';
import {API_URIS} from '@shared/constants/api-uris.const';
import {resolvePromises} from '@shared/helpers/allSettled.helper';
import {Email, ShortPathFile, Sms} from '@shared/interfaces/notification.interface';
import {Metadata, PCV2Request} from '@shared/interfaces/request.interface';
import {Attachment} from '@shared/interfaces/storage.interface';
import {DateService} from '@shared/services/date.service';
import {mailPartialTemplates, mailTemplates} from '@shared/templates/emails/mail-templates';
import {getMailTeamAccessText} from '@shared/templates/team-access.const';
import {AppState} from '@store/app.reducers';
import {PaperworkState} from '@store/reducers';
import {KEY_PROFESSIONAL_STORE} from '@store/store-keys';
import {firstValueFrom, lastValueFrom, Observable, of, Subject} from 'rxjs';
import {catchError, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {AddressService} from 'src/app/pharmacyportal/services/address.service';
import {QuotationService} from 'src/app/pharmacyportal/services/quotation.service';
import {Paperwork, WorkflowNotify} from '@shared/interfaces/paperwork.interface';
import {AmbulatoryAgendaService} from '@clinical/indications/services/ambulatory-agenda.service';
import {SpecialtyByService} from '@clinical/indications/interfaces/ambulatory-agenda.interface';
import {AddressPatientPharma} from '../../pharmacyportal/interfaces/address.interface';
import {EMAIL_DERIVATE_BY_PROFESIONAL} from '@clinical/surgeries/constants/derivate-nurse.constant';
import {NotificationEmailsService} from '@shared/services/notification-emails.service';
import {TENS_PROFILES_NO_PATIENT_MAIL} from '@auth/constants/profiles.const';
import {MAIL_DERIVATIONS, SPECIALTIES} from '@clinical/derivations/constants/derivation.constant';

const BASE_API_PCV2 = environment.baseApiPCv2;
const BASE_AGENDA_UC = environment.baseApiAgendaUc;
const URI_NOTIFICATIONS_V1 = API_URIS.notifications.v1;
const APP_DOMAIN: string = environment.appDomain;
const DOMAIN: string = environment.domain;
const PREFIX_ONCOLOGY = 'ONC';
const PREFIX_INDICATION = 'IND';
const PREFIX_SURGERY = 'SUR';
const PREFIX_RECIPE = 'RME';
const CENTER_MC = 'Hospital Marcoleta';
const CENTER_CSC = 'Clínica San Carlos';

/**
 * Servicio para administrar los diferentes tipos de notificaciones.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  destroy = new Subject();
  storeProfessional: any;
  textDerivations: string;

  /**
   * @ignore
   */
  constructor(
    private httpClient: HttpClient,
    private quoService: QuotationService,
    private notificationEmailsService: NotificationEmailsService,
    private ambulatoryAgendaService: AmbulatoryAgendaService,
    public addressService: AddressService,
    private store: Store<AppState>,
  ) {
    this.readStoreProfessional();
  }

  readStoreProfessional(): void {
    this.store.select(KEY_PROFESSIONAL_STORE).pipe(
      takeUntil(this.destroy),
      filter(({isLoaded, professional}) => isLoaded && professional !== undefined),
      map((professional) => this.storeProfessional = professional
      )).subscribe();
  }

  /**
   * es sub-profesional (TENS) que no debe enviar correos al paciente
   */
  isNoMailSubprofessionalTENS(): boolean {
    if (!this.storeProfessional?.subprofessional) return false;
    return this.storeProfessional.subprofessional.profiles
      .some((profile: string) => TENS_PROFILES_NO_PATIENT_MAIL.includes(profile));
  }

  /**
   * Servicio para Enviar Mensajes de Textos.
   *
   * @param sms - Cuerpo de la petición para enviar mensaje de texto.
   */
  sendSMS(sms: Sms): Promise<any> {
    const url = `${BASE_API_PCV2}/${URI_NOTIFICATIONS_V1}/sms`;
    return lastValueFrom(this.httpClient.post<any>(url, sms));
  }

  /**
   * Servicio para Enviar Correo Electrónico.
   *
   * @param email Cuerpo de la petición para enviar mensaje de correo electrónico.
   */
  sendEmail(email: Email): Observable<Metadata> {
    const url = `${BASE_API_PCV2}/${URI_NOTIFICATIONS_V1}/correo`;

    return this.httpClient.post<PCV2Request>(url, email).pipe(
      map(({metadata}) => metadata),
    );

  }

  /**
   * Obtener el nombre completo de un profesional o paciente.
   *
   * @param entityType Puede recibir las opciones de patient o professional.
   * @param entity Objeto del profesional o paciente que cumpla con las interfaces de Patient y/o Professional.
   */
  getEntityFullnameToUpperCase(entityType: 'patient' | 'professional', entity: Patient | Professional): string {
    const names = entity.names ? entity.names.toUpperCase() : '';
    const surnames = entity.surnames ? entity.surnames.toUpperCase() : '';

    return (names + ' ' + surnames).trim();
  }

  /**
   * Obtener el cuerpo del correo electrónico en HTML.
   *
   * @param template Nombre de la plantilla de correo que se debe usar para enviar el correo electrónico.
   * @param paperworkState Trámite extraído del estado global de la aplicación.
   * @param additionalContent información opcional necesaria para algunas plantillas de correo
   */
  async getEmailBodyHTML(template: string, paperworkState: PaperworkState, additionalContent: any): Promise<string> {
    if (template === 'recipe') return await this.getRecipeEmailBodyHTML(paperworkState, additionalContent);
    if (template === 'exams') return await this.getExamsEmailBodyHTML(paperworkState, additionalContent);
    if (template === 'educational') return await this.getEducationalEmailBodyHTML(paperworkState);
    if (template === 'derivations') return await this.getDerivationsEmailBodyHTML(paperworkState);

    const {paperwork, definition} = paperworkState;
    const docTitle = definition.submodule.docTitle.toUpperCase();
    const shortDate = await DateService.datetimeCL('short');
    return mailTemplates[template]
      .replace('$shortDate$', shortDate)
      .replace('$paperworkTitle$', docTitle)
      .replace('$patientFullname$', this.getEntityFullnameToUpperCase('patient', paperwork.patient))
      .replace('$patientDocumentNumber$', paperwork.patient?.documentNumber || '')
      .replace('$professionalFullname$', this.getEntityFullnameToUpperCase('professional', paperwork.professional))
      .replace('$professionalSpecialty$', (paperwork.professional?.specialty?.name || '').toUpperCase())
      .replace('$teamAccess$', this.getNotifyAccessTeamContent(paperworkState));
  }

  async getDerivationsEmailBodyHTML(paperworkState: PaperworkState): Promise<string> {
    const DER = 'Unidad Salud Mental Integral y Recepcion (USMI-R)';
    if (paperworkState.paperwork.content[0].interconsultation[0]?.name === DER || paperworkState.paperwork.content[0].service?.derivation?.specialty === DER)
      this.textDerivations = `
    <div style="text-align: left;color: #5a5a5a;font-size: 9px;line-height: 20px;">
    <p>Unidad Salud Mental Integral y Recepción (USMI-R)</p>
    <p>Centro Médico Providencia</p>
    <p>Teléfono de Contacto: 224820056</p>
    <p>Correo de Contacto: <a href="mailto:contacto.providencia@ucchristus.cl"></a>contacto.providencia@ucchristus.cl</p>
    <p>Más información en <a href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-salud-mental-integral-y-recepcion">Unidad Salud Mental Integral y Recepción</a></p>
    </div>
    <br/>
    `;
    else this.textDerivations = '';
    const {paperwork, definition} = paperworkState;
    const docTitle = definition.submodule.docTitle.toUpperCase();
    const shortDate = await DateService.datetimeCL('short');
    return mailTemplates.derivations
      .replace('$shortDate$', shortDate)
      .replace('$paperworkTitle$', docTitle)
      .replace('$patientFullname$', this.getEntityFullnameToUpperCase('patient', paperwork.patient))
      .replace('$patientDocumentNumber$', paperwork.patient?.documentNumber || '')
      .replace('$professionalFullname$', this.getEntityFullnameToUpperCase('professional', paperwork.professional))
      .replace('$professionalSpecialty$', (paperwork.professional?.specialty?.name || '').toUpperCase())
      .replace('$teamAccess$', this.getNotifyAccessTeamContent(paperworkState))
      .replace('$text$', this.textDerivations);

  }

  async getRecipeEmailBodyHTML(paperworkState: PaperworkState, quotationByPharmacy: string): Promise<string> {
    const {paperwork, definition} = paperworkState;
    const recipeUrl = `https://${APP_DOMAIN}${DOMAIN}/farmacia/checkout/${paperwork.shortId}`;
    const docTitle = definition.submodule.docTitle.toUpperCase();
    const shortDate = await DateService.datetimeCL('short');
    return mailTemplates.recipe
      .replace('$shortDate$', shortDate)
      .replace('$paperworkTitle$', docTitle)
      .replace('$patientFullname$', this.getEntityFullnameToUpperCase('patient', paperwork.patient))
      .replace('$professionalFullname$', this.getEntityFullnameToUpperCase('professional', paperwork.professional))
      .replace('$url$', recipeUrl)
      .replace('$quotation$', quotationByPharmacy);
  }

  async getExamsEmailBodyHTML(paperworkState: PaperworkState, linksButtons: string): Promise<string> {
    const {paperwork, definition} = paperworkState;
    const docTitle = definition.submodule.docTitle.toUpperCase();
    const shortDate = await DateService.datetimeCL('short');
    return mailTemplates.exams
      .replace('$shortDate$', shortDate)
      .replace('$paperworkTitle$', docTitle)
      .replace('$patientFullname$', this.getEntityFullnameToUpperCase('patient', paperwork.patient))
      .replace('$professionalFullname$', this.getEntityFullnameToUpperCase('professional', paperwork.professional))
      .replace('$professionalSpecialty$', (paperwork.professional?.specialty?.name || '').toUpperCase())
      .replace('$linksButtons$', linksButtons || '')
      .replace('$teamAccess$', this.getNotifyAccessTeamContent(paperworkState));
  }

  async getEducationalEmailBodyHTML(paperworkState: PaperworkState): Promise<string> {
    const {paperwork, definition} = paperworkState;
    const docTitle = definition.submodule.docTitle.toUpperCase();
    const shortDate = await DateService.datetimeCL('short');
    const materials = [];
    paperwork.content.map(content => {
      content.documents.map(doc => materials.push(`<li><a href='${doc.url}' target='_blank'>${doc.name}</a></li>`));
    });
    return mailTemplates.educational
      .replace('$shortDate$', shortDate)
      .replace('$paperworkTitle$', docTitle)
      .replace('$patientFullname$', this.getEntityFullnameToUpperCase('patient', paperwork.patient))
      .replace('$professionalFullname$', this.getEntityFullnameToUpperCase('professional', paperwork.professional))
      .replace('$materials$', materials.join(' '));
  }

  /**
   * Obtiene lista de precios de medicamentos por farmacia para un paciente
   */
  getQuotePharma(recipe: Paperwork) {
    const {patient} = recipe;
    return this.addressService
      .getPatientAddress(patient.id)
      .pipe(
        map(([firstAddress]) => firstAddress),
        tap((firstAddress) => this.validCommuneId(firstAddress.address?.comune?.id)),
        map((firstAddress) => this.getParamsToSearchQuote(recipe, firstAddress)),
        switchMap((params) => this.quoService.getPharmByRmeId(params)),
        map((quote) => ({quote, recipe})),
        catchError((error) => of({quote: null, recipe: null})),
      );
  }

  getParamsToSearchQuote(recipe: Paperwork, address: AddressPatientPharma) {
    /* eslint-disable @typescript-eslint/naming-convention */
    // TODO: No se deben eliminar las 3 propiedades siguientes, hasta que se confirme si el servicio de yapp va a necesitar estos datos
    const {patient, professional, content} = recipe;
    const formula = content.map((prescription) => ({id: prescription.medicine?.id}));
    return {
      medical: professional,
      patient,
      prescription: content,
      formula,
      commune_id: address?.address?.comune?.id,
      formatted_address: address?.formatted_address,
      address: address?.address,
    };
  }

  validCommuneId(communeId: any): any {
    if (!communeId) throw new Error('communeId is null');
    return communeId;
  }

  /**
   * Obtiene sección de cotización de farmacias para incorporar en el correo de recetas
   *
   * @param recipe
   */
  async getRecipeQuotePharmaEmailBodyHTML(recipe: Paperwork) {
    const $quotePharma = this.getQuotePharma(recipe);
    const {quote} = await firstValueFrom($quotePharma);
    if (!quote || !quote.data?.length) return '';

    const recipeBaseUrl = `https://${APP_DOMAIN}${DOMAIN}/farmacia/checkout/${recipe.shortId}`;
    const patientId = recipe.patient?.id;
    const pharmaciesQuote = quote.data
      .filter((pharm) => pharm.delivery.cost !== null)
      .map((pharm) => {
        const minmax = pharm.quotation_detail.sort((a, b) => a.price - b.price);
        const price = minmax.length > 1
          ? `$ ${minmax[0]?.price} - $ ${minmax[minmax.length - 1]?.price}`
          : `$ ${minmax[0]?.price}`;
        return mailPartialTemplates.pharmacyQuote
          .replace('$name$', pharm.pharmacy_chain_name)
          .replace('$logo$', pharm.pharmacy_chain_logo)
          .replace('$pharmacyUrl$', `${recipeBaseUrl}/paciente/${patientId}/farmacia/${pharm.pharmacy_chain_id}`)
          .replace('$deliveryDate$', pharm.delivery?.next_date || '')
          .replace('$deliveryCost$', pharm.delivery.cost)
          .replace('$price$', price);
      });
    return mailPartialTemplates.pharmaciesSelection.replace('$items$', pharmaciesQuote.join(''));
  }

  /**
   * Obtiene lista de especialidades para indicaciones (exámenes) de un trámite
   *
   * @param exams lista de exámenes
   */
  async getIndicationsSpecialtyByService(exams: any[]): Promise<SpecialtyByService[]> {
    const idServiceRequests = exams
      .filter(exam => exam.booking && exam.booking === 'link')
      .map(exam => {
        const ambulatoryAgenda$ = this.ambulatoryAgendaService.getAmbulatoryAgenda(exam);
        return firstValueFrom(ambulatoryAgenda$);
      });
    const [indicationsServicesIds] = await resolvePromises(idServiceRequests);
    return indicationsServicesIds
      .filter(item => item && item.statusCod === 'OK')
      .map(item => item.especialidadesPorServicio)
      .flat();
  }

  /**
   * Obtiene links de indicaciones para incluir en correo cuando corresponda
   *
   * @param indication
   */
  async getIndicationLink(indication: Paperwork) {
    const {content, patient, attachments} = indication;
    const specialtyByService = await this.getIndicationsSpecialtyByService(content);
    const links = content.map(exam => {
      const booking = exam?.booking || 'direct';
      const serviceName = (exam.glosa || '').toLocaleUpperCase();
      const codFonasa = exam.codFon ? `Código Fonasa ${String(exam.codFon)}` : '';
      const defaultHTML = mailPartialTemplates.examButtonDirect
        .replace('$serviceName$', serviceName)
        .replace('$codFonasa$', codFonasa);
      const callHTML = mailPartialTemplates.examButtonCall
        .replace('$serviceName$', serviceName)
        .replace('$codFonasa$', codFonasa);

      switch (booking) {
        case 'call':
          return mailPartialTemplates.examButtonCall
            .replace('$serviceName$', serviceName)
            .replace('$codFonasa$', codFonasa);

        case 'link':
          if (!specialtyByService || !specialtyByService.length) {
            if (exam.group === 'IMAGENES') return callHTML;
            return defaultHTML;
          }
          const areaId = this.ambulatoryAgendaService.getAreaId(exam.group, exam.prmId);
          const specialty = specialtyByService.find(srv =>
            srv && (srv.idServicio === exam.id || String(srv.idServicio)
              .toLocaleLowerCase() === String(exam.prmId)
              .toLocaleLowerCase()),
          );
          if (!specialty || !specialty.idEspecialidad) {
            // Si es examen de imagen, por defecto debe ser llamada
            if (exam.group === 'IMAGENES') return callHTML;
            return defaultHTML;
          }
          const linkQueryParams = new URLSearchParams({
            area: areaId,
            tipo: 'especialidad',
            centro: '6bad9b25-d5df-4565-b5fe-a6f701444053',
            especialidad: String(specialty.idEspecialidad),
            servicio: String(specialty.idServicio),
            rut: patient.documentNumber,
            tipoDocumento: 'RUN',
            ruta: 'indicaciones',
            idDocumento: attachments[0] ? attachments[0].key : '',
          }).toString();
          return mailPartialTemplates.examButtonLink
            .replace('$serviceName$', serviceName)
            .replace('$codFonasa$', codFonasa)
            .replace('$link$', `${BASE_AGENDA_UC}?${linkQueryParams}`);

        case 'direct':
        default:
          return defaultHTML;
      }
    });
    if (!links) return '';
    return mailPartialTemplates.examButtonHeader.replace('$items$', links.join(''));
  }

  /**
   * Obtener las rutas a los documentos que se deben enviar adjuntos en el correo electrónico.
   *
   * @param attachments Archivos adjuntos desde el formulario o pdfs generados al trámite.
   */
  getAttachmentsForEmail(attachments: Attachment[]): ShortPathFile[] {
    return attachments.map((attach) => {
      if (!attach.isAttached) return;

      const key = attach.key;
      const path = `${attach.bucket}/${attach.path}`;

      return {path, key};
    });
  }

  /**
   * Obtener el cuerpo de la petición de correo electrónico.
   *
   * @param paperworkState - Trámite extraído del estado global de la aplicación.
   */
  async getEmailRequest(paperworkState: PaperworkState) {
    const {attachments} = paperworkState.paperwork;
    const notifications = paperworkState.definition.submodule.workflow.notifications;
    const additionalContent = await this.getEmailAdditionalContent(paperworkState);
    const posts: any[] = [];
    for (const notify of notifications) {
      const to = this.getNotifyTo(notify, paperworkState);
      const cc = this.getNotifyCC(notify, paperworkState);
      const cco = notify?.cco || [];
      const subject = this.getSubject(notify, paperworkState);
      const files = this.getAttachments(notify, attachments);
      const content = await this.getEmailBodyHTML(notify.template, paperworkState, additionalContent);
      if (to && to.length > 0) posts.push({to, cc, cco, subject, content, attachments: files});
    }
    return posts;
  }

  /**
   * Obtiene información adicional al trámite que es necesaria para la construcción del correo
   */
  async getEmailAdditionalContent(paperworkState: PaperworkState) {
    const {paperwork, definition} = paperworkState;
    if (paperwork.prefix === PREFIX_RECIPE)
      return await this.getRecipeQuotePharmaEmailBodyHTML(paperwork);

    if (paperwork.prefix === PREFIX_INDICATION && definition.submodule.suffix === 'EXA')
      return await this.getIndicationLink(paperwork);

    return null;
  }

  getAttachments(notify: WorkflowNotify, attachments: Attachment[]) {
    if (notify.entity === 'indications.exams.images.team')
      return this.getAttachmentsForEmail(attachments.filter((attach) => attach.category === 'imagenes'));

    return this.getAttachmentsForEmail(attachments);
  }

  getSubject(notify: WorkflowNotify, paperworkState: PaperworkState): string {
    const isTemplateSubject = notify.subject.includes('$');
    if (!isTemplateSubject) return notify.subject;

    const {patient, shortId} = paperworkState.paperwork;
    return notify.subject
      .replace('$patient$', patient.documentNumber)
      .replace('$shortId$', shortId);
  }

  getNotifyTo(notify: WorkflowNotify, paperworkState: PaperworkState): string[] {
    const {paperwork, definition} = paperworkState;

    // si el profesional es TENS no envía notificación a paciente
    const entityBaseEmail = notify.entity === 'patient' && this.isNoMailSubprofessionalTENS()
      ? undefined
      : paperwork[notify.entity]?.email;

    const entityEmails = this.notificationEmailsService.getEntityEmails(notify.entity, paperworkState);
    if (entityEmails?.to && Array.isArray(entityEmails.to))
      return [entityBaseEmail, ...entityEmails.to];

    if (paperwork.prefix === PREFIX_ONCOLOGY && (definition.submodule.suffix === 'RTP' || definition.submodule.suffix === 'RPO'))
      return this.mailsByForm(paperwork);

    if (paperwork.prefix === 'DER' && definition.submodule.suffix === 'ITC')
      return this.mailsByDerivations(paperwork);

    if (paperwork.prefix === PREFIX_SURGERY && definition.submodule.suffix === 'ODC')
      return [entityBaseEmail, ...this.mailsSurgeryByProfesional(paperwork)];

    if (notify.entity === 'indications.exams.images.team')
      return this.mailsIndicationImagesTeam(paperwork);

    return entityBaseEmail ? [entityBaseEmail] : [];
  }

  getNotifyCC(notify: WorkflowNotify, paperworkState: PaperworkState): string[] {
    const {paperwork, definition} = paperworkState;
    let cc = notify?.cc || [];

    const entityEmails = this.notificationEmailsService.getEntityEmails(notify.entity, paperworkState);
    if (entityEmails?.cc && Array.isArray(entityEmails.cc))
      cc = cc.concat(entityEmails.cc);

    if (entityEmails?.cc && definition.submodule.suffix === 'ITC')
      cc = cc.concat(this.mailsDerivationBySpecialty(paperwork, entityEmails?.cc));

    if (entityEmails?.cc && definition.submodule.suffix === 'GES')
      cc = cc.concat(this.mailsGesByCenter(paperwork, entityEmails?.cc));

    const accessTeamCc = this.getNotifyAccessTeamEmails(paperworkState);
    return [
      ...cc,
      ...(accessTeamCc || []),
    ];
  }

  mailsIndicationImagesTeam(paperwork: Paperwork): string[] {
    const hasImageIndications = paperwork.content.some(exam => exam.group === 'IMAGENES');
    if (hasImageIndications) return environment.teamImagesEmails;
    return [];
  }

  mailsDerivationBySpecialty(paperwork: Paperwork, mailsList: any): string[] {
    const [derivation] = paperwork.content;
    const specialty = derivation.service?.derivation?.specialty;
    if (!specialty) return [];
    return mailsList[specialty.toString().trim().toLowerCase()] || [];
  }

  mailsGesByCenter(paperwork: Paperwork, mailsList: any): string[] {
    const [form] = paperwork.content;
    const center = form.center.name;
    if (!center) return [];
    return mailsList[center.toString().trim().toLowerCase()] || [];
  }

  mailsSurgeryByProfesional(paperwork: Paperwork): string[] {
    const {professional} = paperwork;
    if (!environment.isProduction || !professional?.rut) return [];

    return EMAIL_DERIVATE_BY_PROFESIONAL[professional.rut] || [];
  }

  mailsByForm(form: Paperwork): string[] {
    const ROADMAP_MAIL_MR = [];
    if (form.content[0]?.nurses)
      form.content[0]?.nurses.forEach(nurse => {
        ROADMAP_MAIL_MR.push(nurse.email);
      });
    const emailList: string[] = [form.patient.email];
    if (!environment.isProduction) return emailList;

    switch (form.content[0].group) {
      case FormsOncology.radioterapia:
        if (form.content[0].site === CENTER_MC) emailList.push(...MAIL_RADIOTHERAPY_MARCOLETA);
        if (form.content[0].site === CENTER_CSC) emailList.push(...MAIL_RADIOTHERAPY_CSC);
        break;
      case FormsOncology.roadmap:
        if (form.content[0].path === 'quimioterapia-hoja-ruta-ceca/csc') emailList.push(...NURSES_SC_MAILS);
        if (form.content[0].path === 'quimioterapia-hoja-ruta-ceca/cem') emailList.push(...NURSES_MC_MAILS, ...ROADMAP_MAIL_MR);
        break;
    }
    return emailList;
  }

  mailsByDerivations(form: Paperwork): string[] {
    const emailList: string[] = [form.patient.email];
    switch (form.content[0].interconsultation[0]?.name || form.content[0].service?.derivation?.specialty) {
      case (SPECIALTIES.unidad):
        emailList.push(...MAIL_DERIVATIONS.unidad);
        break;
      case (SPECIALTIES.programa):
        emailList.push(...MAIL_DERIVATIONS.programa);
        break;
      case (SPECIALTIES.programa_ud):
        emailList.push(...MAIL_DERIVATIONS.programa);
        break;
      case (SPECIALTIES.seguimiento):
        emailList.push(...MAIL_DERIVATIONS.seguimiento);
        break;
      case (SPECIALTIES.cefaleas):
        emailList.push(...MAIL_DERIVATIONS.cefaleas);
        break;
    }
    return emailList;
  }

  /**
   * Obtener las promesas de las solicitudes al servicio para enviar correos electrónicos.
   *
   * @param paperwork
   */
  async getRequestsToSendMail(paperwork: PaperworkState): Promise<Observable<Metadata>[]> {
    const body = await this.getEmailRequest(paperwork);
    const requests = body.map((params) => this.sendEmail(params));
    return requests;
  }

  /**
   * Obtener contenido de correo de Acceso Dedicado
   *
   * @param paperworkState
   */
  private getNotifyAccessTeamContent(paperworkState: PaperworkState): string {
    const {nextControl} = paperworkState.paperwork;
    const isCcTeamAccess = nextControl?.ccTeamAccess && nextControl.ccTeamAccess !== '';

    if (!isCcTeamAccess) return '';
    return getMailTeamAccessText(nextControl);
  }

  /**
   * Obtener lista de correos para Acceso Dedicado
   *
   * @param paperworkState
   */
  private getNotifyAccessTeamEmails(paperworkState: PaperworkState): string[] {
    const {nextControl} = paperworkState.paperwork;
    const isCcTeamAccess = nextControl?.ccTeamAccess && nextControl.ccTeamAccess !== '';
    if (!isCcTeamAccess) return [];
    return nextControl?.ccTeamAccessMails || [];
  }
}
