import { IntegralHealt } from './../interfaces/family-doctor.interface';
import { Patient } from '@components/patient/interfaces/patient.interface';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/* Cargar Datos personales del Paciente */
export const GET_PATIENT = createAction('[paciente] Cargar Paciente', props<{ docNumber: string; docType: string }>());
export const GET_PATIENT_OK = createAction('[paciente] OK Cargar Paciente', props<{ patient: Patient }>());
export const GET_PATIENT_ERR = createAction('[paciente] ERR Cargar Paciente', props<{ payload: HttpErrorResponse }>());

export const UPDATE_PATIENT = createAction('[paciente] OK Actualizar Paciente', props<{ patient: Patient }>());

export const GET_PATIENT_INTEGRAL_HEALT = createAction('[paciente] Carga datos salud integral', props<{ docNumber: string }>());
export const GET_PATIENT_INTEGRAL_HEALT_OK = createAction(
'[paciente] OK Paciente salud integral',
  props<{ integralHealth: IntegralHealt }>(),
);
export const GET_PATIENT_INTEGRAL_HEALT_ERR = createAction(
  '[paciente] ERR Cargar datos paciente salud integral',
  props<{ payload: HttpErrorResponse }>()
);

export const CLEAR_PATIENT = createAction('[paciente] Limpia paciente');
