import {DerivationService} from '@shared/interfaces/derivation.interface';

export const SERVICE: { ih: DerivationService[]; noIh: DerivationService[] } = {
  ih: [
    {
      id: '45778B2D-DB7C-497E-BB69-AF5B00CCBDA0',
      specialty: 'CARDIOLOGIA ADULTOS',
      service: 'General',
      fonasaIds: ['0101301'],
      imedIds: ['0101817']
    },
    {
      id: '014E0D80-43D3-4690-9E15-AF5B00D0592E',
      specialty: 'CIRUGIA CARDIACA',
      service: 'Adulto',
      fonasaIds: ['0101314'],
      imedIds: ['0101314', '0101802']
    },
    {
      id: 'CA9CC247-9413-4D85-9519-AF5B00D08C62',
      specialty: 'CIRUGIA CARDIACA',
      service: 'Adulto TLM',
      fonasaIds: ['0108314'],
      imedIds: ['0101314','0101802']
    },
    {
      id: 'F9706EB5-5871-44AA-9480-AF5B00D0D706',
      specialty: 'CIRUGIA CARDIACA',
      service: 'Pediatria',
      fonasaIds: ['0101312'],
      imedIds: ['0101800','0101802']
    },
    {
      id: 'D8D83F60-1F28-465B-8568-AF5B00D1ABA2',
      specialty: 'CIRUGIA COLOPROCTOLOGICA',
      service: 'General',
      fonasaIds: ['0101319'],
      imedIds: ['0101823','0101835','0101911']
    },
    {
      id: 'D390807B-0A0B-4E1D-918F-AF5B00D1EA6D',
      specialty: 'CIRUGIA COLOPROCTOLOGICA',
      service: 'General TLM',
      fonasaIds: ['0108319'],
      imedIds: ['0101823', '0101835', '0101911']
    },
    {
      id: 'B4352D32-7C70-465D-952B-B153012912E7',
      specialty: 'DERMATOLOGIA',
      service: 'Dermatologia General Adulto y Niño',
      fonasaIds: ['0101201'],
      imedIds: ['0101810']
    },
    {
      id: '05638B3B-A072-4B2E-8097-B153012A6FD9',
      specialty: 'DERMATOLOGIA',
      service: 'General Adulto TLM',
      fonasaIds: ['0108201'],
      imedIds: ['0101810']
    },
    {
      id: 'E32E8B28-2C45-4DDC-9867-AF5B00D2D60D',
      specialty: 'MEDICINA DEPORTIVA',
      service: 'General',
      fonasaIds: ['0101001'],
      imedIds: ['0101816', '0101814']
    },
    {
      id: 'C9B7B4C4-3118-4949-BC70-B08B00EB9544',
      specialty: 'MEDICINA INTERNA',
      service: 'General',
      fonasaIds: ['0101307'],
      imedIds: ['0101816']
    },
    {
      id: 'D26E637B-C8D2-421D-8915-B08B00EC4163',
      specialty: 'MEDICINA INTERNA',
      service: 'General TLM',
      fonasaIds: ['0108307'],
      imedIds: ['0101816']
    }
  ],
  noIh: [],
};
