export const environment = {
  production: false,
  isProduction: false,
  stage: 'dev',
  version: 'Entorno de Desarrollo | v3.22.0',
  zoomApiKey: 'lnT-gzgnS5-KhHLz_duBTg',
  defaultBucketS3: 'portalclinico-dev-pri-usw2',
  supportEmail: 'soporterme@ucchristus.cl',
  teamImagesEmails: ['dmartinezbello@gmail.com'],
  domain: '.ucchristus.io',
  appDomain: 'portalclinico.dev',

  worldTimeApi: 'https://worldtimeapi.org',
  baseApiPCv2: 'https://api.dev.ucchristus.io',
  serverAccessLinks: 'http://192.168.150.229:6001/RedirectServices',
  baseApiSchedule: 'https://apigw.ucchristus.cl/agendaambulatoria-test',
  baseApiRME: 'https://th9n7vjum0.execute-api.us-east-1.amazonaws.com',
  baseApiFC2: 'https://yn954rqyk2.execute-api.us-east-1.amazonaws.com',
  baseApiGES: 'http://146.155.200.74:7777',
  baseApiProfessionalImage: 'https://assets.ucchristus.cl/fotosProfesionales/test',
  nextControlBaseSchedule: 'http://no-disponible-dev/agenda',

  baseApiExamRecords: ' https://q99qh0i7x7.execute-api.us-east-1.amazonaws.com',
  baseApiIntegralHealth: 'https://ecommerceucv2.lfi.cl',
  baseApiAmbulatory: 'https://apigw.ucchristus.cl/agendaambulatoria-test', //cambiar a pre para probar
  baseApiAgendaUc: 'https://agendauctest.lfi.cl',

  baseTableau: 'https://bi.ucchristus.cl/t/bi-portal/views',
  tableauCookie: 'https://5zeywqajfc.execute-api.us-east-1.amazonaws.com/prod/tableau',
  chatInnovit: 'https://storage.googleapis.com/webchats/production/ucch/bundle.js',
  baseSurgery: 'https://bumn1o7am0.execute-api.us-east-1.amazonaws.com',

  linkTyC: 'https://rme-prd-terms-conditions.s3.amazonaws.com/Terminos_Condicione_UC_CHRISTUS_(Venta_medicamentos).pdf',
  linkPP: 'https://rme-prd-terms-conditions.s3.amazonaws.com/Poli%CC%81tica+de+Privacidad+RME+V31.07.2020.pdf',

  baseApiQualtrics: 'http://localhost:3102/radiologia/v1/analitica/qualtrics/abcdef/profesional/171374405?especialidades=["especialidad 1", "especialidad 2", "especialidad 3"]',

  tagsPRM: {
    drm: '6b4771f8-4f3a-46da-a6f6-af1800f088ef',
    otr: [
      {
        name: 'Evaluación avanzada de voz y laringe - Estroboscopía laríngea',
        tag: 'bac31273-6932-4449-b5f0-b05101512c5d',
      },
      {
        name: 'Comité de oído',
        tag: '49d0eaf9-16e2-4e07-8f48-b0510151422f',
      },
      {
        name: 'Comité de nariz',
        tag: '6b4771f8-4f3a-46da-a6f6-af1800f088ef',
      },
      {
        name: 'Comité de cavidades paranasales - Videonasolaringoscopía',
        tag: '',
      },
      {
        name: 'Comité de ronquido - Videonasolaringoscopía',
        tag: 'db615596-380d-46f0-89f2-b053013cee1a',
      },
    ],
  },

  boxCenters: [
    'Alcántara',
    'Bilbao',
    'Clínica San Carlos',
    'Clínica San Carlos de Apoquindo',
    'Escuela Militar',
    'Irarrázaval',
    'Lira Piso 2',
    'Lira Piso 4',
    'Lira Piso 6',
    'San Jorge',
    'Santa Lucía',
    'Santa Lucía (Lira 21)',
  ],

  examAreas: {
    imagenes: 'RIS_IMAGENES',
    laboratorio: '1a2ec3c8-90f6-4b07-82ba-abbb017226bd',
    procedimientos: 'b2461ac2-9c50-4724-9676-a91a00a91a02',
  },

  integralHealthPlans: [
    {id: '018ed73d-6bd2-46e9-8e95-acfc0134435b', name: 'Paciente Salud Integral'},
    {id: '7464fdd5-ec20-407a-984a-b03b015ec191', name: 'Paciente Santander'}
  ]

};
