import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as patientActions from './patient.actions';
import { PatientService } from 'src/app/components/patient/services/patient.service';

import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { UNSELECT_AGENDA } from '@components/agenda/store/agenda.actions';
import { IntegralHealt } from '@components/patient/interfaces/family-doctor.interface';
import { FamilyDoctorService } from '@components/patient/services/family-doctor.service';

@Injectable()
export class PatientEffects {
  getPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.GET_PATIENT),
      mergeMap(({ docNumber, docType }) =>
        this.patientService.getPatient(docNumber, docType).pipe(
          map((patient) => patientActions.GET_PATIENT_OK({ patient })),
          catchError((err) => of(patientActions.GET_PATIENT_ERR({ payload: err }))),
        ),
      ),
    ),
  );

  getIntegralHealth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.GET_PATIENT_OK),
      filter(({ patient }) => Boolean(patient)),
      filter(({ patient }) => patient?.integralHealth === undefined),
      map(({ patient }) => patientActions.GET_PATIENT_INTEGRAL_HEALT({ docNumber: patient.documentNumber })),
      catchError((err) => of(patientActions.GET_PATIENT_INTEGRAL_HEALT_ERR({ payload: err }))),
    ),
  );

  setIntegralHealth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.GET_PATIENT_INTEGRAL_HEALT),
      switchMap(({ docNumber }) => this.familyDoctorService.getPatientIH(docNumber)),
      map((integralHealth: IntegralHealt) => patientActions.GET_PATIENT_INTEGRAL_HEALT_OK({ integralHealth })),
      catchError((err) => of(patientActions.GET_PATIENT_INTEGRAL_HEALT_ERR({ payload: err }))),
    ),
  );

  clearPatientAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientActions.CLEAR_PATIENT),
      map(() => UNSELECT_AGENDA()),
    ),
  );

  constructor(
    private actions$: Actions,
    private patientService: PatientService,
    private familyDoctorService: FamilyDoctorService,
  ) {}
}
