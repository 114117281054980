import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validators} from '@angular/forms';
import {FormControl} from '@core/reactive-forms/form-control';
import {FormGroup} from '@core/reactive-forms/form-group';
import {FormArray} from '@core/reactive-forms/form-array';

export const FIELD_TEXT_REQUIRED = 15;
export const FIELD_REQUIRED = 1;

export interface RequiredMin {
  minLength?: number;
  value?: any;
  isRequired?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FormFieldsValidate {
  // largo máximo string direcciones
  static maxlengthAddress = 40;

  // largo máximo string email
  static maxlengthEmail = 40;

  // largo máximo string teléfono
  static maxlengthPhone = 12;

  // largo máximo string RUT
  static maxlengthRut = 10;

  // largo máximo string Nombre y apellidos
  static maxlengthNames = 30;

  // Patron que solo acepta números
  static onlyNumbersPattern = /^[-]?[0-9]+[.]?[0-9]+$/;
  static onlyNumbersUpCero = /^[0-9]+/;

  // Patron de validación de email
  static patternEmail = new RegExp(
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
  );
  // 2 decimales separados por punto

  // static twoDigitsSeparatedByPeriod = /((?:\d+\.\d*)|(?:\.?\d+))/g;
  static twoDigitsSeparatedByPeriod = /[0-9]{1,3}(\.[0-9]{2})/;
  static oneDigitAndTowDecimal = /^[0-9]\.\d{2}$/;
  static onlyThreeDecimalPlaces = /^[0-9]+(\.[0-9]{1,3})?$/;
  // static twoDigitsSeparatedByPeriod = /[0-9][0-9]+\.\d{2}$/gm;

  // Patron que solo acepta números enteros
  static onlyNumbersPositivesPattern = /[0-9]/;

  // Patrón de teléfono simple
  static phoneSimple = /^[+]{0,1}[\- 0-9]{4,12}$/;

  // Patrón para los números de telefonos de chile (aplica para celulares y fijos)
  static phoneNumberCL = /^\D*([+56]\d [9])(\D)(\d{4})(\D)(\d{4})\D*$/;

  // Patrón para los números de telefonos de chile (aplica para celulares y fijos)
  static phoneHomeNumberCL = /^\D*([+56]\d)(\D)(\d{0,2})(\D)(\d{4})(\D)(\d{4})\D*$/;

  // Patrón para RUT completo
  static rut = /^[0-9]+[-|‐]{1}[0-9kK]{1}$/; //  /\d{7,8}-[\d|kK]{1}/

  // Número de telefono formato chileno requerido
  static requiredPhoneNumberCL = [
    '',
    [Validators.required, Validators.pattern(FormFieldsValidate.phoneNumberCL), Validators.minLength(12)],
  ];

  // Número de telefono formato chileno No Requerido
  static noRequiredPhoneNumberCL = [
    '',
    [Validators.pattern(FormFieldsValidate.phoneHomeNumberCL), Validators.minLength(12)],
  ];

  // Campo de texto desabilitado para que el usuario escriba
  static fieldDisabled = [{value: '', disabled: true}];

  /**
   * Campo requerido con un mínimo de caracteres que se reciben por parámetros
   *
   * @returns Arreglo de validaciones
   * @param minLength
   * @param value
   */

  static requiredMinLength(minLength = FIELD_REQUIRED, value: any = null): FormControl<typeof value> {
    return new FormControl<typeof value>(value, [Validators.required, Validators.minLength(minLength)]);
  }

  /**
   * Campo no requerido con un mínimo de caracteres que se reciben por parámetros
   *
   * @param minLength - largo mínimo del campo
   * @param value
   * @returns Arreglo de validaciones
   */

  static minLength(minLength = FIELD_REQUIRED, value = null): FormControl<typeof value> {
    return new FormControl<typeof value>(value, [Validators.minLength(minLength)]);
  }

  /**
   * Correo electrónico, requerido con un mínimo de caracteres
   *
   * @param minlength - largo mínimo del campo
   * @param required - si el campo es requerido o no
   * @returns Arreglo de validaciones
   */
  static requiredEmailMinLength(minlength: number, required: boolean = true): any {
    if (required) return ['', [Validators.required, Validators.email, Validators.minLength(minlength)]];
    return ['', [Validators.email, Validators.minLength(minlength)]];
  }

  /**
   * Obtener el mensaje apropiado para identificar un campo del formulario si este no está válido
   *
   * @param field - nombre de campo a validar
   * @returns Mensaje de error
   */
  static getErrorMessage(field: AbstractControl): string {
    const controlErrors: ValidationErrors = field.errors;
    let message = '';

    if (controlErrors != null)
      Object.keys(controlErrors).forEach((keyError) => {
        switch (keyError) {
          case 'required':
            message = 'Este campo es requerido.';
            break;

          case 'minlength':
            const minLength = controlErrors?.minlength.requiredLength;
            message = `Debe tener mínimo ${minLength} caracteres.`;
            break;

          case 'maxlength':
            const maxlength = controlErrors?.maxlength.requiredLength;
            message = `No debe tener más de ${maxlength} caracteres.`;
            break;

          case 'pattern':
            const pattern = controlErrors?.pattern.requiredPattern;

            let typePattern = 'patrón incorrecto.';

            if (pattern === this.phoneNumberCL.toString()) typePattern = 'Número de celular inválido.';

            if (pattern === this.patternEmail.toString()) typePattern = 'Correo inválido.';

            if (pattern === this.rut.toString()) typePattern = 'RUT incorrecto (patrón).';

            if (pattern === this.phoneSimple.toString()) typePattern = 'Teléfono incorrecto (patrón).';

            if (pattern === this.twoDigitsSeparatedByPeriod.toString())
              typePattern = 'debe tener 2 decimales separados por punto (.)';

            if (pattern === this.oneDigitAndTowDecimal.toString())
              typePattern = 'debe tener 1 digito y 2 decimales separados por punto (.)';

            message = `El valor no corresponde: ${typePattern}`;
            break;

          case 'lessThan':
            message = `La fecha no puede ser mayor a la fecha hasta.`;
            break;

          case 'higherThan':
            message = `La fecha no puede ser menor a la fecha desde.`;
            break;

          case 'validEmail':
            message = `El texto ingresado no corresponde a un email válido`;
            break;

          default:
            message = null;
            break;
        }
      });

    return message;
  }

  /**
   * Validar el estado del campo del formulario
   *
   * @param field - nombre de campo a validar
   * @returns Si el campo es válido o no
   */
  static isValidField(field: AbstractControl): boolean {
    return (field.touched || field.dirty) && !field.valid;
  }

  /**
   * Obtener todos los valores del formGroup, recorrerlos y marcar cada campo como tocado
   *
   * @param formGroup - formulario
   */
  static markFormAsTouched(formGroup: FormGroup<any>): void {
    Object.values(formGroup.controls).forEach((control) => {
      // control.markAsTouched();
    });
  }

  static dateLessThan(from: string, to: string) {
    return (group: AbstractControl): ValidationErrors | null => {
      const validFrom = group.get(from)?.value;
      const validTo = group.get(to)?.value;
      if (new Date(validFrom).getTime() > new Date(validTo).getTime()) {
        group.get(from).setErrors({lessThan: true});
        return {lessThan: true};
      }
      group.get(from).setErrors(null);
      return null;
    };
  }

  static dateHigherThan(from: string, to: string) {
    return (group: AbstractControl): ValidationErrors | null => {
      const validFrom = group.get(from)?.value;
      const validTo = group.get(to)?.value;
      if (
        new Date(validFrom).getTime() > new Date(validTo).getTime() ||
        new Date(validFrom).getTime() === new Date(validTo).getTime()
      ) {
        group.get(from).setErrors({higherThan: true});
        return {higherThan: true};
      }
      group.get(from).setErrors(null);
      return null;
    };
  }

  static validateEmail(email: string) {
    return (group: AbstractControl): ValidationErrors | null => {
      const emailV = group.get(email)?.value;
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailV)) return {validEmail: true};

      return null;
    };
  }

  /**
   * Válida que al menos un campo del grupo este seleccionado
   *
   * @param formGroup - formulario
   */
  static validateAtLeastOneRequired(formGroup: FormGroup<any>): { [key: string]: any } | null {
    const hasValue = Object.values(formGroup.controls).some((control: any) => control.value);
    return hasValue ? null : {validateGroup: true};
  };

  /**
   * Válida que al menos un elemento de la lista tenga valor distinto de vacío
   *
   * @param formArray
   */
  static validateAtLeastOneArrayValue(formArray: FormArray<any>): { [key: string]: any } | null {
    const hasValue = formArray.controls.some((control) => control.value && control.value.length > 0);
    return hasValue ? null : {validateGroup: true};
  }

  /**
   * Válida que un array al menos contenga un elemento
   *
   * @param formArray
   */
  static validateRequiredArray(formArray: FormArray<any>): { [key: string]: any } | null {
    const isValid = formArray?.value && formArray.value.length > 0;
    return isValid ? null : {validateGroup: true};
  }

  static validateAtArrayValue(formArray: FormArray<any>): { [key: string]: any } | null {
    const hasValue = formArray.controls.some((control) => !control.value || control.value.length === 0);
    return hasValue ? {validateGroup: true} : null;
  }
}
