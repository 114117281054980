import {Menu} from '@shared/interfaces/menu.interface';
import {ROOT_ROUTES} from '../../app-routing.module';
import {CLINICAL_ROUTES} from '@clinical/clinical-routes.module';
import {FORMS_ROUTES} from '@clinical/forms/forms-routes.module';
import {METRICS_ROUTES} from '@clinical/metrics/metrics-routes.module';
import {ONCOLOGY_ROUTES} from '@clinical/oncology/oncology.routes.module';
import {HOSPITALIZATION_ROUTES} from '@clinical/hospital/hospital.routes.module';
import {AGENDA_MGMT_ROUTES} from '@clinical/schedule/schedule.routes.module';
import {EXPERTISE_ROUTES} from '@clinical/expertise/expertise-routes.module';
import {MEDICINES_ROUTES} from '@clinical/medicines/medicines-routes.module';
import {SURGERIES_ROUTES} from '@clinical/surgeries/surgeries-routes.module';
import {ADMISSIONS_ROUTES} from '@clinical/admissions/admissions-routes.module';
import {INDICATIONS_ROUTES} from '@clinical/indications/indications-routes.module';
import {DAU_ROUTES} from '@clinical/emergencies/emergencies-routes.module';
import {CARE_ROUNDS_ROUTES} from '@clinical/carerounds/care-rounds-routes.module';
import {DERIVATIONS_ROUTES} from '@clinical/derivations/derivations-routes.module';
import {EDUCATIONAL_ROUTES} from '@clinical/educational/educational-routes.module';
import {REPRODUCTIVE_ROUTES} from '@clinical/reproductive/reproductive-routes.module';
import {ADMIN_ROUTES} from '@clinical/administration/administration-routes.module';
import {MEDICAL_DISCHARGE_ROUTES} from '@clinical/medical-discharge/medical-discharge-routes.module';
import {APPO_ROUTES} from '@clinical/appointments/appointments-routes.module';

export const MENU_OPTIONS: Menu[] = [
  {
    order: 1,
    color: '#CCCCCC',
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#oQdizW',
    title: 'Medicamentos',
    tooltip: null,
    icon: 'bx bx-capsule',
    activated: true,
    open: false,
    allow: '',
    path: `${CLINICAL_ROUTES.medicines}`,
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Uh7hit',
        activated: true,
        order: 1,
        title: 'Receta',
        tooltip: null,
        icon: '',
        allow: '',
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}/${MEDICINES_ROUTES.generalRecipe}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#VGerim',
        activated: true,
        order: 3,
        title: 'Receta Diabetes',
        tooltip: null,
        icon: '',
        allow: '',
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}/${MEDICINES_ROUTES.diabetesRecipe}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#VGedWh',
        activated: true,
        order: 2,
        title: 'Receta Magistral',
        tooltip: null,
        icon: '',
        allow: '',
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}/${MEDICINES_ROUTES.masterRecipe}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#RlnY7m',
        activated: true,
        order: 4,
        title: 'Recetas Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}/${MEDICINES_ROUTES.historyPatientRecipes}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#OJU7t4',
        activated: true,
        order: 5,
        title: 'Histórico Recetas',
        tooltip: null,
        icon: '',
        allow: '',
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.medicines}/${MEDICINES_ROUTES.historyProfessionalRecipes}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#c082IL',
    activated: true,
    order: 2,
    title: 'Indicaciones',
    tooltip: null,
    icon: ' bx bx-test-tube',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}`,
    color: '#CCCCCC',
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#WM2RsD',
        order: 1,
        title: 'Exámenes',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.exams}`,
      },

      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#EgXljW',
        order: 2,
        title: 'Oftalmológica',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.ophthalmology}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Z4SZK2',
        order: 3,
        title: 'Exámen Oftalmológica',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.ophthalmologyExam}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Y3whMc',
        order: 4,
        title: 'Procedimientos Dermatología',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.dermatology}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#ShBtpE',
        order: 5,
        title: 'Vacunas',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.vaccines}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#OtoLar',
        order: 6,
        title: 'Otorrinolaringologia',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.otorhinolaryngology}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#AyTeOr',
        order: 7,
        title: 'Ayudas Técnicas y Órtesis',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.techHelpOrthosis}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#sAjqsd',
        order: 8,
        title: 'Indicaciones Pacientes',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.historyPatientIndications}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#asd123',
        order: 9,
        title: 'Indicaciones Histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.indications}/${INDICATIONS_ROUTES.historyProfessionalIndications}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#sAFYFo',
    order: 3,
    title: 'Derivaciones',
    tooltip: null,
    icon: 'bx bx-directions',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.derivations}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#kWqoQV',
        order: 1,
        title: 'Interconsultas',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.derivations}/${DERIVATIONS_ROUTES.interconsultations}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Da3Pnb',
        order: 2,
        title: 'Interconsultas Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.derivations}/${DERIVATIONS_ROUTES.historyPatientInterconsultations}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#HSJ65s',
        order: 3,
        title: 'Interconsultas Histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.derivations}/${DERIVATIONS_ROUTES.historyProfessionalInterconsultations}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Da3Phy',
        order: 4,
        title: 'Próximo Control',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.derivations}/${DERIVATIONS_ROUTES.nextControl}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#zK09E5',
    order: 4,
    title: 'Formularios',
    tooltip: null,
    icon: 'bx bx-library',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#f113qX',
        order: 1,
        title: 'Certificado',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.certificate}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#zr5tUk',
        order: 2,
        title: 'Formulario GES',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.ges}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#yg7JGb',
        order: 3,
        title: 'Formulario Consentimiento',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.consent}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#LYyvt7',
        order: 4,
        title: 'Consentimiento Imágenes',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.imagesConsent}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#LYyvt7',
        order: 5,
        title: 'Consentimiento Anestesia',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.anesthesiaConsent}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#ShBtpE',
        order: 5,
        title: 'Vacunas Especiales',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.specialVaccines}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#DQetYa',
        order: 5,
        title: 'Inscripción PAD FONASA Obst. Gine.',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.padFonasaRegistration}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#cEQl7X',
        order: 3,
        title: 'Bono PAD Cirugía Bariátrica',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.bariatric}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#ENtU1U',
        order: 6,
        title: 'Ingreso Hospitalario',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.hospitalization}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#S3E4GU',
        order: 7,
        title: 'Dolor Lumbar',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.lumbarPain}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#th2liy',
        order: 8,
        title: 'Riesgo ETE',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.ete}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#03pN4c',
        order: 9,
        title: 'PNAC',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.pnac}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#03Ly1N',
        order: 15,
        title: 'Formularios Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.historyPatientForms}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#03Ly1N',
        order: 16,
        title: 'Formularios Histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.forms}/${FORMS_ROUTES.historyProfessionalForms}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#wfjhTp-bloqueado',
    order: 12,
    title: 'Peritaje Abuso Sexual',
    tooltip: null,
    icon: 'bx bx-spreadsheet',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.expertise}`,
    color: '#CCCCCC',
    activated: false,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#TmZxs2',
        order: 1,
        title: 'Nuevo Informe',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.expertise}/${EXPERTISE_ROUTES.expertiseForm}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#HotHCF',
        order: 2,
        title: 'Registros pacientes',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.expertise}/${EXPERTISE_ROUTES.historyPatientExpertise}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#HotNST',
        order: 3,
        title: 'Registros histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.expertise}/${EXPERTISE_ROUTES.historyProfessionalExpertise}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#RMDM-bloqueado',
    order: 12,
    title: 'Ronda Multidisciplinaria',
    tooltip: null,
    icon: 'bx bx-spreadsheet',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.careRounds}`,
    color: '#CCCCCC',
    activated: false,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#0Mult1',
        order: 10,
        title: 'Ronda Multidisciplinaria',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.careRounds}/${CARE_ROUNDS_ROUTES.careRoundForm}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#0MuMdR',
        order: 10,
        title: 'Dashboard MDR',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.careRounds}/${CARE_ROUNDS_ROUTES.dashboard}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#wfjhTp-bloqueado',
    order: 12,
    title: 'Documentos Al Alta',
    tooltip: null,
    icon: 'bx bx-edit-alt',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dischargeDocs}`,
    color: '#CCCCCC',
    activated: false,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#wfjhTp',
        order: 1,
        title: 'Epicrisis Enfermería',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dischargeDocs}/${MEDICAL_DISCHARGE_ROUTES.epicrisisNursingForm}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#wfjhTp',
        order: 2,
        title: 'Régimen Liviano',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dischargeDocs}/${MEDICAL_DISCHARGE_ROUTES.lightRegimeForm}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#wfjhTp',
        order: 3,
        title: 'Régimen Sin Residuos',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dischargeDocs}/${MEDICAL_DISCHARGE_ROUTES.residueFreeRegimenForm}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#wfjhTp',
        order: 4,
        title: 'Régimen Papilla',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dischargeDocs}/${MEDICAL_DISCHARGE_ROUTES.papillaryRegimeForm}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#wfjhTp',
        order: 5,
        title: 'Registros pacientes',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dischargeDocs}/${MEDICAL_DISCHARGE_ROUTES.historyPatientDischargeDocs}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#wfjhTp',
        order: 6,
        title: 'Registros histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dischargeDocs}/${MEDICAL_DISCHARGE_ROUTES.historyProfessionalDischargeDocs}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#3bF5Jp',
    order: 5,
    title: 'Cirugías',
    tooltip: null,
    icon: 'bx bx-pulse',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.surgeries}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#cEQl7P',
        order: 1,
        title: 'Cirugías',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.surgeries}/${SURGERIES_ROUTES.order}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#jeLj4l',
        order: 2,
        title: 'Cirugías Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.surgeries}/${SURGERIES_ROUTES.historyPatient}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#jeLj4l',
        order: 2,
        title: 'Cirugías Histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.surgeries}/${SURGERIES_ROUTES.historyProfessional}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Akld52',
        order: 4,
        title: 'Tabla Quirúrgica',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.surgeries}/${SURGERIES_ROUTES.surgicalTable}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#O0GOGI',
    order: 6,
    title: 'Oncología',
    tooltip: null,
    icon: 'bx bx-bible',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#O0GOGI',
        order: 1,
        title: 'Tac',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}/${ONCOLOGY_ROUTES.tac}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#O0GOGI',
        order: 2,
        title: 'Radioterapia',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}/${ONCOLOGY_ROUTES.radiotherapy}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#O0GOGI',
        order: 4,
        title: 'Hoja de Ruta San Carlos',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}/${ONCOLOGY_ROUTES.roadmap}/csc`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#O0GOGI',
        order: 5,
        title: 'Hoja de Ruta Marcoleta',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}/${ONCOLOGY_ROUTES.roadmap}/cem`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#O0GOGI',
        order: 3,
        title: 'Órdenes Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}/${ONCOLOGY_ROUTES.historyPatient}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#O0GOGI',
        order: 4,
        title: 'Órdenes Histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}/${ONCOLOGY_ROUTES.historyProfessional}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#O0tAty',
    order: 7,
    title: 'Hospitalización',
    tooltip: null,
    icon: 'bx bx-hotel',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.hospitalizations}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#2FHAXh',
        order: 1,
        title: 'Hospitalización',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.hospitalizations}/${HOSPITALIZATION_ROUTES.order}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#6T3psL',
        order: 2,
        title: 'Órdenes Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.hospitalizations}/${HOSPITALIZATION_ROUTES.historyPatient}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#6T3psL',
        order: 3,
        title: 'Órdenes Histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.hospitalizations}/${HOSPITALIZATION_ROUTES.historyProfessional}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#6y6qwe',
    order: 7,
    title: 'Admisión Hospitalaria',
    tooltip: null,
    icon: 'bx bx-clinic',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.admissions}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#6y6qwe',
        order: 4,
        title: 'Admisión Hospitalaria',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.admissions}/${ADMISSIONS_ROUTES.admission}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#6y6qwe',
        order: 5,
        title: 'Admisiones Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.admissions}/${ADMISSIONS_ROUTES.historyPatient}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#N8Yplx',
    order: 8,
    title: 'Medicina Reproductiva',
    tooltip: null,
    icon: 'bx bx-female-sign',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#o6gbJH',
        order: 1,
        title: 'Ciclo de Actividad Sexual Dirigida',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}/${REPRODUCTIVE_ROUTES.reproductive}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#2Ahu65',
        order: 2,
        title: 'Inseminación Intrauterina',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}/${REPRODUCTIVE_ROUTES.insemination}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#2RT56H',
        order: 3,
        title: 'Formulario PAD Hombre',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}/${REPRODUCTIVE_ROUTES.padMan}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#4FB1I9',
        order: 4,
        title: 'Formulario PAD Mujer',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}/${REPRODUCTIVE_ROUTES.padWoman}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#4FB1I9',
        order: 5,
        title: 'PAD FONASA',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}/${REPRODUCTIVE_ROUTES.fonasa}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#F596O1',
        order: 6,
        title: 'Formulario Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}/${REPRODUCTIVE_ROUTES.historyPatient}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#5RBKKQ',
        order: 7,
        title: 'Formulario Histórico',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.reproductive}/${REPRODUCTIVE_ROUTES.historyProfessional}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#s8o7hJ',
    order: 9,
    title: 'Material Educativo',
    tooltip: null,
    icon: 'bx bx-chalkboard',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.educationalResources}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Qblktl',
        order: 1,
        title: 'Educación Pacientes y Familiares',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.educationalResources}/${EDUCATIONAL_ROUTES.tutorials}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Qblktl',
        order: 2,
        title: 'Recursos de TLM',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: null,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#lPruH3',
        order: 3,
        title: 'Tutoriales',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.admin}/${ADMIN_ROUTES.capsules}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#yUu2XB',
    order: 10,
    title: 'Gestión Agenda',
    tooltip: '- Podrás solicitar apertura/bloqueo y\n' +
      '- Cierre de oferta.\n' +
      '- Apertura en forma automática de tu oferta de telemedicina.\n' +
      '- Ver tus ofertas activas\n' +
      '- Solicitar box para abrir ofertas adicionales.',
    icon: 'bx bx-food-menu',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agendaManagement}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#lpSfIl',
        order: 1,
        title: 'Oferta, Bloqueo y Cierre',
        tooltip: 'Podrás solicitar apertura/bloqueo y cierre de oferta de manera fácil y expedita',
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agendaManagement}/${AGENDA_MGMT_ROUTES.requestOBC}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#ZrYQoN',
        order: 2,
        title: 'Autogestión Telemedicina',
        tooltip: 'Acá podrás abrir en forma automática tu oferta de telemedicina y quedara ' +
          'disponible de inmediato para agendar los pacientes.',
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agendaManagement}/${AGENDA_MGMT_ROUTES.requestTLM}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#OyuOfU',
        order: 3,
        title: 'Oferta Activas',
        tooltip: 'Aquí podrás ver tus ofertas actuales tanto presenciales como virtuales',
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agendaManagement}/${AGENDA_MGMT_ROUTES.activeOffers}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#hRxAsdxx',
        order: 4,
        title: 'Usa un Box Disponible',
        tooltip: 'Aquí podrás solicitar box para abrir ofertas adicionales a lo que ya tienes',
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agendaManagement}/${AGENDA_MGMT_ROUTES.box}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#hRxnes',
        order: 5,
        title: 'Resoluciones',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agendaManagement}/${AGENDA_MGMT_ROUTES.resolutions}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#a4Qmsp',
        order: 6,
        title: 'Mis Solicitudes',
        tooltip: 'Aquí podrás si tus ofertas solicitadas fueron o no aprobadas, recuerda que para presencial necesitamos un box',
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agendaManagement}/${AGENDA_MGMT_ROUTES.requestProfessional}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#YwFw16',
    order: 11,
    title: 'DAU',
    tooltip: null,
    icon: 'bx bx-bolt-circle',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dau}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Vpri4o',
        order: 1,
        title: 'San Carlos',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dau}/${DAU_ROUTES.dau}/san carlos`,

      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Vpri4o',
        order: 1,
        title: 'Colorado',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dau}/${DAU_ROUTES.dau}/colorado`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#6drGY2',
        order: 2,
        title: 'DAU Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dau}/${DAU_ROUTES.historyPatient}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#6drGY2',
        order: 3,
        title: 'DAU Todos',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.dau}/${DAU_ROUTES.dauByDate}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#JuVhdk',
    order: 12,
    title: 'Indicadores',
    tooltip: null,
    icon: 'bx bx-line-chart',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.metrics}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#RXlyDO',
        order: 1,
        title: 'Productividad',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.metrics}/${METRICS_ROUTES.productivity}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#aFqXiW',
        order: 2,
        title: 'Adopcion',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.metrics}/${METRICS_ROUTES.adopt}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#aFqXiZ',
        order: 3,
        title: 'Mi Oferta Futura',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.metrics}/${METRICS_ROUTES.futureOffer}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#bGjPgS',
        order: 5,
        title: 'Experiencia Paciente',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.metrics}/${METRICS_ROUTES.qualtrics}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#bHyTfs-bloqueado',
        order: 4,
        title: 'Gestión Agenda',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.metrics}/${METRICS_ROUTES.agenda}`,
      },
    ],
  },

  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#JuVfKp',
    order: 13,
    title: 'Administración',
    tooltip: null,
    icon: 'bx bx-collection',
    path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.admin}`,
    color: '#CCCCCC',
    activated: true,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#BgqBMY',
        order: 1,
        title: 'Cartola de Pagos',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.admin}/${ADMIN_ROUTES.pay}`,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#firm43',
        order: 3,
        title: 'Registrar Firma',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: null,
      },
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#r3c0rd4',
        order: 4,
        title: 'Recordatorios',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.admin}/${ADMIN_ROUTES.reminders}`,
      },
    ],
  },
  {
    pk: 'APP#00AB01',
    sk: 'APP#00AB01#MOD#Agendar',
    order: 14,
    title: 'Agenda',
    icon: 'bx bx-calendar',
    path: '',
    color: '#CCCCCC',
    activated: true,
    tooltip: null,
    open: false,
    allow: '',
    modules: [
      {
        pk: 'APP#00AB01',
        sk: 'APP#00AB01#MOD#Agendar',
        order: 1,
        title: 'Cupos Disponibles',
        tooltip: null,
        icon: '',
        allow: '',
        activated: true,
        path: `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.agendar}/${APPO_ROUTES.cupos}`,
      },

    ],
  },
];
