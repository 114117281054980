import { Patient } from '@components/patient/interfaces/patient.interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  CLEAR_PATIENT,
  GET_PATIENT,
  GET_PATIENT_OK,
  GET_PATIENT_ERR,
  GET_PATIENT_INTEGRAL_HEALT,
  GET_PATIENT_INTEGRAL_HEALT_OK,
  GET_PATIENT_INTEGRAL_HEALT_ERR,
  UPDATE_PATIENT,
} from '../../../store/actions';

export interface PatientState {
    patient: Patient;
    isLoaded: boolean;
    isLoading: boolean;
    error: any;
    status: string;
}

export const patientState: PatientState = {
    patient  : null,
    isLoaded : false, // cambiar a false
    isLoading: false,
    error    : null,
    status   : '',
};

const createReducerPatient = createReducer(
  patientState,

  on(GET_PATIENT, (state) => ({ ...state, isLoading: true })),
  on(GET_PATIENT_INTEGRAL_HEALT, (state) => ({ ...state, isLoading: true })),

  on(GET_PATIENT_OK, (state, { patient }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    patient: { ...patient },
    status: 'cargado'
  })),

  on(GET_PATIENT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    patient: null,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(UPDATE_PATIENT, (state, { patient }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    patient: { ...state.patient, ...patient },
    status   : 'paciente actualizado',
  })),

  on(CLEAR_PATIENT, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    patient: null,
    error: null,
  })),

  on(GET_PATIENT_INTEGRAL_HEALT_OK, (state, { integralHealth }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    patient: { ...state.patient, integralHealth },
    status: 'actualizado'
  })),

  on(GET_PATIENT_INTEGRAL_HEALT_ERR, (state, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    patient: { ...state.patient, integralHealth: null },
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
);

export const patientReducer = (state: PatientState, action: Action) => createReducerPatient(state, action);
