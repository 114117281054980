import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {firstValueFrom} from 'rxjs';
import {PCV2Request} from '@shared/interfaces/request.interface';
import {map, takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {HEADER_INFO} from '@shared/constants/notifications.const';
import {AlertService} from '@shared/services/alert.service';

@Injectable({
              providedIn: 'root'
            })
export class VersionManagementService {
  isVersionChanged: boolean;

  constructor(private http: HttpClient, private alertService: AlertService) { }

  async clearCacheAndCookies() {

    const c = document.cookie.split(';');
    for (const k of c) {
      const s         = k.split('=');
      document.cookie = s[0].trim() + '=;expires=Fri, 20 Aug 2021 00:00:00 UTC';
    }

    const domain = window.location.hostname;
    await caches.delete(domain);
    // @ts-ignore
    //window.location.reload(true);
    document.location.reload();

// Recargará el archivo JavaScript desde el servidor.
    await fetch(domain);
    console.log(`Cache borrado para el dominio ${domain}`);
  };

  goToInstructions() {

    window.open(
      `https://s3.us-west-2.amazonaws.com/recursosweb.pub.prd.usw2/documentos/instructivo-borrado-cache-portal-clinico.pdf`,
      '_blank',
    );
  }

  async checkVersionIsChanged(showAlert: boolean): Promise<boolean> {
    const url              = `${environment.baseApiPCv2}/mantenedores/v1/versiones`;
    const serverVersion    = await firstValueFrom(this.http.get<PCV2Request>(url).pipe(map(({data}) => data[0].value)));
    const clientVersion    = environment.version;
    const isVersionChanged = !(serverVersion === clientVersion);

    this.isVersionChanged = isVersionChanged;

    const buttons = [
      {
        text    : 'Ver Instrucciones',
        role    : 'info',
        cssClass: 'app-prompt-reminder',
        handler : () => this.goToInstructions(),
      },
      {
        text    : 'OK',
        role    : 'confirm',
        cssClass: 'app-prompt-info',
        id      : 'cancel-button',
      }

    ];

    const message = '<b>Hay una nueva versión.</b> ' +
      '<p></p> <p> Para actualizar, presione al mismo tiempo las teclas ' +
      '<b>CTRL + SHIFT + R</b> si está usando Chrome o Edge. ' +
      'En caso de usar Safari presionar <b>⌥ + ⌘ + E y luego F5.</b> </p>';

    if (showAlert && isVersionChanged) await this.alertService.presentAlert(HEADER_INFO, message, buttons);
    return isVersionChanged;
  }
}
