export const genericTemplate = `
<html>
<head>
  <style>
    * { font-family: Tahoma, Ginebra, sans-serif; }
    p { color: #686666; font-size: 14px; line-height: 19px; }
  </style>
</head>
<body>
<div>
  <!--[if gte mso 9]>
  <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
    <v:fill type="tile"></v:fill>
  </v:background>
  <![endif]-->
  <table style="padding-left: 20px;" width="90%">
    <tr>
      <td align="left" style="padding-bottom: 20px; padding-top: 20px">
        <img src="https://www.ucchristus.cl/assets/images/logo.png" alt="Logo" width="292"/>
      </td>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
    <tr>
      <td style="color: #848484;">
        <p><b style="color: #404040;">Estimado(a) $patientFullname$. </b></p>
        <p> Encuentre adjunto su $paperworkTitle$ prescrito por el Dr. $professionalFullname$ el día $shortDate$.</p>
        <p> Muchas gracias por atenderse con nosotros.</p>
        $teamAccess$
      </td>
    </tr>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td style="border-bottom: 1px solid #dddddd"></td>
    </tr>
    <tr>
      <td>
        <table width="100%">
          <tr>
            <td>
              <table width="100%" border="0">
                <tr>
                  <td valign="top">
                    <p style="text-align: left;color: #5a5a5a;font-size: 11px;line-height: 20px;">
                      <span style="font-weight: bold"> Red de Salud UC CHRISTUS.</span> <br/>
                      Mesa Central Hospital Cl&iacute;nico: 22 354 3000 <br/>
                      Mesa Central Cl&iacute;nica San Carlos: 22 754 8700 <br/>
                      Rescate Ambulancia: 800 265 265 <br/>
                      Reserva de Horas: 22 676 7000
                    </p>
                  </td>
                  <td valign="top" width="30">&nbsp;</td>
                  <td valign="top">
                    <table width="100%" border="0">
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td valign="top" width="16">&nbsp;</td>
                        <td valign="top" width="1">
                          <a target="_blank" href="https://www.facebook.com/UCCHRISTUS">
                            <img src="https://www.ucchristus.cl/assets/images/rs1.png" width="20"/>
                          </a>
                        </td>
                        <td valign="top" width="1">
                          <a target="_blank"
                             href="https://www.instagram.com/ucchristus/?hl=es-la">
                            <img src="https://www.ucchristus.cl/assets/images/rs4.png" width="20"/>
                          </a>
                        </td>
                        <td valign="top" width="1">
                          <a target="_blank" href="https://twitter.com/ucchristus">
                            <img src="https://www.ucchristus.cl/assets/images/rs2.png" width="20"/>
                          </a>
                        </td>
                        <td valign="top" width="1">
                          <a target="_blank"
                             href="https://cl.linkedin.com/company/ucchristus">
                            <img src="https://www.ucchristus.cl/assets/images/rs5.png" width="20"/>
                          </a>
                        </td>
                        <td valign="top" width="1">
                          <a target="_blank" href="https://www.youtube.com/user/UCCHRISTUS">
                            <img src="https://www.ucchristus.cl/assets/images/rs3.png" width="20"/>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="88"></td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
</body>
</html>
`;
