import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';

import {forkJoin, Observable} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';

import {PCV2Request} from '@shared/interfaces/request.interface';
import {SurgeryOrder} from '@shared/interfaces/surgery.interface';

import {API_URIS} from '@shared/constants/api-uris.const';
import {Paperwork} from '@shared/interfaces/paperwork.interface';
import camelcaseKeys from 'camelcase-keys';
import {format} from 'date-fns';
import {PaperworkState} from '@store/reducers';
import {FileExtension} from '@shared/constants/mime-types.const';
import {PdfService} from '@shared/helpers/pdf.helper';
import {buildContentPdfSurgeryKeiron} from '@clinical/surgeries/utils/build-pdf-surgery-keiron';
import {AuthService} from '@auth/services/auth.service';

const BASE_API: string = environment.baseApiRME;
const BASE_API_PCV2: string = environment.baseApiPCv2;
const BASE_API_SURGERY: string = environment.baseSurgery;
const URI_SURGERY: string = API_URIS.clinical.surgery;
const URI_TABLE_SURGERY: string = API_URIS.surgery.request;
const URI_KEIRON_SURGERY: string = API_URIS.keiron.surgery;
const URI_PROXY: string = API_URIS.proxy.intranet.ucc;

interface ResponseSurgicalService {
  codfon: string;
  csu: string;
  glosa: string;
  grupo: string;
  id: string;
}

interface SurgeriesResponse {
  fonasaId: string;
  csu: string;
  glosa: string;
  grupo: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class SurgeryService {
  colores = ['#e9e9e9', '#f6e8fa', '#e5e6fa'];
  controlColor = 0;
  controlDate;

  constructor(private httpClient: HttpClient, private authService: AuthService, private pdfService: PdfService) {
  }

  save(body: Paperwork<SurgeryOrder>): Observable<Paperwork<SurgeryOrder>> {
    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_SURGERY}`, body).pipe(
      retry(3),
      map(({data}: { data: Paperwork<SurgeryOrder> }) => data),
    );
  }

  getSurgicalProcedure(txt: string) {
    return this.httpClient.get<ResponseSurgicalService[]>(`${BASE_API}/v1/indicaciones/cirugias?text=${txt}`)
      .pipe(retry(3), map(procedures => this.adapterSurgicalProcedure(procedures)));
  }

  adapterSurgicalProcedure(procedures: ResponseSurgicalService[]): SurgeriesResponse[] {
    return procedures.map(procedure => ({
      fonasaId: procedure.codfon,
      glosa: procedure.glosa,
      csu: procedure.csu,
      grupo: procedure.grupo,
      id: procedure.id,

    }));
  }

  findDataTable(formData) {
    const {start, end, rut} = formData;
    const body = {
      RUTMEDICO: rut,
      FINICIO: start,
      FFIN: end
    };
    const suffixURL = 'LISTA/AGENDAMIENTOS/PABELLON';
    const apiName = '&apiName=filemaker';
    const environ = `&environment=${environment.stage}`;

    return this.httpClient.post<PCV2Request>(`${BASE_API_PCV2}/${URI_PROXY}${suffixURL}${apiName}${environ}`, body).pipe(
      map((response) => this.transformData(response.data)), catchError((error) => error)
    );
  }

  transformData(data: any) {
    const {listAgendamientos} = camelcaseKeys(data, {deep: true});
    const {datos} = listAgendamientos;

    const newPavilions = datos.map((dato: any) => {
      const {codPabellon, descPabellon, runPaciente, nombrePaciente} = dato;
      const {diagnostico, horainicio, horatermino, fecha} = dato;
      const {procedimiento, descUnidad, descCentro} = dato;

      const hour = `${this.formatHour(horainicio)} a ${this.formatHour(horatermino)}`;
      const pabellon = this.setPabellon(codPabellon, descPabellon);
      const date = format(new Date(fecha), 'dd/MM/yyyy');
      const color = this.setColor(fecha);

      return {
        hour,
        color,
        pabellon,
        sort: date,
        descCentro,
        descUnidad,
        runPaciente,
        diagnostico,
        procedimiento,
        nombrePaciente,
        createdAt: date,
      };
    });

    return newPavilions;
  }

  setColor(fecha) {
    if (!this.controlDate) this.controlDate = fecha;
    if (this.controlDate !== fecha && this.controlDate) {
      this.controlDate = fecha;
      this.controlColor = this.controlColor + 1;
    }
    if (this.controlColor === 3) this.controlColor = 0;
    return this.colores[this.controlColor];
  }

  setPabellon(pabellon: string, descPabellon: string) {
    return `${pabellon} (${descPabellon.toUpperCase()})`;
  }

  formatHour(hour: string) {
    return hour.split(':').slice(0, 2).join(':');
  }

  notifyKeiron(formData: FormData) {
    const endpoint = `${BASE_API_PCV2}/${URI_KEIRON_SURGERY}`;
    return this.httpClient.post<PCV2Request>(`${endpoint}`, formData).pipe(
      // retry(3),
      map(({data}) => data),
      catchError((error) => error),
    );
  }

  notifyAllKeiron(formsData: FormData[]) {
    const request = formsData.map(formData => this.notifyKeiron(formData));
    return forkJoin(request);
  }

  /**
   * Construye formularios para enviar a keiron. Retorna un formulario por cada centro en la orden
   */
  async buildNotifyKeironFormsData(paperworkState): Promise<FormData[]> {
    const {patient, professional, shortId, content} = paperworkState.paperwork;
    const file = await this.getKeironPDFs(paperworkState);
    const [surgeryOrder] = content;
    const centers: string[] = surgeryOrder.center;
    return centers.map(center => {
      const notifyFormData = new FormData();
      notifyFormData.append('file', file.file, file.key);
      notifyFormData.append('center', center);
      notifyFormData.append('id', shortId);
      notifyFormData.append('email', patient.email);
      notifyFormData.append('firstName', patient.names);
      notifyFormData.append('lastName', patient.surnames);
      notifyFormData.append('patientRut', patient.documentNumber);
      notifyFormData.append('doctorRut', professional.rut);
      notifyFormData.append('description', surgeryOrder.observations?.budget);
      return notifyFormData;
    });
  }

  async getKeironPDFs(paperworkState: PaperworkState) {
    let docBase64 = null;
    const {definition} = paperworkState;
    const groups = this.groupedRows(paperworkState);
    const docs = {pdfs: [], paperwork: {} as Paperwork};
    const {paperwork} = paperworkState;
    const professional = this.authService.professionalRef;

    for await (const group of groups) {
      const {title} = group;
      const {config, docTitle} = definition.submodule;
      const body = buildContentPdfSurgeryKeiron(paperwork, group);
      const pdfParams = {
        config: {...config, isPreview: false},
        paperwork: {...paperwork, professional},
        group, title, body, docTitle,
      };
      const base64 = await this.pdfService.createPdf(pdfParams);
      const pdf = {base64, title: title + ' Keiron', isAttached: true};

      docBase64 = base64;
      docs.pdfs = [pdf, ...docs.pdfs];
    }

    const extension: FileExtension = 'pdf';
    const documents = docs.pdfs.map((pdf) => ({
      ...pdf,
      extension,
      path: definition.module,
      file: this.pdfService.base64ToBlob(pdf.base64),
    }));
    return documents[0];
  }

  groupedRows({paperwork}): any[] {
    const groups: string[] = [...new Set<string>(paperwork.content.map((form) => form?.group))];
    return groups.map<any>((group) => ({
      title: group ? group.toLowerCase() : '',
      rows: paperwork.content.filter((form) => form.group === group),
    }));
  }
}


